import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import useAuthStore from '../store/authStore';

const PrivateRoutes = ({roles}) => {
  let {user, token} = useAuthStore();
  const isAuthenticated = token ? true : false;

  const userHasRequiredRole = user && roles.includes(user?.user_role) ? true : false;

  if (!isAuthenticated) {
    return window.location.replace('/login');
  }

  if (user.user_role === 'LEADER' && (location.pathname.startsWith('/admin-dashboard') || location.pathname.startsWith('/super-admin') || location.pathname.startsWith('/disciple'))) {
    return window.location.replace('/leader');
  }
  if (user.user_role === 'ADMIN' && (location.pathname.startsWith('/leader') || location.pathname.startsWith('/super-admin') || location.pathname.startsWith('/disciple'))) {
    return window.location.replace('/admin-dashboard/groups');
  }
  if (user.user_role === 'SUPERADMIN' && (location.pathname.startsWith('/leader') || location.pathname.startsWith('/admin-dashboard') || location.pathname.startsWith('/disciple'))) {
    return window.location.replace('/super-admin');
  }
  if (user.user_role === 'DISCIPLE' && (location.pathname.startsWith('/leader') || location.pathname.startsWith('/admin-dashboard') || location.pathname.startsWith('/super-admin'))) {
    return window.location.replace('/disciple');
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return window.location.replace('/');
  }
  return <Outlet />;
};

export default PrivateRoutes;
