import {useEffect, useState} from 'react';
import {formatTime} from '../utils';

export const useCountDown = start => {
  const [counter, setCounter] = useState(start);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let timerId;
    if (isRunning && counter > 0) {
      timerId = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    } else if (counter === 0) {
      setIsRunning(false);
    }
    return () => clearTimeout(timerId); // Cleanup function to clear timeout on unmount or state change
  }, [counter, isRunning]);

  const startCountdown = () => {
    setIsRunning(true);
    setCounter(start);
  };

  return [isRunning, startCountdown, formatTime(counter)];
};
