import React, {useState, useEffect} from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import {useNavigate} from 'react-router-dom';
import {Grid, RadioGroup, Radio, FormControlLabel, Typography, Divider, IconButton} from '@mui/material';
import {Box} from '@mui/system';
import TextInput from '../../common/TextInput';
import {addExperienceApi, getExperienceApi} from '../../../api';
import {CONSTANTS} from '../../../constants';
import HeaderContainer from '../../common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Modal from '../../common/Modal';
import HelpOutline from '@mui/icons-material/HelpOutline';
import ModalHeader from '../../common/ModalHeader';
import useTranslations from '../../../hooks/useTranslation';

const DiscipleJourney = ({user, setPage,isEdit}) => {
  const { translate } = useTranslations();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [text, setText] = useState();
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const answersData = {
    "55": [
      {
        "id": 1,
        "label": translate("Which one are you? Pick just one."),
        "options": [
          { value: "Introvert", label: translate("Introvert") },
          { value: "Extrovert", label: translate("Extrovert") },
          { value: "Ambivert", label: translate("Ambivert") }
        ]
      },
      {
        "id": 2,
        "label": "",
        "options": [
          { value: "Go", label: translate("Go") },
          { value: "Send", label: translate("Send") }
        ]
      },
      {
        "id": 3,
        "label": "",
        "options": [
          { value: "Lead", label: translate("Lead") },
          { value: "Follow", label: translate("Follow") }
        ]
      },
      {
        "id": 4,
        "label": "",
        "options": [
          { value: "Tell", label: translate("Tell") },
          { value: "Listen", label: translate("Listen") }
        ]
      },
      {
        "id": 5,
        "label": translate("What type of learner are you?"),
        "options": [
          { value: "Visual", label: translate("Visual") },
          { value: "Auditory", label: translate("Auditory") },
          { value: "Both", label: translate("Both") }
        ]
      },
      {
        "id": 6,
        "label": translate("Which best describes how you feel or process information?"),
        "options": [
          { value: "Emotional", label: translate("Emotional") },
          { value: "Engineer", label: translate("Engineer") },
          { value: "Activist", label: translate("Activist") }
        ]
      }
    ]
  };
  

  const navigate = useNavigate();

  const handleModal = () => {
    setModalOpen(prev => !prev);
  };

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await getExperienceApi(CONSTANTS.DISCIPLE_JOURNEY_IDS.JOURNEY_2, user?.id, user?.group);
      if (response?.data?.status === 200) {
        let ques = [];
        response?.data?.data?.forEach(item => {
          ques.push({
            ...item,
            answers: item?.answers?.split(',') || ''
          });
        });
        let result = {};
        response?.data?.data?.forEach(item => {
          if (typeof item.answers === 'string' && item.answers.includes(',')) {
            const answers = item.answers.split(',');
            answers.forEach((answers, index) => {
              result[index + 1] = answers;
            });
          }
        });
        const ans = getAnswerById(response?.data?.data, 56);
        setText(ans);
        setAnswers(result);
        setQuestions(ques);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAnswerChange = (questionId, value) => {
    setError(null);
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: value
    }));
  };

  const submitAnswers = async () => {
    const data = {
      user_id: user?.id,
      group_id: user?.group,
      questions: [
        {
          question_id: '55',
          answers: Object.values(answers).join(',')
        },
        {
          question_id: '56',
          answers: text
        }
      ]
    };

    try {
      setLoading(true);
      const response = await addExperienceApi(data);
      if (response?.data?.status === 200) {
        if(user.user_role === 'LEADER'){
        if (isEdit) {
         navigate('/leader');
        } else {
          navigate('/create-group?isFirst=true');
        }
        }else{
          navigate('/disciple');
        }
        
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleSubmit = async e => {
    e.preventDefault();
    if (Object.keys(answers).length === 6 && text?.trim()) {
      await submitAnswers();
    } else {
      setError(translate("All fields are required!"));
    }
  };

  function getAnswerById(array, id) {
    const obj = array.find(item => item.id === id);
    return obj ? obj.answers : null;
  }

  useEffect(() => {
    fetchQuestions();
  }, []);

  return (
    <>
      <CssBaseline />
      <HeaderContainer pt={1} mt={2} mb={4}>
      
        <ArrowBackIcon style={{cursor: 'pointer', color: 'white'}} onClick={() => setPage(user.user_role === 'LEADER' ? 3 : 2)} color="white" />
      </HeaderContainer>
      <Modal open={modalOpen} setOpen={setModalOpen} buttons={[{name: translate("Done"), onClick: () => handleModal()}]} style={{height: '100%'}} backgroundColor="white">
        <ModalHeader title={translate("How You Think, Learn and Speak")} />
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          {translate("INTROVERT - EXTROVERT - AMBIVERT")}
        </Typography>
        <Typography mt={1} color="#000">
          {translate("Do you recharge in a group (extrovert) or alone (introvert)? Do you process externally (extrovert) ? Do you get peopled out (introvert)? Are you truly a mix of both (ambivert) ?")}
        </Typography>
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          {translate("GO/SEND - LEAD/FOLLOW - TELL/LISTEN")}
        </Typography>
        <Typography mt={1} color="#000">
          {translate("If three people and $3,000 were needed to free nine slaves, would you buy a plane ticket or fund the trip? If an event was going downhill and it seemed like no one knew what to do, would you end up fixing it or supporting the fix? If a question was asked in a group, would you jump in with the answer or wait to see if someone else spoke first?")}
        </Typography>
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          {translate("AUDITORY - VISUAL - BOTH")}
        </Typography>
        <Typography mt={1} color="#000">
          {translate("Can you follow and audio book (auditory learner) ? Do you like pictures, use metaphors, speak word pictures, or have to 'see it' (visual learner) ?")}
        </Typography>
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          {translate("EMOTIONAL - ENGINEER - ACTIVIST")}
        </Typography>
        <Typography mt={1} color="#000">
          {translate("Do you feel life and stories deeply, emotionally? Do you have to figure out the order of things or seem to think linearly? Are you always asking, 'Why would God kill all the people? Why all the rules?'")}
        </Typography>
      </Modal>
      <CssBaseline />
      <Typography variant="h3">{translate("How You Think, Learn and Speak")}</Typography>
      <Typography variant="body1" style={{marginTop: '20px'}}>
        {translate("YOUR JOURNEY")} &bull; {user.user_role === 'LEADER' ? '4' : '3'} {translate("OF")} {user.user_role === 'LEADER' ? '4' : '3'}
      </Typography>
      <IconButton
        style={{
          marginTop: '28px',
          width: '28px',
          height: '28px',
          cursor: 'pointer'
        }}
        onClick={handleModal}
      >
        <HelpOutline style={{color: 'white'}} />
      </IconButton>
      <Box mt={1} px={1} overflow="auto">
        <FormWrapper
          buttonInputs={[
            {
              text: translate("Continue"),
              onClick: e => handleSubmit(e),
              type: 'submit'
            }
          ]}
          loading={loading}
        >
          <Box my={1} overflow="hidden">
            {questions &&
              questions.map((quest, index) => (
                <Box key={index} mb={2} overflow="hidden">
                  {/* {answersData[quest.id]?.length > 0 && (
                    <Box sx={{textAlign: 'left'}}>
                      <Typography variant="body2" gutterBottom>
                        {quest?.question}
                      </Typography>
                    </Box>
                  )} */}

                  <Box overflow="hidden">
                    {answersData[quest.id]?.length > 0 && (
                      <Box display="flex" flexDirection="row" flexWrap="wrap" key={index} overflow="hidden">
                        {answersData[quest.id].map((ans, subIndex) => (
                          <React.Fragment key={subIndex}>
                            {ans?.label && (
                              <Box width="100%" overflow="hidden">
                                <Typography textAlign="left" width="100%">
                                  {ans.label}
                                </Typography>
                              </Box>
                            )}
                            {ans?.options?.length === 2 && subIndex === 1 && (
                              <Box width="100%" overflow="hidden">
                                <Typography textAlign="left" width="100%">
                                {translate("Pick the description that best describes you from each of the three sets.")}
                                </Typography>
                              </Box>
                            )}
                            <Box width={ans?.options?.length === 2 ? (subIndex === 1 ? '53.33%' : '33.33%') : '100%'} ml={subIndex === 2 && ans.options.length === 2 ? '-20%' : 0} overflow="hidden">
                              <RadioGroup
                                aria-label={`question-${ans.id}`}
                                name={`question-${ans.id}`}
                                style={{
                                  display: 'flex',
                                  flexDirection: ans.options.length === 2 ? 'column' : 'row',
                                 
                                }}
                                value={answers[ans.id] || quest?.answers[subIndex] || ''}
                                onChange={event => handleAnswerChange(ans.id, event.target.value)}
                              >
                                <Grid display="flex" flexDirection={ans.options.length === 2 ? 'column' : 'row'}  marginBottom='5px' marginLeft={ans.options.length === 2 ? '0px' : '0px'} container>
                                  {ans.options.map((element, index) => (
                                    <Grid item xs={4} sm={4} md={4} sx={{fontSize: '0.9rem'}} key={index} style={ {paddingRight:'90px',  borderRight: ans.options.length === 2 && subIndex !==3 ?'1px solid white':0}}>
                                      <Box
                                        sx={{
                                          textAlign: 'left',
                                          fontSize: '0.7rem'
                                        }}
                                        mr={2}
                                      >
                                        <FormControlLabel
                                          value={element.value}
                                          control={<Radio />}
                                          label={element.label}
                                          sx={{
                                            fontSize: '0.9rem',
                                            '& span:last-child': {fontSize: {xs: '0.9rem', sm: '1rem', md: '0.9rem'}}
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                  ))}
                                </Grid>
                                
                              </RadioGroup>
                              <Divider sx={{backgroundColor: 'white'}} />
                            </Box>
                          </React.Fragment>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}

            {questions?.some(obj => obj?.id === 56) && (
              <>
                <Box sx={{textAlign: 'left'}}>
                  <Typography variant="body2">{translate("WHY ARE YOU IN DISCIPLESHIP?")}</Typography>
                </Box>
                <TextInput
                  placeholder={translate("Reason for doing discipleship")}
                  value={text || ''}
                  onChange={e => {
                    setError(null);
                    setText(e.target.value);
                  }}
                  autoComplete="off"
                  margin="normal"
                  error={true}
                  helperText=""
                  labelVariant="body2"
                />
                <p className="bottom-text">{translate("HINT: To have a relationship with god. To get to know Him because they believe in Him. To experience Him")} </p>
              </>
            )}
          </Box>
          <Grid item xs={12} sx={{height: '50px'}}>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default DiscipleJourney;
