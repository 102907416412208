import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import useTranslations from '../../../hooks/useTranslation';
import TextInput from '../../../components/common/TextInput';

const AddUpdateBookComponent = ({ bookId, onDelete }) => {
	const { translate } = useTranslations();
	const [planType, setPlanType] = useState('');

	return (
		<>
			<TextInput
				labelColor="black"
				autoComplete="off"
				labelVariant="body2"
				colorBlack={'text-black'}
				value={""}
				onChange={e => { }}
				fullWidth
				mb={0}
				placeholder="Enter meeting location"
				bottomLabel={translate("Title")}
				sx={{
					backgroundColor: 'white',
					color: 'black',
					border: '1px solid #ccc',
					borderRadius: '8px',
					'& .MuiInputBase-input': {
						color: 'black !important',
					},
					'& .MuiInputBase-input:focus': {
						color: 'black !important'
					}
				}}
			/>
			<FormControlLabel
				sx={{
					'& .MuiFormControlLabel-label': {
						color: '#000000 !important',
					}
				}}
				control={
					<Checkbox
						sx={{
							color: '#000000 !important',  // Unchecked color
							'&.Mui-checked': {
								color: '#000000 !important',  // Checked color
							}
						}}
					/>
				}
				label={translate("Ignore this book when calculating remaining weeks to read.")}
			/>

			<RadioGroup
				row
				value={planType}
				sx={{
					marginTop: 2,
					color: '#d32f2f !important',
					'& .MuiFormControlLabel-label': {
						color: '#d32f2f !important'
					}
				}}
				onChange={e => setPlanType(e.target.value)}
			>
				<FormControlLabel
					value="D1"
					control={
						<Radio
							sx={{
								color: '#d32f2f !important',
								'&.Mui-checked': {
									color: '#d32f2f !important'
								}
							}}
						/>
					}
					label="D1"
				/>
				<FormControlLabel
					value="D2"
					control={
						<Radio
							sx={{
								color: '#d32f2f !important',
								'&.Mui-checked': {
									color: '#d32f2f !important'
								}
							}}
						/>
					}
					label="D2"
				/>
				<FormControlLabel
					value="D3"
					control={
						<Radio
							sx={{
								color: '#d32f2f !important',
								'&.Mui-checked': {
									color: '#d32f2f !important'
								}
							}}
						/>
					}
					label="D3"
				/>
			</RadioGroup>
		</>
	);
};

export default AddUpdateBookComponent;
