import React, {useEffect, useState} from 'react';
import {Box, Button, IconButton, List, ListItem, ListItemText, Typography, useMediaQuery} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {deleteUser, getAdminData} from '../../api';
import useAuthStore from '../../store/authStore';
import AdminStructure from './common/AdminStructure';
import useAdminStore from '../../store/adminStore';
import Modal from '../../components/common/Modal';
import Loader from '../../components/common/Loader';
import NoDataFoundMessage from '../../components/common/NoDataFoundMessage';
import useTranslations from '../../hooks/useTranslation';

function Admins() {
  const {translate} = useTranslations();
  const {user} = useAuthStore(state => state);
  const {organizationSelected} = useAdminStore(state => state);
  const [adminData, setAdminData] = useState([]);
  const [searchAdmins, setSearchAdmins] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const fetchAdminData = async () => {
    try {
      setPageLoading(true);
      const response = await getAdminData(organizationSelected);
      if (response?.data?.status === 200) {
        setAdminData(response?.data?.data);
        setSearchAdmins(response?.data?.data);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  };

  const handleOpenModal = admin => {
    setSelectedAdmin(admin);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedAdmin(null);
    setIsModalOpen(false);
  };

  const handleConfirmDelete = () => {
    if (selectedAdmin) {
      handleDelete(selectedAdmin);
      handleCloseModal();
    }
  };

  const handleDelete = async id => {
    try {
      const response = await deleteUser(id);
      if (response?.data?.status === 200) {
        setAdminData(adminData.filter(a => a.id !== id));
      }
    } catch (error) {}
  };

  const searchHandler = value => {
    if (value.length === 0) {
      setAdminData(searchAdmins);
    } else {
      const filteredAdmins = searchAdmins.filter(admin => admin?.name?.toLowerCase().includes(value.toLowerCase()));
      setAdminData(filteredAdmins);
    }
  };

  useEffect(() => {
    fetchAdminData();
  }, [organizationSelected]);

  const activeAdmins = adminData?.filter(admin => admin.status === 'ACTIVE');
  const invitedAdmins = adminData?.filter(admin => admin.status === 'INVITED');

  return (
    <>
      <Box>
        <div
          style={{
            backgroundImage: 'none !important',
            backgroundColor: '#f9f9f9',
            height: '100vh',
            width: '100vw',
            overflowY: isSmallScreen ? 'auto' : 'hidden'
          }}
        >
          <AdminStructure isSearch={true} searchHandler={searchHandler} heading={translate("Admins")}>
            {pageLoading ? (
              <Loader color="#9e0001" />
            ) : (
              <Box
                display="flex"
                justifyContent="space-between"
                px={2}
                // height="85vh"
              >
                <Box
                  sx={{
                    width: {md: '50%', xs: '100%', sm: '100%'},
                    bgcolor: 'white',
                    borderRadius: 4,
                    height: {
                      md: 'auto',
                      xs: 'auto',
                      sm: 'auto'
                    }
                  }}
                >
                  <Typography textAlign="left" m={2} variant="h6" fontWeight={600} color="#d32f2f">
                    {translate("Active Admins")} ({activeAdmins.length})
                  </Typography>
                  {activeAdmins.length !== 0 ? (
                    <List>
                      {activeAdmins.map(admin => (
                        <ListItem key={admin.id}>
                          <ListItemText
                            primary={admin?.name}
                            primaryTypographyProps={{
                              sx: {
                                color: '#000'
                              }
                            }}
                          />
                          <IconButton disabled={admin.id === user?.id}>
                            <DeleteIcon
                              sx={{
                                color: admin.id !== user?.id ? '#d32f2f' : '#ccc'
                              }}
                              onClick={() => handleOpenModal(admin.id)}
                            />
                          </IconButton>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <NoDataFoundMessage mt={5} message={translate("No active admins in this organization")} />
                  )}
                  <Typography textAlign="left" m={2} variant="h6" fontWeight={600} color="#d32f2f">
                    {translate("Invited Admins")} ({invitedAdmins.length})
                  </Typography>
                  {invitedAdmins.length !== 0 ? (
                    <List>
                      {invitedAdmins.map(admin => (
                        <ListItem key={admin.id}>
                          <ListItemText
                            primary={admin?.name}
                            primaryTypographyProps={{
                              sx: {
                                color: '#000'
                              }
                            }}
                          />
                          <IconButton disabled={admin.id === user?.id}>
                            <DeleteIcon
                              sx={{
                                color: admin.id !== user?.id ? '#d32f2f' : '#ccc'
                              }}
                              onClick={() => handleOpenModal(admin.id)}
                            />
                          </IconButton>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <NoDataFoundMessage mt={5} message={translate("No invited admins in this organization")} />
                  )}
                </Box>
              </Box>
            )}
          </AdminStructure>

          <Modal backgroundColor="white" color="black" open={isModalOpen} onClose={handleCloseModal} title={translate("Confirm Delete")}>
            <Box>
              <Typography variant="body1" mb={2} color="black">
                {translate("Are you sure you want to delete")} {selectedAdmin?.name}?
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="error" onClick={handleCloseModal} sx={{mr: 1}}>
                  {translate("Cancel")}
                </Button>
                <Button variant="contained" color="secondary" onClick={handleConfirmDelete}>
                  {translate("Delete")}
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
      </Box>
    </>
  );
}

export default Admins;
