const useTranslations = () => {
  const loadAllTranslations = async (lan = 'en') => {
    try {
      const lang = localStorage.getItem('lang');
     
      const response = await fetch(`/locales/${lang || lan}.json`);
     
      const data = await response.json();
      
      localStorage.setItem('translation', JSON.stringify(data));
    }
     catch (error) {
      console.error('Error loading translation files:', error);
    }
  };

  const translate = key => {
    const storedTranslations = localStorage.getItem('translation');
    const translations = storedTranslations ? JSON.parse(storedTranslations) : {};
    return translations[key] || key;
  };
  const switchLanguage = lang => {
    localStorage.setItem('lang', lang);
    loadAllTranslations(lang);
    window.location.reload();
  };

  return {loadAllTranslations, translate, switchLanguage};
};

export default useTranslations;
