import React, { useState } from 'react';
import { Typography, Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import useTranslations from '../../../hooks/useTranslation';

const AdministratorsCard = () => {
  const { translate } = useTranslations();
  const [adminName, setAdminName] = useState('');
  const [adminRole, setAdminRole] = useState('');

  const handleAddClick = () => {
  };

  const handleCancelClick = () => {
    setAdminName('');
    setAdminRole('');
  };

  const handleSubmitClick = () => {
    // Add logic to save the new administrator here
    setAdminName('');
    setAdminRole('');
  };
  const handleMenuClick = name => {
    // Add logic for menu click actions for each administrator
    console.log(`${name} menu clicked`);
  };

  return (
    <Box
      sx={{
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        maxWidth: '400px',
        height: 'fit-content',
        backgroundColor: '#ffffff'
      }}
    >
      <Typography
        variant="h5"
        mb={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: '#000'
        }}
      >
        {translate('Administrators')}
        <IconButton onClick={handleAddClick} size="small" aria-label="add">
          <AddIcon sx={{ color: '#000' }} />
        </IconButton>
      </Typography>

      <Typography variant="h6" mb={1} sx={{ textAlign: 'start', color: '#d32f2f', fontWeight: 'bold' }}>
        Active Administrators (3)
      </Typography>

      {[
        { label: 'Caleb Billingsley', value: 'Caleb Billingsley' },
        { label: 'Jaime Lewis', value: 'Jaime Lewis' },
        { label: 'Doug Burrier', value: 'Doug Burrier' }
      ].map((item, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            textAlign: 'start',
            color: '#000',
            mt: 1
          }}
        >
          <Typography variant="body1" sx={{ color: '#000' }}>
            {item.label}
          </Typography>
          <IconButton onClick={() => handleMenuClick(item.label)} size="small" aria-label="menu">
            <MenuIcon sx={{ color: '#000' }} />
          </IconButton>
        </Box>
      ))}

      <Typography variant="h6" mt={2} mb={1} sx={{ textAlign: 'start', color: '#d32f2f', fontWeight: 'bold' }}>
        Invited Administrators (0)
      </Typography>
    </Box>
  );
};

export default AdministratorsCard;
