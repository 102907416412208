import React, { useState, useEffect } from 'react';
import {Box, Button, Typography} from '@mui/material';
import sdlogo from '../../../images/sdlogo.png';
import {Link, useNavigate} from 'react-router-dom';
import useTranslations from '../../../hooks/useTranslation';

const GetStartedZero = ({setPage}) => {
  const { translate } = useTranslations();
  return (
    <Box mx={3} my={5}>
      <img src={sdlogo} width="80" height="80" />
      <Typography mt={2} variant="h3" fontWeight="medium">
        {translate("Let's Make Disciples!")}
      </Typography>
      <Typography variant="h6" py={2}>
        {translate("Ready to get started making disciples? Need a simple 'how to' method proven in the real world.")}
      </Typography>
      <Typography variant="h6" py={2} style={{marginBottom: "20px"}}>
        {translate("Set up your d-group in under ten minutes, supercharge your disciple making, and change the world!")}
      </Typography>

      <Button type="submit" fullWidth onClick={() => setPage(2)} variant="contained" color="secondary" style={{fontWeight: 400}}>
        {translate("SEE HOW IT WORKS")}
      </Button>
      <Typography my={2}>
        {translate("Already Registered?")}{' '}
        <Link to="/login" className="anchor-link">
          {translate("Log in here.")}
        </Link>
      </Typography>
    </Box>
  );
};

export default GetStartedZero;
