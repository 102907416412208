import React from 'react';
import sdlogo from '../../../images/sdlogo.png';
import {Box, Button, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import { CONSTANTS } from '../../../constants';
import { margin } from '@mui/system';
import useTranslations from '../../../hooks/useTranslation';

const GetStartedFive = () => {
  const { translate } = useTranslations();
  const navigate = useNavigate();

  return (
    <Box mx={3} my={5} style={{width:"75%",margin:"auto","text-align":"center"}}>
      <img src={sdlogo} width="80" height="80" />
      <Typography mt={2} variant="h3" fontWeight="medium">
        {translate("Let's Make Disciples!")}
      </Typography>
      <Typography mb={4} >{translate("HOW IT WORKS")} &bull; {translate("5 OF 5")}</Typography>
      <Typography variant="h6" mb={4}>
      {translate("We promise that you can get these same crazy results and do that")} <b>{translate("one thing")}</b> {translate("Jesus called us to do!")}   
      </Typography>
      <Typography variant="h6" mb={4}>
      {translate("The following resources will get you up to speed on the SD Method as you start.")}
      </Typography>
      <a style={{color: "white"}} href={CONSTANTS.GET_STARTED_LINKS.ONE} target='_blank'><Typography variant="h5">{translate("How to Make Disciples")}</Typography></a>
      <Typography pb={1}>{translate("The how-to book of everything God has shown us.")}</Typography>

      <a style={{color: "white"}} href={CONSTANTS.GET_STARTED_LINKS.TWO} target='_blank'><Typography variant="h5">{translate("How to Join a Live or Virtual Workshop")}</Typography></a>
      <Typography pb={1}>{translate("The fastest way to get going.")}</Typography>

      <a style={{color: "white"}} href={CONSTANTS.GET_STARTED_LINKS.THREE} target='_blank'><Typography variant="h5">{translate("Get Coaching")}</Typography></a>
      <Typography style={{marginBottom: "20px"}}>{translate("Stay confident as we walk alongside you all the way.")}</Typography>

      <Button type="submit"  onClick={() => navigate('/signup')} variant="contained" color="secondary" style={{marginTop: '20px', fontWeight: 400, marginBottom: '40px', width: '80%'}}>
      {translate("CREATE YOUR GROUP")}
      </Button>
      <Typography fontStyle="italic" letterSpacing={1}>
      {translate("We aren't trying to sell anything. If money is tight, we've got you covered!")}
      </Typography>
    </Box>
  );
};

export default GetStartedFive;