import React from 'react';

const IconContainer = ({ icon, width, height, marginRight, color }) => {
	return (
		<div
			dangerouslySetInnerHTML={{ __html: icon(color) }}
			style={{
				width: width,
				height: height,
				marginRight: marginRight,
				display: 'flex',
				alignItems: 'center'
			}}
		/>
	);
};

export default IconContainer;
