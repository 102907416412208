import React, {useState} from 'react';
import {Typography, IconButton, Box, Button, MenuItem, TextField} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import useTranslations from '../../../hooks/useTranslation';
import SelectInput from '../../../components/common/SelectInput';
import TextInput from '../../../components/common/TextInput';

const OrganizationContactCard = () => {
  const {translate} = useTranslations();
  const [isEditing, setIsEditing] = useState(false);
  const [contactName, setContactName] = useState([
    {label: translate('Suzy Q'), value: 'Suzy Q'},
    {label: translate('John Doe'), value: 'John Doe'},
    {label: translate('Jane Smith'), value: 'Jane Smith'}
  ]);
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleSubmitClick = () => {
    // Add logic to save changes here
    setIsEditing(false);
  };

  return (
    <Box
      sx={{
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        maxWidth: '400px',
        backgroundColor: '#ffffff'
      }}
    >
      <Typography variant="h5" mb={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#000'}}>
        {translate('Organization Contact')}
        {!isEditing && (
          <IconButton onClick={handleEditClick} size="small" aria-label="edit">
            <EditIcon sx={{color: '#000'}} />
          </IconButton>
        )}
      </Typography>
      {isEditing ? (
        <>
          <SelectInput 
            bottomLabel={translate('Contact Name')}
            bottomLabelColor="black"
            value={contactName}
            setValue={(e) => setContactName(e.target.value)}
            onChange={(e) => setExistingLeader(e.target.value)}
            fullWidth
            variant="outlined"
            optionArray={contactName}
            sx={{
              borderRadius: '8px',
            }}
          />

          <TextInput
            bottomLabel={translate('Role')}
            labelColor="black"
            labelVariant="body2"
            value={role}
            onChange={e => setRole(e.target.value)}
            fullWidth
            colorBlack={'text-black'}
            sx={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #ccc',
              borderRadius: '8px',
              '& .MuiInputBase-input': {
                color: 'black !important'
              },
              '& .MuiInputBase-input:focus': {
                color: 'black !important'
              }
            }}
          />
          <TextInput
            bottomLabel={translate('Email')}
            labelColor="black"
            labelVariant="body2"
            value={email}
            onChange={e => setEmail(e.target.value)}
            fullWidth
            colorBlack={'text-black'}
            sx={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #ccc',
              borderRadius: '8px',
              '& .MuiInputBase-input': {
                color: 'black !important'
              },
              '& .MuiInputBase-input:focus': {
                color: 'black !important'
              }
            }}
          />
          <TextInput
            bottomLabel={translate('Phone')}
            labelColor="black"
            labelVariant="body2"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            fullWidth
            colorBlack={'text-black'}
            sx={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #ccc',
              borderRadius: '8px',
              '& .MuiInputBase-input': {
                color: 'black !important'
              },
              '& .MuiInputBase-input:focus': {
                color: 'black !important'
              }
            }}
          />
          <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '8px', marginTop: '8px'}}>
            <Button variant="contained" color="primary" onClick={handleSubmitClick}>
              Submit
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCancelClick} sx={{borderColor: '#000', color: '#000'}}>
              Cancel
            </Button>
          </Box>
        </>
      ) : (
        <Box sx={{marginTop: '8px'}}>
          <Typography variant="h5" mb={1} sx={{textAlign: 'start', color: '#000', fontWeight: 'bold'}}>
            Suzy Q
            {/* {contactName} */}
          </Typography>
          {[
            {label: 'Role:', value: role},
            {label: 'Email:', value: email},
            {label: 'Phone:', value: phone}
          ].map((item, index) => (
            <Typography variant="body1" sx={{color: '#000', display: 'flex', textAlign: 'start'}} key={index}>
              <span>{item.label}</span>
              <span>{item.value}</span>
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default OrganizationContactCard;
