import {PageOne, PageTwo, PageThree, PageFour} from '../components/modules/Group';
import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {BASE_URL, CONSTANTS} from '../constants';
import {validateEmail} from '../utils';
import BottomBar from '../components/common/BottomBar';
import {Box} from '@mui/material';
import {inviteDiscipleApi, sendInviteToDisciple} from '../api';
import HeaderContainer from '../components/common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {toast} from 'react-hot-toast';
import useAuthStore from '../store/authStore';
import InviteUser from '../components/modules/InviteUser';
import useLeaderStore from '../store/leaderStore';
import useTranslations from '../hooks/useTranslation';
import PageFive from '../components/modules/Group/PageFive';

const CreateGroup = ({handleClose}) => {
  const {translate} = useTranslations();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [formDataList, setFormDataList] = useState([]);
  const [error, setError] = useState(null);
  const [discipleData, setDiscipleData] = useState({
    name: '',
    email: ''
  });
  const {setGroupsData: setStoreGroup} = useLeaderStore(state => state)
  const [currentPage, setCurrentPage] = useState(searchParams.get('isdisciple') === 'true' ? 6 : Number(searchParams.get('page')) ? 5 : searchParams.get('isFirst') === 'true' ? 1 : 2);
  const [groupData, setGroupData] = useState({
    id: '',
    name: '',
    organization_id: '',
    gender: '',
    level: '',
    day: '',
    time: '',
    place: '',
    startDate: '',
    noOfWeeks: '',
    avatar: ''
  });
  const {user: userData, token} = useAuthStore(state => state);
  const role = userData?.user_role;
  const isPublicAccount = userData?.is_public === 1 ? true : false;
  const nextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };
  const handleSubmit = async captchaToken => {
    const formData = new FormData();
    formData.append('name', groupData?.name);
    formData.append('organization_id', groupData?.organization_id);
    formData.append('gender', groupData?.gender);
    formData.append('level', groupData?.level);
    if (groupData?.avatar) {
      formData.append('avatar', groupData?.avatar);
    }
    formData.append('captcha_code', captchaToken);

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: formData
    };
    const res = await fetch(`${BASE_URL}/group`, requestOptions);
    const data = await res.json();
    if (data?.status === 200) {
      setGroupData(prev => ({
        ...prev,
        id: data?.data?.id
      }));
      setStoreGroup(data?.data);
      if (role === 'ADMIN') {
        // Set the next page to 7 if the user is an admin
        setCurrentPage(7);
      } else {
        nextPage();
      }
    }
    if (data.status === 400) {
      toast.error(data?.message, {id: 'error_message'});
    }
  };

  const inviteDisciple = async (user, type) => {
    const data = {
      first_name: user?.name,
      email: String(user?.email).trim(),
      group_id: groupData?.id
    };
    try {
      setLoading(true);
      const response = await inviteDiscipleApi(data);
      if (response?.data?.status === 200) {
        const userId = response?.data?.data?.user_id;
        sendInviteHandler(type);
        if (type === 'continue') {
          setFormDataList([]);
          if (searchParams.get('isFirst') === 'true') {
            navigate('/leader');
          } else {
            navigate(-1);
          }
        } else {
          setFormDataList(() => [...formDataList, {...discipleData, user_id: userId}]);
        }
        setDiscipleData({
          name: '',
          email: ''
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const onAddDisciple = async () => {
    if (!discipleData.email && !discipleData.name.trim()) {
      setError(translate("All fields are required!"));
    }
    if (discipleData.email && !discipleData.name.trim()) {
      setError(translate("All fields are required!"));
    }
    if (!discipleData.email && discipleData.name.trim()) {
      setError(translate("All fields are required!"));
    }
    if (!validateEmail(discipleData.email)) {
      return;
    }
    if (discipleData.name.trim() && discipleData.email) {
      inviteDisciple(discipleData, 'add');
    }
  };

  const sendInviteHandler = async type => {
    let emails = [];
    if (discipleData?.name && discipleData?.email) {
      emails.push(discipleData?.email);
    }
    formDataList?.forEach(user => {
      emails.push(user.email);
    });
    const data = {
      emails: [discipleData?.email],
      group_id: groupData?.id,
      inviter: `${userData?.first_name} ${userData?.last_name}`
    };
    try {
      setLoading(true);
      await sendInviteToDisciple(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const continueHandler = async () => {
    if (!discipleData.email && !discipleData.name.trim() && formDataList.length === 0) {
      setError(translate("Please add at-least one disciple!"));
      return;
    }
    if (discipleData.email && !discipleData.name.trim()) {
      setError(translate("All fields are required!"));
      return;
    } else if (!discipleData.email && discipleData.name.trim()) {
      setError(translate("All fields are required!"));
      return;
    } else if (validateEmail(discipleData.email) && discipleData.name.trim()) {
      inviteDisciple(discipleData, 'continue');
    } else {
      // if (searchParams.get('isFirst') === 'true') {
      //   navigate('/leader');
      // } else {
      //   navigate(-1);
      // }
      // setFormDataList([]);
      setError(translate("Please enter a valid email address."));

    }
  };
  useEffect(() => {
    if (Number(searchParams.get('group'))) {
      setGroupData(prev => {
        return {
          ...prev,
          id: Number(searchParams.get('group'))
        };
      });
    }
  }, []);

  return (
    <Box mt={1} px={2} overflow="auto" position="relative">
      {searchParams.get('isdisciple') === 'true' && currentPage === 6 && (
        <HeaderContainer position="fixed" top={50}>
          <ArrowBackIcon style={{cursor: 'pointer', color: 'white'}} onClick={() => navigate(-1)} />
        </HeaderContainer>
      )}
      {currentPage === 1 &&  role !== 'ADMIN' && <PageOne nextPage={nextPage} />}
      {currentPage === 2 && <PageTwo nextPage={nextPage} setGroupData={setGroupData} />}
      {currentPage === 3 && <PageThree groupData={groupData} setGroupData={setGroupData} handleSubmit={handleSubmit} isPublicAccount={isPublicAccount} />}
      {currentPage === 4 && role !== 'ADMIN' && <PageFour groupData={groupData} nextPage={() => navigate(`/leader/reading-plans?group_id=${groupData?.id}`)} />}
      {currentPage === 5 && role !== 'ADMIN' && <InviteUser continueHandler={continueHandler} onSubmit={onAddDisciple} formDataList={formDataList} setFormDataList={setFormDataList} setData={setDiscipleData} data={discipleData} loading={loading} isDisciple={true} setError={setError} error={error} heading={translate("Invite Disciples")} buttonTextOne={translate("Add Another Invitation")} buttonTextTwo={translate("Send Invitation(s)")} />}
      {currentPage === 7 && role === 'ADMIN' && <PageFive groupData={groupData} handleClose={handleClose} />}
      {searchParams.get('isdisciple') === 'true' && (
        <Box position="fixed" bottom={0} left={0} width="100%">
          <BottomBar
            buttonInputs={[
              {text: translate("Home"), onClick: () => navigate('/leader')},
              {
                text: '',
                onClick: () => (addText === translate("Add Group") ? navigate('/create-group') : navigate(`/create-group?isdisciple=true&group=${params.id}`)),
                isShow: false
              },
              {text: translate("Settings"), onClick: () => {}}
            ]}
          />
        </Box>
      )}
    </Box>
  );
};

export default CreateGroup;
