import React, { useState } from 'react';
import { Box, List, ListItemButton, ListItemText, Typography, Drawer, IconButton, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PasswordIcon from '@mui/icons-material/Password';
import sdlogo from '../../../images/sdlogo.png';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import useAuthStore from '../../../store/authStore';
import useAdminStore from '../../../store/adminStore';
import useLeaderStore from '../../../store/leaderStore';
import { useNavigate } from 'react-router-dom';
import { DeleteOutline } from '@mui/icons-material';
import Modal from '../../../components/common/Modal';
import { deleteUser } from '../../../api';
import useTranslations from '../../../hooks/useTranslation';
import { barIcon, doublePersonIcon, exitIcon, homeIcon, multiPersonIcon, settingIcon, sheetIcon, singlePersonIcon } from '../../../utils/svg';
import IconContainer from '../../../components/common/IconContainer';

function Sidebar({ totalDisciples, setIsDeleteModalOpen }) {
  const theme = useTheme();
  const iconColor = theme.palette.icon.secondary;
  const bgColor = theme.palette.background.primary;
  const selectBgColor = theme.palette.background.secondary;
  const textColor = theme.palette.text.primary;
  const { translate } = useTranslations();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { logoutUser, user } = useAuthStore(state => state);
  const { clearAdminData } = useAdminStore(state => state);
  const { clearLeaderData } = useLeaderStore(state => state);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const pathName = window.location.pathname;

  const sidebarOptions = [
    {
      id: 1,
      path: "/admin-dashboard",
      icon: (path) => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={homeIcon} width={20} height={20} marginRight={8} />,
      title: "Dashboard",
      type: "list-item"
    },
    {
      id: 2,
      path: '/admin-dashboard/groups',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={multiPersonIcon} width={20} height={20} marginRight={8} />,
      title: translate("Groups"),
      type: 'list-item'
    },
    {
      id: 3,
      path: '/admin-dashboard/leaders',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={doublePersonIcon} width={20} height={20} marginRight={8} />,
      title: translate("Leaders"),
      type: 'list-item'
    },
    {
      id: 4,
      path: '/admin-dashboard/disciples',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={singlePersonIcon} width={20} height={20} marginRight={8} />,
      title: translate("Disciples"),
      type: 'list-item'
    },
    // {
    //   id: 5,
    //   path: '/admin-dashboard/admins',
    //   icon: path => <PersonIcon sx={{ mr: 1, color: path === pathName ? iconColor : '#8A8A8A' }} />,
    //   title: translate("Admins"),
    //   type: 'list-item'
    // },
    // {
    //   id: 6,
    //   path: '/admin-dashboard/promotions',
    //   icon: path => <GroupsIcon sx={{ mr: 1, color: path === pathName ? iconColor : '#8A8A8A' }} />,
    //   title: translate("Promotions"),
    //   type: 'list-item'
    // },
    {
      id: 7,
      path: '/admin-dashboard/reports',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={sheetIcon} width={20} height={20} marginRight={8} />,
      title: translate("Reports"),
      type: 'list-item'
    },
    {
      id: 8,
      type: 'seperator'
    },
    {
      id: 9,
      path: '/admin-dashboard/reading-plans',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={barIcon} width={20} height={20} marginRight={8} />,
      title: translate("Reading Plan"),
      type: 'list-item'
    },
    {
      id: 10,
      path: '/admin-dashboard/settings',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={settingIcon} width={20} height={20} marginRight={8} />,
      title: translate("Settings"),
      type: 'list-item'
    },
    {
      id: 11,
      path: '/admin-dashboard/change-password',
      icon: path => <PasswordIcon sx={{ mr: 1, color: path === pathName ? iconColor : '#8A8A8A' }} />,
      title: translate("Change Password"),
      type: 'list-item'
    }
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoutUserHandler = () => {
    logoutUser();
    clearLeaderData();
    clearAdminData();
  };

  const handleDeleteUser = async () => {
    try {
      setLoading(true);
      const res = await deleteUser(user?.id);
      if (res?.data?.status === 200) {
        setModalOpen(false);
        logoutUserHandler();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const drawerContent = (
    <Box bgcolor={bgColor} color={textColor} display="flex" flexDirection="column" px={2} pb={3} overflow={'auto'}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 6,
          margin: "20px 0",
        }}
      >
        <img src={sdlogo} alt="" width={25} height={25} />
        <Typography fontWeight="bold">Sustainable Discipleship</Typography>
      </div>
      <Box
        borderRadius="10px"
        textAlign="center"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        py={2}
        sx={{
          background: selectBgColor,
          cursor: 'pointer'
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h3">{totalDisciples} </Typography>
        </Box>
        <Typography variant="subtitle1">{translate("Lifetime Disciples")}</Typography>
      </Box>
      <List sx={{ color: iconColor, margin: "0 10px" }}>
        {sidebarOptions.map(option => {
          if (option.type === 'seperator') {
            return <hr key={option.id} style={{ width: '90%', marginLeft: '6px', opacity: '50%', margin: '10px 0' }} />;
          } else {
            return (
              <ListItemButton
                key={option.id}
                className="mb-2"
                onClick={() => {
                  navigate(option.path);
                }}
                sx={{
                  height: '40px',
                  marginBottom: '15px',
                  background: pathName === option.path ? selectBgColor : 'none',
                  borderRadius: '30px',
                }}
              >
                {option.icon(option.path)}
                <ListItemText
                  primary={option.title}
                  primaryTypographyProps={{
                    style: {
                      fontSize: '12px',
                      color: pathName === option.path ? 'white' : '#8A8A8A',
                      fontWeight: 'bold',
                    }
                  }}
                />
              </ListItemButton>
            );
          }
        })}
        <ListItemButton
          sx={{
            height: '40px',
            borderRadius: '30px',
            marginBottom: "10px",
          }}
          onClick={() => setModalOpen(true)}
        >
          <DeleteOutline sx={{ mr: 1, color: '#8A8A8A' }} />
          <ListItemText
            primary={translate("Delete My Account")}
            primaryTypographyProps={{
              style: {
                fontSize: '12px',
                color: '#8A8A8A',
                fontWeight: 'bold'
              }
            }}
          />
        </ListItemButton>
        <ListItemButton
          sx={{
            borderRadius: '30px',
            paddingLeft: "20px",
          }}
          onClick={() => logoutUserHandler()}
        >
          <IconContainer color={'#8A8A8A'} icon={exitIcon} width={20} height={20} marginRight={8} />
          <ListItemText
            primary={translate("Log Out")}
            primaryTypographyProps={{
              style: {
                fontSize: '12px',
                color: '#8A8A8A',
                fontWeight: 'bold'
              }
            }}
          />
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          display: { md: 'none' },
          position: 'absolute',
          top: 16,
          left: 16
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 250 }
        }}
      >
        {drawerContent}
      </Drawer>
      <Box
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          width: { md: 250 },
        }}
      >
        {drawerContent}
      </Box>

      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        title={translate("Delete Account")}
        modalbgColor='white'
        titleStyles={{ color: 'black', fontWeight: 'bold' }}
        bodyStyles={{ color: 'black' }}
        buttons={[
          { name: translate("Close"), onClick: () => setModalOpen(false) },
          { name: translate("Delete"), onClick: () => handleDeleteUser(), loading: loading }
        ]}
      >
        <Typography sx={{ color: 'black' }}>{translate("Are you sure you want to delete your account? All associated data will be removed from the application soon, and you will be logged out immediately.")}</Typography>
      </Modal>
    </>
  );
}

export default Sidebar;
