import {create} from 'zustand';
import {persist, createJSONStorage} from 'zustand/middleware';

const authStore = (set, get) => ({
  user: null,
  token: null,
  rememberEmail: '',

  loginUser: userData => {
    set(state => ({
      ...state,
      user: userData,
      token: userData.authToken
    }));
  },
  logoutUser: () => {
    set(state => ({
      ...state,
      user: null,
      token: null
    }));
  },
  setRememberEmail: email => {
    set(state => ({
      ...state,
      rememberEmail: email
    }));
  },
  updateUserData: data => {
    set(state => ({
      ...state,
      user: {...state.user, ...data}
    }));
  },
  loginNewUser: data => {
    set(state => ({
      ...state,
      token: data?.authToken,
      user: data
    }));
  }
});

const useAuthStore = create(
    persist(authStore, {
      name: 'auth-storage',
      storage: createJSONStorage(() => localStorage)
    })
);

export default useAuthStore;
