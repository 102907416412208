import React, {useState} from 'react';
import {TextField, Button, IconButton, Typography, Box} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TextInput from '../../../components/common/TextInput';
import useTranslations from '../../../hooks/useTranslation';

const NameAndAddressCard = () => {
  const {translate} = useTranslations();
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState('New Life Church');
  const [address, setAddress] = useState('123 Farm Road\nAcworth, Ga 30101\nUSA');

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleSubmitClick = () => {
    // Add logic to save changes here
    setIsEditing(false);
  };

  return (
    <Box
      sx={{
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        maxWidth: '400px',
        backgroundColor: '#ffffff'
      }}
    >
      <Typography variant="h5" mb={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#000'}}>
        {translate('Name and Address')}
        {!isEditing && (
          <IconButton onClick={handleEditClick} size="small" aria-label="edit">
            <EditIcon sx={{color: '#000'}} />
          </IconButton>
        )}
      </Typography>

      {isEditing ? (
        <>
          <TextInput
            bottomLabel={translate('Church Name')}
            labelColor="black"
            labelVariant="body2"
            value={name}
            onChange={e => setName(e.target.value)}
            fullWidth
            colorBlack={'text-black'}
            sx={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #ccc',
              borderRadius: '8px',
              '& .MuiInputBase-input': {
                color: 'black !important'
              },
              '& .MuiInputBase-input:focus': {
                color: 'black !important'
              }
            }}
          />
          <TextInput
            bottomLabel={translate('Address')}
            labelColor="black"
            labelVariant="body2"
            value={address}
            onChange={e => setAddress(e.target.value)}
            fullWidth
            colorBlack={'text-black'}
            sx={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #ccc',
              borderRadius: '8px',
              '& .MuiInputBase-input': {
                color: 'black !important'
              },
              '& .MuiInputBase-input:focus': {
                color: 'black !important'
              }
            }}
          />
          <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '8px', marginTop: '8px'}}>
            <Button variant="contained" color="primary" onClick={handleSubmitClick}>
              Submit
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCancelClick}>
              Cancel
            </Button>
          </Box>
        </>
      ) : (
        <Box sx={{marginTop: '8px'}}>
          <Typography variant="h5" mb={1} sx={{textAlign: 'start', color: '#000', fontWeight: 'bold'}}>
            {name}
          </Typography>
          <Typography variant="body1" sx={{whiteSpace: 'pre-line', color: '#000', textAlign: 'start'}}>
            {address}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default NameAndAddressCard;
