import {Box} from '@mui/material';

const MainContainer = ({children}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: {xs: '100vw', sm: '100vw', md: '30vw'},
        minWidth: '22rem',
        height: '100%',
        margin: {xs: 'auto', sm: 'auto', md: '0'}
      }}
    >
      {children}
    </Box>
  );
};

export default MainContainer;
