import React, {useEffect, useState} from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import {CONSTANTS} from '../../../constants';
import {Alert, FormLabel, Grid, Radio, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {useNavigate} from 'react-router-dom';
import CheckBoxInput from '../../common/CheckBoxInput';
import {isEmptyObjectValues, mergeArraysIntoObject} from '../../../utils';
import {addExperienceApi, getExperienceApi} from '../../../api';
import HeaderContainer from '../../common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CssBaseline from '@mui/material/CssBaseline';
import useAuthStore from '../../../store/authStore';
import useTranslations from '../../../hooks/useTranslation';

const LeaderJourney = ({setPage, isEdit,user}) => {
  const {translate} = useTranslations();
  const [loading, setLoading] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [leaderJourney, setLeaderJourney] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const {user: userData} = useAuthStore(state => state);

  const answersData = {
    "LEADER_JOURNEY_QUESTIONS": [
      {
        "id": 1,
        "width": "100%",
        "label": translate("I'VE ATTENDED"),
        "type": "checkbox",
        "options": [
          { value: "Live Workshop", label: translate("Live Workshop") },
          { value: "Virtual Workshop", label: translate("Virtual Workshop") },
          { value: "N/A", label: translate("N/A") }
        ],
        "selectedAnswers": []
      },
      {
        "id": 2,
        "width": "100%",
        "label": translate("I'VE READ"),
        "type": "checkbox",
        "options": [
          { value: "How to Make Disciples", label: translate("How to Make Disciples") },
          { value: "Well Made Well Done", label: translate("Well Made Well Done") },
          { value: "N/A", label: translate("N/A") }
        ],
        "selectedAnswers": []
      },
      {
        "id": 3,
        "width": "50%",
        "label": translate("I'VE PARTICIPATED IN"),
        "type": "checkbox",
        "options": [
          { value: "D1", label: translate("D1") },
          { value: "D2", label: translate("D2") },
          { value: "D3", label: translate("D3") },
          { value: "N/A", label: translate("N/A") },
          { value: "Outside Discipleship", label: translate("Outside Discipleship") }
        ],
        "selectedAnswers": []
      },
      {
        "id": 4,
        "width": "50%",
        "label": translate("I'VE LEAD"),
        "type": "checkbox",
        "options": [
          { value: "D1", label: translate("D1") },
          { value: "D2", label: translate("D2") },
          { value: "D3", label: translate("D3") },
          { value: "N/A", label: translate("N/A") },
        ],
        "selectedAnswers": []
      },
      {
        "id": 5,
        "width": "50%",
        "label": translate("I'VE BEEN COACHED"),
        "type": "radio",
        "options": [
          { value: "Yes", label: translate("Yes") },
          { value: "No", label: translate("No") }
        ],
        "selectedAnswers": ""
      },
      {
        "id": 6,
        "width": "50%",
        "label": translate("I WANT COACHING"),
        "type": "radio",
        "options": [
          { value: "Yes", label: translate("Yes") },
          { value: "No", label: translate("No") }
        ],
        "selectedAnswers": ""
      }
    ],
  };  
  const submitFormdata = async () => {
    const questions = [];
    for (const questionId in selectedAnswers) {
      questions.push({
        question_id: parseInt(questionId), // Convert string to integer
        answers: Array.isArray(selectedAnswers[questionId]) ? selectedAnswers[questionId].join(',') : selectedAnswers[questionId]
      });
    }
    try {
      setLoading(true);
      const response = await addExperienceApi({questions: questions});
      if (response?.data?.status === 200) {
          setPage(4)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isEdit) {
        navigate('/leader');
      } else {
        navigate('/create-group');
      }
    }
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (isEmptyObjectValues(selectedAnswers)) {
      setError(translate('All fields are required!'));
    } else {
      setError(null);
      submitFormdata();
    }
  };
  const getExperience = async () => {
    try {
      setLoading(true);
      const response = await getExperienceApi(CONSTANTS.DISCIPLE_JOURNEY_IDS.LEADER_JOURNEY);
      if (response?.data?.status === 200) {
        let answerData = {};
        const filteredData = answersData.LEADER_JOURNEY_QUESTIONS.filter(detail => {
          return response?.data?.data?.some(item => item.id === detail.id);
        });
        answerData = mergeArraysIntoObject(filteredData, response?.data?.data);
        setSelectedAnswers(answerData);
        setLeaderJourney(filteredData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
const handleCheckboxChange = (detailId, option) => {
    const updatedSelectedAnswers = {...selectedAnswers};
    setError(null);

    if (option === 'N/A' && detailId === 3 && updatedSelectedAnswers[detailId]?.includes('N/A')) {
      if(updatedSelectedAnswers[3]?.includes("Outside Discipleship")) {
        updatedSelectedAnswers[3] = ["Outside Discipleship"];
      }else {
        updatedSelectedAnswers[3] = [];
      }
      setSelectedAnswers(updatedSelectedAnswers);
      return;
    }else if(option === 'N/A' && detailId === 3) {
      if(updatedSelectedAnswers[3]?.includes("Outside Discipleship")) {
        if(updatedSelectedAnswers[3]?.includes("N/A")) {
          updatedSelectedAnswers[3] = ["Outside Discipleship"];
        }else {
          updatedSelectedAnswers[3] = ["N/A", "Outside Discipleship"];
        }
      }else {
        if(updatedSelectedAnswers[3]?.includes("N/A")) {
          updatedSelectedAnswers[3] = [];
        }else {
          updatedSelectedAnswers[3] = ["N/A"];
        }
      }
      setSelectedAnswers(updatedSelectedAnswers);
      return;
    }else if(option === "Outside Discipleship" && detailId === 3){
      if(updatedSelectedAnswers[3]?.includes("Outside Discipleship")) {
          updatedSelectedAnswers[3] = updatedSelectedAnswers[3]?.filter((text) => text !== "Outside Discipleship")
      }else {
          updatedSelectedAnswers[3] = [...updatedSelectedAnswers[3], "Outside Discipleship"];
      }
      setSelectedAnswers(updatedSelectedAnswers);
      return;
    }

    if (option === 'D1' && detailId === 3) {
      if (updatedSelectedAnswers[detailId]?.includes('D1')) {
        if(updatedSelectedAnswers[3]?.includes("Outside Discipleship")) {
          updatedSelectedAnswers[3] = ["Outside Discipleship"];
          //updatedSelectedAnswers[4] = [];
        }else {
          updatedSelectedAnswers[3] = [];
          //updatedSelectedAnswers[4] = [];
        }
      } else {
        if(updatedSelectedAnswers[3]?.includes("Outside Discipleship")) {
          updatedSelectedAnswers[3] = ['D1', "Outside Discipleship"];
        }else {
          updatedSelectedAnswers[3] = ['D1'];
        }
        //updatedSelectedAnswers[4] = updatedSelectedAnswers[4]?.filter(item => item === 'D1');
      }
      setSelectedAnswers(updatedSelectedAnswers);
      return;
    }
    if (option === 'D2' && detailId === 3) {
      if (updatedSelectedAnswers[detailId]?.includes('D2')) {
        if(updatedSelectedAnswers[3]?.includes("Outside Discipleship")) {
          updatedSelectedAnswers[3] = ["Outside Discipleship"];
          //updatedSelectedAnswers[4] = [];
        }else {
          updatedSelectedAnswers[3] = [];
          //updatedSelectedAnswers[4] = [];
        }
      } else {
        if(updatedSelectedAnswers[3]?.includes("Outside Discipleship")) {
          updatedSelectedAnswers[3] = ["D1", 'D2', "Outside Discipleship"];
        }else {
          updatedSelectedAnswers[3] = ["D1", 'D2'];
        }
        //updatedSelectedAnswers[4] = updatedSelectedAnswers[4]?.filter(item => item === 'D2' || item === 'D1');
      }
      setSelectedAnswers(updatedSelectedAnswers);
      return;
    }

    // If N/A is selected, uncheck all other options for the current detailId
    if (option === 'N/A') {
      // Uncheck other options for the current detailId
      
      if(updatedSelectedAnswers[detailId].includes('N/A')){
      updatedSelectedAnswers[detailId] = [];
      }else{
        updatedSelectedAnswers[detailId] = [option];
      }
      // If N/A is selected in detailID 3, uncheck all checkboxes for detailID 4
      if (detailId === 3) {
       //updatedSelectedAnswers[4] = [];
      }
    } else {
      // If other than N/A selected
      if (detailId === 3) {
        // For detailId 3, allow only one checkbox to be selected at a time
        if(updatedSelectedAnswers[3]?.includes(option)) {
          if(updatedSelectedAnswers[3]?.includes("Outside Discipleship")) {
            updatedSelectedAnswers[3] = ["Outside Discipleship"];
          }else {
            updatedSelectedAnswers[3] = [];
          }
          //updatedSelectedAnswers[4] = [];
        }else {
          if(updatedSelectedAnswers[3]?.includes("Outside Discipleship")) {
            updatedSelectedAnswers[3] = ["D1", "D2", option, "Outside Discipleship"];
          }else {
            updatedSelectedAnswers[3] = ["D1", "D2", option];
          }

        }
        // Uncheck N/A if selected for detailId 3
        updatedSelectedAnswers[3] = updatedSelectedAnswers[3].filter(item => item !== 'N/A');
        if (option === 'Outside Discipleship') {
          //updatedSelectedAnswers[4] = [];
          setSelectedAnswers(updatedSelectedAnswers);
        }
      } else {
        // For other detailIds, update selected options accordingly
        const selectedOptions = updatedSelectedAnswers[detailId];
        const index = selectedOptions?.indexOf(option);
        if (index === -1) {
          // Option not selected, add it
          updatedSelectedAnswers[detailId] = [...selectedOptions, option];
        } else {
          // Option already selected, remove it
          if(detailId === 3){
            if(updatedSelectedAnswers[3]?.includes("Outside Discipleship")){
              updatedSelectedAnswers[3] = ["Outside Discipleship"];
            }else{
              updatedSelectedAnswers[3] = [];
            }
          }else{
          updatedSelectedAnswers[detailId] = [...selectedOptions?.slice(0, index), ...selectedOptions?.slice(index + 1)];

          }
        }
      }
      updatedSelectedAnswers[detailId] = updatedSelectedAnswers[detailId].filter(item => item !== 'N/A');
    }

    setSelectedAnswers(updatedSelectedAnswers);
  };
  const handleRadioChange = (detailId, value) => {
    setError(null);
    setSelectedAnswers(prevState => ({
      ...prevState,
      [detailId]: value
    }));
  };
  const disabledHandler = (id, option) => {
    if (id === 4) {
      if (option === 'N/A') {
        return false;
      }
      if (option === 'D1' && (selectedAnswers[3]?.includes('D2') || selectedAnswers[3]?.includes('D3') || selectedAnswers[3]?.includes('D1')) && !selectedAnswers[3]?.includes('N/A')) {
        return false;
      } else if (option === 'D2' && (selectedAnswers[3]?.includes('D2') || selectedAnswers[3]?.includes('D3')) && !selectedAnswers[3]?.includes('N/A')) {
        return false;
      } else if (option === 'D3' && selectedAnswers[3]?.includes('D3') && !selectedAnswers[3]?.includes('N/A')) {
        return false;
      } else {
        let index = selectedAnswers[4].findIndex(ele => ele==option)
        if(index > -1){
          selectedAnswers[4].splice(index,1)
          setSelectedAnswers(selectedAnswers)
        }
        return true;
      }
    }
    else if(id === 3){
     if(selectedAnswers[3].length == 0){
      return false
     }
       else if (option === 'N/A') {
        return false;
      }
        else if(option === 'D1'  && !selectedAnswers[3]?.includes('N/A') && !selectedAnswers[3]?.includes('D3') && !selectedAnswers[3]?.includes('D2')){
        return false
       }
               else if(option === 'D2' && !selectedAnswers[3]?.includes('D3')  && !selectedAnswers[3]?.includes('N/A')){
        return false
       }
               else if(option === 'D3'  && !selectedAnswers[3]?.includes('N/A')){
        return false
       }
      else if (option === 'D1' && (selectedAnswers[3]?.includes('D2') || selectedAnswers[3]?.includes('D3') || selectedAnswers[3]?.includes('D1')) && !selectedAnswers[3]?.includes('N/A')) {
        return true;
      } else if (option === 'D2' && (selectedAnswers[3]?.includes('D3')) && !selectedAnswers[3]?.includes('N/A')) {
        return true;
      } else if (option === 'D3' && !selectedAnswers[3]?.includes('N/A')) {
        return false;
      } 
       else if (option === 'Outside Discipleship') {
        return false;
      }
     
      else {
        return true;
      }
    } 
    else {
      return false;
    }
  };
  useEffect(() => {
    getExperience();
  }, []);

  return (
    <>
      <CssBaseline />
      <HeaderContainer pt={1} mt={2} mb={4}>
        <ArrowBackIcon style={{cursor: 'pointer', color: 'white'}} onClick={() => setPage(2)} color="white" />
      </HeaderContainer>
      <FormWrapper buttonInputs={[{text: translate("Continue"), onClick: handleSubmit}]} headingText={isEdit ? `${userData?.first_name && translate("Hey ") + userData?.first_name + ', '+ translate("Tell Us About Your Journey")}` : translate("Tell Us About Your Journey")} loading={loading}>
        <Typography variant="body1" style={{marginTop: '10px'}}>
        {translate("YOUR JOURNEY")} &bull; {translate("3 OF")} {user?.user_role === 'LEADER' ? '4' : '3'}
        </Typography>
        <Box mt={7}>
          <Grid style={{display: 'flex', flexWrap: 'wrap'}}>
            {leaderJourney.map((questions, index) => {
              if (questions.type === 'radio') {
                return (
                  <div key={index} style={{width: questions.width, marginBottom: '20px'}}>
                    <FormLabel
                      style={{
                        display: 'flex',
                        alignSelf: 'flex-start',
                        fontSize: '12px'
                      }}
                      id="questions-radio-buttons-group-label"
                    >
                      {questions.label}
                    </FormLabel>

                    {questions?.options.map((question, i) => {
                      return (
                        <Box key={i} sx={{display: 'flex', alignItems: 'center'}}>
                          <Radio checked={selectedAnswers[questions.id] === question.value} onChange={e => handleRadioChange(questions.id, e.target.value)} value={question.value} name="radio-buttons" inputProps={{'aria-label': question.label}} />
                          <FormLabel>{question.label}</FormLabel>
                        </Box>
                      );
                    })}
                  </div>
                );
              } else {
                return (
                  <div key={index} style={{width: questions.width, marginBottom: '20px'}}>
                    <FormLabel
                      style={{
                        display: 'flex',
                        alignSelf: 'flex-start',
                        fontSize: '12px'
                      }}
                      id="demo-radio-buttons-group-label"
                    >
                      {questions.label}
                    </FormLabel>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                      {questions.options.map((question, i) => (
                        <CheckBoxInput key={i} label={question.label} disabled={disabledHandler(questions.id, question.value)} checked={selectedAnswers[questions.id]?.includes(question.value)} onChange={() => handleCheckboxChange(questions.id, question.value)} width={questions.width} fullWidth={question.value === 'Outside Discipleship' ? 'full-width' : ''} />
                      ))}
                    </div>
                  </div>
                );
              }
            })}
          </Grid>
        </Box>
        <Grid item xs={12} sx={{height: '50px'}}>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
      </FormWrapper>
    </>
  );
};

export default LeaderJourney;
