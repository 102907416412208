import React, {useEffect, useState} from 'react';
import {CssBaseline, Grid, Typography, Box} from '@mui/material';
import FormWrapper from '../../common/StyledFormWrapper';
import CheckBoxInput from '../../common/CheckBoxInput';
import MultiLineTextInput from '../../common/MultiLineInput';
import {getAssessmentsQuestions, postAssessmentsQuestions} from '../../../api';
import {CONSTANTS} from '../../../constants';
import Alert from '@mui/material/Alert';
import RadioButtonGroup from '../../common/RadioButtonGroup';
import useTranslations from '../../../hooks/useTranslation';

const PageFourteen = ({nextpage, user}) => {
  const {translate} = useTranslations();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await getAssessmentsQuestions(CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_12, user?.id, user?.group);
      if (response?.data?.status === 200) {
        let allQues = [];
        response?.data?.data?.forEach(ques => {
          allQues.push({
            ...ques,
            answer: ques?.answer || ''
          });
        });
        setQuestions(allQues);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const submitAnswers = async () => {
    const postData = questions?.map(ques => {
      if (ques.id === 40) {
        return {
          question_id: ques?.id,
          answer: ques?.answer
        };
      } else {
        return {
          question_id: ques?.id,
          answer: ques?.answer
        };
      }
    });
    const data = {
      user_id: user?.id,
      group_id: user?.group,
      questions: postData
    };
    try {
      setLoading(true);
      const response = await postAssessmentsQuestions(data);
      if (response?.data?.status === 200) {
        nextpage();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCheckBox = (val, index) => {
    setError(null);
    const updatedItems = [...questions];
    updatedItems[index].answer = val;
    setQuestions(updatedItems);
  };

  const handleCommentChange = (val, index) => {
    setError(null);
    const updatedItems = [...questions];
    updatedItems[index].answer = val;
    setQuestions(updatedItems);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isAllQuestionsAnswered = questions?.every(item => {
      return item.answer.trim() !== '';
    });
    if (isAllQuestionsAnswered) {
      submitAnswers();
    } else {
      setError(translate("All fields are required!"));
    }
  };

  const handleRadio = (value, id) => {
    let questionsArray = [...questions];
    let questionData = questionsArray.find(item => item.id === id);

    if (questionData) {
      questionData = {...questionData, answer: value};
      questionsArray = questionsArray.map(item => (item.id === id ? questionData : item));
    }
    setQuestions(questionsArray);
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  return (
    <>
      <CssBaseline />

      <Box mt={1} px={3} overflow="auto">
        <Typography variant="h3" align="center">
        {translate("Wohoo!")}
        </Typography>
        <Typography variant="h3" align="center" mb={3}>
        {translate("They Finished D3.")}
        </Typography>
        <Typography variant="h6" align="left" mt={4}>
        {translate("WHAT'S THEIR PLAN FOR NEXT YEAR")}
        </Typography>

        <FormWrapper headingText="" style={{width: '100%', marginTop: '20px'}} buttonInputs={[{text: translate("Submit"), onClick: e => handleSubmit(e), type: 'submit'}]} loading={loading}>
          {questions?.map((ques, index) => {
            if (ques?.id == 40) {
              return (
                <Grid key={index}>
                  <CheckBoxInput onChange={() => handleCheckBox('Ready to make disciples', index)} checked={ques?.answer === 'Ready to make disciples'} label={translate("Ready to make disciples")} />
                  <CheckBoxInput onChange={() => handleCheckBox('Taking a Break', index)} checked={ques?.answer === 'Taking a Break'} label={translate("Taking a break")} />
                  <CheckBoxInput onChange={() => handleCheckBox('Not going to practice', index)} checked={ques?.answer === 'Not going to practice'} label={translate("Not going to practice")} />
                </Grid>
              );
            }
            if (ques?.id == 41) {
              return (
                <Grid key={index} item xs={12} mt={2}>
                  <MultiLineTextInput placeholder={translate("Comments")} value={ques?.answer} onChange={e => handleCommentChange(e.target.value, index)} autoComplete="off" margin="normal" multiline rows={4} />
                </Grid>
              );
            }
            if (ques?.id == 57) {
              return (
                <Grid key={index} mt={1}>
                  <Typography variant="h6" textAlign="left">
                    {ques?.question}
                  </Typography>
                  <RadioButtonGroup
                    options={[
                      {label: translate("Yes"), value: "Yes"},
                      {label: translate("No"), value: "No"}
                    ]}
                    selectedOption={ques?.answer}
                    onChange={value => handleRadio(value, ques?.id)}
                  />
                </Grid>
              );
            }
          })}
          <Grid item xs={12} sx={{height: '50px'}}>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default PageFourteen;
