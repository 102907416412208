import React, {useEffect, useState} from 'react';
import AccordionForm from '../common/AccordianInvite';
import {Typography, Box} from '@mui/material';
import CustomButton from '../common/Button';

const InviteUser = props => {
  const {formDataList, setFormDataList, setData, data, continueHandler, loading, setError, error, heading, onSubmit, buttonTextOne, buttonTextTwo} = props;
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const handleSubmit = () => {
    continueHandler();
  };

  useEffect(() => {
    return () => {
      setError(null);
    };
  }, []);
  return (
    <Box>
      <Typography variant="h1" fontSize={30} mx={2} mb={4} color="white" textAlign="center">
        {heading}
      </Typography>
      <Box mt={1}>
        <AccordionForm formDataList={formDataList} setFormDataList={setFormDataList} setData={setData} data={data} setEmailError={setEmailError} setNameError={setNameError} setPhoneError={setPhoneError} emailError={emailError} nameError={nameError} phoneError={phoneError} setError={setError} error={error} />
        <CustomButton disabled={loading} type="button" text={buttonTextOne} onClick={() => onSubmit()} />
        <CustomButton disabled={loading} type="button" text={buttonTextTwo} onClick={() => handleSubmit()} />
      </Box>
    </Box>
  );
};

export default InviteUser;
