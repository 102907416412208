import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import React from 'react';
import useTranslations from '../../../hooks/useTranslation';

const DeleteReadingPlanComponent = ({ bookId, onDelete }) => {
	const { translate } = useTranslations();

	return (
		<>
			<Typography sx={{ mb: 2, color: '#000000' }}>{translate("Are you certain that you want to delete this books? All information will be removed from the system and the reading plan will be removed. This action cannot be undone!")}</Typography>
			<FormControl component="fieldset" sx={{ '& .MuiFormControlLabel-label': { color: '#000000' } }}>
				<RadioGroup
					value={''}
					onChange={e => { }}
					sx={{
						color: '#000000 !important',
						'& .MuiFormControlLabel-label': {
							color: '#000000 !important'
						}
					}}
				>
					<FormControlLabel
						value="deleteGroupAndDisciples"
						control={<Radio sx={{
							color: '#000000 !important',
							'&.Mui-checked': {
								color: '#000000 !important'
							}
						}} />}
						label={translate("I am sure that I want to delete this reading plan.")}
					/>
				</RadioGroup>
			</FormControl>
		</>
	);
};

export default DeleteReadingPlanComponent;
