import React, {useState} from 'react';
import {Box, Avatar, Typography, IconButton, useMediaQuery, Select, InputLabel, MenuItem, FormControl} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TextInput from '../../../components/common/TextInput';
import {BASE_URL, CONSTANTS, FILE_PATH_PROFILE_URL} from '../../../constants';
import {capitalizeWords, isValidFile, validatePhone} from '../../../utils';
import toast from 'react-hot-toast';
import useTranslations from '../../../hooks/useTranslation';
import useAuthStore from '../../../store/authStore';
import {updatethemeOrganization} from '../../../api';
import useAdminStore from '../../../store/adminStore';
import FormWrapper from '../../../components/common/StyledFormWrapper';

function EditProfile() {
  const {translate} = useTranslations();
  const {user, token, updateUserData} = useAuthStore(state => state);
  const {organizationSelected, setOrganizationSelected} = useAdminStore(state => state);
  const [editUserData, setEditUserData] = useState({
    id: user?.id,
    name: user?.first_name + ' ' + user?.last_name,
    avatar: user?.avatar,
    mobile_number: user?.mobile_number
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [fileExtensionName, setFileExtensionName] = useState([]);
  const userId = user?.id;
  const [theme, setTheme] = useState(JSON.parse(localStorage.getItem('theme_id')) || '');
  const themes = [
    {value: 1, theme_id: 1, theme_name: 'Red'},
    {value: 2, theme_id: 2, theme_name: 'Green'},
    {value: 3, theme_id: 3, theme_name: 'Orange'}
  ];
  const handleThemeChange = event => {
    setTheme(event.target.value);
  };
  const handleApplyThemeChange = async () => {
    try {
      const istheme = true;
      const res = await updatethemeOrganization(organizationSelected, theme, istheme);
      localStorage.setItem('theme', theme);
      // Reload the window to apply the theme change
      window.location.reload();
      toast.success(translate('Theme updated successfully!'));
    } catch (error) {
      toast.error(translate('Failed to update theme!'), {
        id: 'error_message'
      });
      console.error(error);
    }
  };

  const handleInputChange = e => {
    if (e.target.value.length <= 64) {
      setEditUserData(prevData => ({
        ...prevData,
        name: capitalizeWords(e.target.value)
      }));
    }
  };

  const handlePhoneInputChange = e => {
    const phoneNumber = e.target.value;
    setEditUserData(prevData => ({
      ...prevData,
      mobile_number: phoneNumber
    }));
  };

  const imageChangeHandler = e => {
    let file = e.target.files[0];
    if (file.size >= CONSTANTS.MAX_IMAGE_FILE_SIZE) {
      toast.error(translate('File is too large (not more than 10 mb)'));
      return;
    }
    if (isValidFile('image', file)) {
      setEditUserData(prevData => ({
        ...prevData,
        avatar: e.target.files[0]
      }));
      let fileExtension = e.target.files[0].name.split('.');
      fileExtension = fileExtension[fileExtension.length - 1];
      setFileExtensionName(fileExtension);
    } else {
      toast.error(translate('Only image file allowed!'), {
        id: 'toast_message'
      });
    }
  };

  const handleUpdate = async () => {
    if (editUserData?.name?.trim().length === 0) {
      toast.error(translate('All fields are required!'), {
        id: 'toast_message'
      });
      return;
    }
    if (editUserData?.mobile_number && !validatePhone(editUserData?.mobile_number)) {
      toast.error(translate('Please enter valid phone number!'), {
        id: 'toast_message'
      });
      return;
    }
    try {
      setButtonLoading(true);
      const formData = new FormData();
      formData.append('first_name', editUserData?.name?.trim().split(' ')[0] || '');
      formData.append('last_name', editUserData?.name?.trim().split(' ')[1] || '');
      formData.append('mobile_number', editUserData?.mobile_number || '');
      if (editUserData?.avatar && typeof editUserData?.avatar !== 'string') {
        formData.append('profile_avatar', editUserData?.avatar);
      }

      const requestOptions = {
        method: 'PATCH',
        headers: {
          Authorization: token
        },
        body: formData
      };

      const response = await fetch(`${BASE_URL}/users/${userId}`, requestOptions);
      const data = await response.json();
      if (data?.status === 200) {
        let fileName = '';
        if (editUserData?.avatar && typeof editUserData.avatar !== 'string') {
          updateUserData({avatar: data?.data?.avatar ? data?.data?.avatar : ''});
          fileName = data?.data?.avatar;
        } else {
          fileName = data?.data?.avatar;
        }
        fileName = fileName == {} ? '' : fileName;

        updateUserData({
          first_name: editUserData?.name.trim().split(' ')[0] || '',
          last_name: editUserData?.name.trim().split(' ')[1] || '',
          mobile_number: editUserData?.mobile_number ? editUserData?.mobile_number.trim() : '',
          avatar: data?.data?.avatar
        });
        toast.success(translate(data.message), {
          id: 'toast_message'
        });
      } else {
        toast.error(translate('Something went wrong!'), {
          id: 'error_message'
        });
      }
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
    }
  };

  return (
    <Box
      sx={{
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        maxWidth: '400px',
        backgroundColor: '#ffffff'
      }}
    >
      <FormWrapper buttonInputs={[{text: translate('Update'), onClick: handleUpdate}]} headingText={translate('Edit Profile')} headingTextColor="black" loading={buttonLoading}>
        <IconButton>
          <label style={{display: 'flex', cursor: 'pointer'}} htmlFor="contained-button-file">
            <div style={{position: 'relative'}}>
              <Avatar
                alt={editUserData?.name}
                src={editUserData?.avatar ? (typeof editUserData?.avatar === 'object' ? URL.createObjectURL(editUserData?.avatar) : `${FILE_PATH_PROFILE_URL}/${editUserData?.avatar}`) : ''}
                style={{
                  margin: '10px',
                  width: '80px',
                  height: '80px'
                }}
              />
              <EditIcon
                style={{
                  color: 'black',
                  position: 'absolute',
                  bottom: 22,
                  right: 20,
                  transform: 'translate(50%, 50%)',
                  zIndex: 1,
                  backgroundColor: 'whitesmoke',
                  borderRadius: '50%',
                  padding: '2px',
                  border: '.5px solid black'
                }}
              />
            </div>
            <input onChange={imageChangeHandler} style={{display: 'none'}} accept="image/*" id="contained-button-file" type="file" />
          </label>
        </IconButton>
        <Typography py={2} fontSize={'16px'} color="black">
          {user?.email}
        </Typography>
        <Box px={1}>
        <TextInput
          value={editUserData?.name}
          label={translate('Name')}
          labelColor="black"
          onChange={handleInputChange}
          autoComplete="off"
          labelVariant="body2"
          colorBlack={'text-black'}
          sx={{
            mb: 2,
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid #ccc',
            borderRadius: '8px',
            '& .MuiInputBase-input': {
              color: 'black !important'
            },
            '& .MuiInputBase-input:focus': {
              color: 'black !important'
            }
          }}
        />
        <TextInput
          value={editUserData?.mobile_number}
          labelColor="black"
          label={translate('Phone Number')}
          onChange={handlePhoneInputChange}
          maxLength={CONSTANTS.MOBILE_NUMBER_MAX_LENGTH}
          autoComplete="off"
          labelVariant="body2"
          colorBlack={'text-black'}
          placeholder={translate('Phone Number')}
          sx={{
            mb: 2,
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid #ccc',
            borderRadius: '8px',
            '& .MuiInputBase-input': {
              color: 'black !important'
            },
            '& .MuiInputBase-input:focus': {
              color: 'black !important'
            }
          }}
        />
        </Box>
        {/* Commentt down the code for theme change  */}
        {/* <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Theme</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={theme}
    label="Theme"
    onChange={handleThemeChange}
  >
    {themes.map((theme) => (
  <MenuItem key={theme.theme_id} value={theme.value}>
    {theme.theme_name}
  </MenuItem>
))}
  </Select>
</FormControl>
<button type="button"
            variant="contained"
            color="primary"
            onClick={() => handleApplyThemeChange()}
          >
            Apply Theme
          </button> */}
      </FormWrapper>
    </Box>
  );
}

export default EditProfile;
