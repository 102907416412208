import {useState} from 'react';
import {Box} from '@mui/material';
import {GetStartedFive, GetStartedFour, GetStartedOne, GetStartedThree, GetStartedTwo, GetStartedZero} from '../components/modules/GetStarted';

const GetStarted = () => {
  const [page, setPage] = useState(1);

  return (
    <Box>
      {page === 1 && <GetStartedZero setPage={setPage} />}
      {page === 2 && <GetStartedOne setPage={setPage} />}
      {page === 3 && <GetStartedTwo setPage={setPage} />}
      {page === 4 && <GetStartedThree setPage={setPage} />}
      {page === 5 && <GetStartedFour setPage={setPage} />}
      {page === 6 && <GetStartedFive setPage={setPage} />}
    </Box>
  );
};

export default GetStarted;
