import React from 'react';
import theme from '../../styles/theme';
import {CONSTANTS} from '../../constants';
import {isSpecialCharacterIncludes} from '../../utils';
import {Box, TextField, Typography} from '@mui/material';

const MultiLineTextInput = ({label, error, helperText, labelVariant = 'body2', onChange, isAllowSpecialCharacter, ...rest}) => {
  return (
    <Box display="flex" flexDirection="column" padding="0 !important">
      {label && (
        <Typography textAlign="left" variant={labelVariant}>
          {label.toUpperCase()}
        </Typography>
      )}
      <TextField
        error={error}
        helperText={helperText}
        autoComplete="off"
        inputProps={{
          maxLength: CONSTANTS.TEXT_AREA_MAX_LENGTH
        }}
        onChange={e => (isAllowSpecialCharacter ? onChange(e) : !isSpecialCharacterIncludes(e.target.value) && onChange(e))}
        InputProps={{
          theme: theme
        }}
        {...rest}
      />
    </Box>
  );
};

export default MultiLineTextInput;
