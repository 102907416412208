import React, {useState} from 'react';
import Address from '../components/modules/Detail/Address';
import DiscipleJourney from '../components/modules/Detail/DiscipleJourney';
import {Box} from '@mui/material';
import Journey from '../components/modules/Detail/Journey';
import LeaderJourney from '../components/modules/Detail/LeaderJourney';
import {useSearchParams} from 'react-router-dom';
import useAuthStore from '../store/authStore';

const Auth = () => {
  const [page, setPage] = useState(1);
  const {user} = useAuthStore(state => state);
  const userRole = user?.user_role;
  const [searchParams, setSearchParams] = useSearchParams();
  const isEdit = searchParams.get('isEdit') && searchParams.get('isEdit') === 'true' ? true : false;
  return (
    <Box mt={1} mx={3}>
      {page === 1 && <Address setPage={setPage} user={user} />}
      {page === 2 && <Journey setPage={setPage} user={user} />}
      {page === 3 && (userRole === 'LEADER' || userRole === 'ADMIN') && <LeaderJourney isEdit={isEdit} setPage={setPage} user={user} />}
      {page === ( userRole === 'LEADER'? 4 : 3 )&& (userRole === 'LEADER' || userRole === 'DISCIPLE') && <DiscipleJourney isEdit={isEdit} setPage={setPage} user={user} />}
    </Box>
  );
};

export default Auth;
