import {useTheme} from '@mui/material';
import Button from '@mui/material/Button';
import {capitalizeWords} from '../../utils';
import CircularProgress from '@mui/material/CircularProgress';

const CustomButton = ({onClick = () => {}, visible = true, text, variant = 'contained', color = 'secondary', disabled = false, type, disablePadding, loading = false}) => {
  const theme = useTheme();
  return (
    <Button sx={{fontWeight: 400, visibility: visible ? 'visible' : 'hidden'}} type={type === 'submit' ? 'submit' : 'button'} style={{marginBlock: disablePadding ? theme.spacing(0) : theme.spacing(2)}} variant={variant} color={color} onClick={onClick} disabled={disabled || loading} startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}>
      {capitalizeWords(text)}
    </Button>
  );
};

export default CustomButton;
