import {Alert, Box, CssBaseline, Grid} from '@mui/material';
import React from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import TextInput from '../../common/TextInput';
import MultiLineTextInput from '../../common/MultiLineInput';
import HeaderContainer from '../../common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useTranslations from '../../../hooks/useTranslation';

const GroupSendEmail = ({setEmailRes, emailRes, handleSubmit, loading, setError, error, setIsDiscipleSelected}) => {
  const {translate} = useTranslations();
  const handleChangeText = e => {
    setError(null);
    setEmailRes(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      };
    });
  };

  const handleFileChange = e => {
    setError(null);
    setEmailRes(prev => {
      return {
        ...prev,
        recfile: e.target.files[0] || ''
      };
    });
  };
  return (
    <>
      <HeaderContainer>
        <ArrowBackIcon
          style={{cursor: 'pointer', color: 'white'}}
          onClick={() => {
            setEmailRes({
              subject: '',
              message: '',
              recfile: ''
            });
            setIsDiscipleSelected(false);
          }}
          color="white"
        />
      </HeaderContainer>
      <Box>
        <CssBaseline />
        <FormWrapper buttonInputs={[{text: loading ? translate("Sending") : translate("Send Email"), onClick: handleSubmit}]} headingText="" loading={loading}>
          <Box my={0} mb={2} sx={{textAlign: 'left'}}>
            <Box mt={2}>
              <TextInput name="subject" placeholder={translate("Enter Subject")} value={emailRes.subject} onChange={handleChangeText} label={translate("Subject")} labelVariant="body2" />

              <MultiLineTextInput label={translate("Body")} name="message" placeholder={translate("Write your message")} value={emailRes.message} onChange={handleChangeText} autoComplete="off" margin="normal" multiline rows={6} width="100%" />
              <input onChange={handleFileChange} type="file" accept="application/pdf" />
            </Box>
          </Box>
          <Grid item sx={{height: '50px'}}>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default GroupSendEmail;
