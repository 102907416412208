import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import React from 'react';
import useTranslations from '../../../hooks/useTranslation';

const DeleteAvailableBookComponent = ({ bookId, onDelete }) => {
	const { translate } = useTranslations();

	return (
		<>
			<Typography sx={{ mb: 2, color: '#000000' }}>{translate("Are you certain that you want to delete this books? All information will be removed from the system and the book will be removed from all reading plans. This action cannot be undone!")}</Typography>
			<FormControl component="fieldset" sx={{ '& .MuiFormControlLabel-label': { color: '#000000' } }}>
				<RadioGroup
					value={''}
					onChange={e => { }}
					sx={{
						color: '#000000 !important',
						'& .MuiFormControlLabel-label': {
							color: '#000000 !important'
						}
					}}
				>
					<FormControlLabel
						value="deleteGroupAndDisciples"
						control={<Radio sx={{
							color: '#000000 !important',
							'&.Mui-checked': {
								color: '#000000 !important'
							}
						}} />}
						label={translate("I am sure that I want to delete this book.")}
					/>
				</RadioGroup>
			</FormControl>
		</>
	);
};

export default DeleteAvailableBookComponent;
