import React from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, useTheme} from '@mui/material';
import CustomButton from './Button';

const Modal = ({children, open, setOpen, title, buttons, modalbgColor , modalTextColor, modalTitleColor , height, titleStyles, contentStyles}) => {
  const theme = useTheme();
  const bgColor = modalbgColor ? modalbgColor : theme.palette.modal.bgcolor;
  const TextColor = modalTextColor? modalTextColor : theme.palette.modal.textcolor;
  return (
    <Dialog
      open={open}
      onClose={setOpen}
      PaperProps={{
        sx: {
          backgroundColor: bgColor,
          color: TextColor,
          padding: 1,
          overflow: 'hidden',
          width: '100vw',
          ...(height && {height: height})
        }
      }}
    >
      <DialogTitle sx={{backgroundColor: bgColor, color: modalTitleColor, padding: '10px', fontWeight: 'bold', ...titleStyles}}>{title}</DialogTitle>

      <DialogContent sx={{backgroundColor: bgColor, color: TextColor, padding: '10px', ...contentStyles}}>{children}</DialogContent>

      <DialogActions>
        {buttons?.map((button, index) => {
          return <CustomButton type="outlined" key={index} onClick={button.onClick} text={button?.name} loading={button.loading} disabled={button.disabled} />;
        })}
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
