import {Box, Typography} from '@mui/material';
import React from 'react';

const KeyAndValues = ({name, answer, comment}) => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{name}: </Typography>
        <Typography variant="h6">{answer} </Typography>
      </Box>
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          fontSize: '11px',
          textAlign: 'start',
          marginBottom: '5px',
          fontStyle: 'oblique'
        }}
      >
        {comment}{' '}
      </Typography>
    </Box>
  );
};

export default KeyAndValues;
