import React, {useState} from 'react';
import {Typography, IconButton, Box, Button} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import useTranslations from '../../../hooks/useTranslation';

const LogoCard = () => {
  const {translate} = useTranslations();
  const [isEditing, setIsEditing] = useState(false);
  const [logo, setLogo] = useState('path_to_logo');
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleSubmitClick = () => {
    // Add logic to save changes here
    setIsEditing(false);
  };

  const handleLogoChange = e => {
    const file = e.target.files[0];
    if (file) {
      // Convert to URL for preview, in real case, you'd probably want to upload it
      setLogo(URL.createObjectURL(file));
    }
  };

  return (
    <Box
      sx={{
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        maxWidth: '400px',
        height: 'fit-content',
        backgroundColor: '#ffffff'
      }}
    >
      <Typography variant="h6" sx={{display: 'flex', justifyContent: 'space-between'}}>
        {translate('Logo')}
        {!isEditing && (
          <IconButton onClick={handleEditClick} size="small" aria-label="edit">
            <EditIcon sx={{color: '#000'}} />
          </IconButton>
        )}
      </Typography>
      {isEditing ? (
        <>
          <input accept="image/*" style={{display: 'none'}} id="upload-logo" type="file" onChange={handleLogoChange} />
          <label htmlFor="upload-logo">
            <Button variant="contained" color="primary" component="span">
              Upload Logo
            </Button>
          </label>
          <Button color="secondary" onClick={() => setIsEditing(false)} sx={{mt: 1, ml: 1}}>
            Cancel
          </Button>
          {logo && <img src={logo} alt="Logo" style={{width: '80px', height: '80px', marginTop: '10px'}} />}
        </>
      ) : (
        <Box sx={{mt: 2}}>
          <img src={logo} alt="Organization Logo" style={{width: '80px', height: '80px'}} />
        </Box>
      )}
    </Box>
  );
};

export default LogoCard;
