import {Divider} from '@mui/material';
import React from 'react';

const Divide = () => {
  return (
    <Divider
      sx={{
        backgroundColor: 'white',
        marginY: '10px',
        variant: 'middle'
      }}
    />
  );
};

export default Divide;
