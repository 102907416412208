import React, { useState } from 'react';
import { Box } from '@mui/material';


function TabSwitcher({ children, tab1Text, tab2Text, selectedTab, setSelectedTab }) {
	return (
		<Box>
			<Box display={'flex'} alignItems={'center'} gap={2} mb={2}>
				<Box
					onClick={() => setSelectedTab(0)}
					color={selectedTab === 0 ? 'white' : '#000000'}
					bgcolor={selectedTab === 0 ? '#D0373B' : '#FFFFFF'}
					p={1}
					borderRadius={12}
					px={5}
					sx={{ cursor: 'pointer', fontWeight: 'bold' }}
				>
					{tab1Text}
				</Box>
				<Box
					onClick={() => setSelectedTab(1)}
					color={selectedTab === 1 ? 'white' : '#000000'}
					bgcolor={selectedTab === 1 ? '#D0373B' : '#FFFFFF'}
					p={1}
					borderRadius={12}
					px={5}
					sx={{ cursor: 'pointer', fontWeight: 'bold' }}
				>
					{tab2Text}
				</Box>
			</Box>
			<Box mb={2}>{children}</Box>
		</Box>
	);
}

export default TabSwitcher;
