import React, { useState } from 'react';
import { Box, Typography, Avatar, TextField, Select, MenuItem, Menu, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useAuthStore from '../../../store/authStore';
import { CONSTANTS, FILE_PATH_PROFILE_URL } from '../../../constants';
import { getKeyByValue } from '../../../utils';
import useAdminStore from '../../../store/adminStore';
import { addUserRoleApi } from '../../../api';
import useTranslations from '../../../hooks/useTranslation';
import IconContainer from '../../../components/common/IconContainer';
import { searchIcon } from '../../../utils/svg';

const DashboardHeader = ({ heading, handleAddClick, isSearch, searchHandler, isAddingUser, isAddingGroup, isAddingDisciple }) => {
  const { translate } = useTranslations();
  const { user, loginNewUser } = useAuthStore(state => state);
  const { organizationSelected, setOrganizationSelected } = useAdminStore(state => state);
  const [anchorEl, setAnchorEl] = useState(null);
  const rolesArray = user?.user_permissions ? user?.user_permissions?.split(',') : [];
  const userRole = rolesArray?.map(role => {
    const roles = getKeyByValue(CONSTANTS.USER_ROLES, role);
    return {
      label: roles,
      value: roles
    };
  });
  const organizations = user?.organizations;

  const handleOrganizationChange = event => {
    const orgId = event.target.value;
    setOrganizationSelected(orgId);
    const org_to_theme = JSON.parse(localStorage.getItem('org_to_theme')) || [];
    const selectedOrg = org_to_theme.find(org => org.org_id == orgId);
    const newThemeId = selectedOrg?.theme_id;

    if (newThemeId) {
      localStorage.setItem("theme", newThemeId);
      window.location.reload();
    }
  };

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const permissionChangeHandler = async permission => {
    try {
      const roleData = {
        permission: permission,
        is_role_change: true,
        orgId: organizationSelected
      };
      const res = await addUserRoleApi(roleData);
      if (res?.data?.status === 200) {
        loginNewUser({ ...res?.data?.data, user_role: permission });
      }
    } catch (error) { }
  };

  return (
    <>
      <Box
        flexGrow={1}
        justifyContent="space-between"
        alignItems="center"
        p={2}
        bgcolor="#f9f9f9"
        sx={{
          display: {
            xs: 'flex',
            sm: 'flex',
            md: 'flex',
            lg: 'flex',
            xl: 'flex'
          },
          marginTop: {
            xs: '30px',
            sm: '30px',
            md: 0,
            lg: 0
          }
        }}
      >
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography
            variant="h4"
            sx={{
              color: '#000 !important',
              fontWeight: 600,
              fontSize: {
                xs: '17px',
                sm: '17px',
                md: '1.5rem'
              }
            }}
          >
            {heading}
          </Typography>
          <Box ml={4}>
            {isAddingUser && (
              <Box
                sx={{
                  display: { md: 'flex', xs: 'none', sm: 'none' }
                }}
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton
                  onClick={handleAddClick}
                  sx={{
                    '&:hover': {
                      borderRadius: '12px'
                    }
                  }}
                >
                  <AddIcon
                    sx={{
                      color: '#D0373B'
                      // padding: 1,
                    }}
                  />{' '}
                  <Typography variant="h6" color="black" ml={1}>
                    {translate("Add Leaders")}
                  </Typography>
                </IconButton>
              </Box>
            )}
            {isAddingGroup && (
              <Box
                sx={{
                  display: { md: 'flex', xs: 'none', sm: 'none' }
                }}
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton
                  onClick={handleAddClick}
                  sx={{
                    '&:hover': {
                      borderRadius: '12px'
                    }
                  }}
                >
                  <AddIcon
                    sx={{
                      color: '#D0373B'
                      // padding: 1,
                    }}
                  />{' '}
                  <Typography variant="h6" color="black" ml={1}>
                    {translate("Add Groups")}
                  </Typography>
                </IconButton>
              </Box>
            )}
            {isAddingDisciple && (
              <Box
                sx={{
                  display: { md: 'flex', xs: 'none', sm: 'none' }
                }}
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton
                  onClick={handleAddClick}
                  sx={{
                    '&:hover': {
                      borderRadius: '12px'
                    }
                  }}
                >
                  <AddIcon
                    sx={{
                      color: '#D0373B'
                      // padding: 1,
                    }}
                  />{' '}
                  <Typography variant="h6" color="black" ml={1}>
                    {translate("Add Disciple")}
                  </Typography>
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          {window.location.pathname !== '/admin-dashboard/settings' && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent={'space-between'}
              px={2}
              height={'50px'}
            >
              <Select
                value={organizationSelected}
                onChange={handleOrganizationChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                IconComponent={ExpandMoreIcon}
                sx={{
                  marginRight: 4,
                  color: '#D0373B',
                  outline: 'none',
                  borderRadius: '30px',
                  display: 'flex',
                  height: '100%',
                  alignItems: 'center',
                  width: '200px',
                  bgcolor: 'white !important',
                  '& .MuiSelect-select': {
                    padding: '8px 8px',
                    color: '#D0373B',
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#D0373B',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              >
                {organizations?.map(org => (
                  <MenuItem
                    key={org.id}
                    value={org.id}
                    sx={{
                      color: '#D0373B',
                      textAlign: 'center',
                      backgroundColor: 'white !important',
                      '&:hover': {
                        backgroundColor: '#f9f9f9'
                      }
                    }}
                  >
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
              {isSearch && (
                <Box
                  sx={{
                    bgcolor: 'white',
                    borderRadius: '30px',
                    padding: '0px 20px',
                    display: {
                      md: 'flex',
                      sm: 'none',
                      xs: 'none'
                    },
                    alignItems: 'center',
                    width: '40%',
                    height: '50px'
                  }}
                >
                  <IconContainer color={'#D0373B'} icon={searchIcon} width={20} height={20} marginRight={8} />
                  <TextField
                    variant="standard"
                    placeholder={translate("Search Here")}
                    InputProps={{
                      disableUnderline: true
                    }}
                    onChange={e => searchHandler(e.target.value)}
                    size="small"
                    sx={{
                      height: '100%',
                      width: '100%',
                      color: 'black !important',
                      '& .MuiInputBase-input': {
                        color: 'black !important'
                      },
                      '& .MuiInputBase-input:focus': {
                        color: 'black !important'
                      },
                      '& .MuiInputBase-input::placeholder': {
                        color: 'black !important',
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
          <Box display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Typography variant="body1" mr={2} color="#000" fontWeight={500}>
                {user?.first_name} {user?.last_name}
              </Typography>
              <Typography variant="subtitle2" mr={2} color="#000">
                {translate("Administrator")}
              </Typography>
            </Box>
            <Avatar src={`${FILE_PATH_PROFILE_URL}/${user?.avatar}`} onClick={handleMenuOpen} sx={{ cursor: 'pointer' }} />
          </Box>
          {userRole?.length > 1 && (
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              sx={{
                '& .MuiMenuItem-root': {
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#a13a3d'
                  }
                },
                '& .MuiMenu-list': {
                  marginBlock: '7px'
                  // marginTop: "20px",
                }
              }}
            >
              {userRole?.map(permission => (
                <MenuItem
                  style={{
                    background: user.user_role === permission.value ? '#ca0000' : 'white',
                    color: user.user_role === permission.value ? 'white' : 'black'
                  }}
                  key={permission.value}
                  onClick={() => permissionChangeHandler(permission.value)}
                >
                  {translate(permission.label)}
                </MenuItem>
              ))}
            </Menu>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DashboardHeader;
