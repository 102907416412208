import React, {useEffect, useState} from 'react';
import {Box, Typography, Button} from '@mui/material';
import {styled} from '@mui/material/styles';
import { getAdminLeadersGroupApi, updateGroupApi} from '../../../api';
import useTranslations from '../../../hooks/useTranslation';
import useAdminStore from '../../../store/adminStore';

const StyledSelect = styled('select')({
  width: '100%',
  padding: '10px',
  margin: '10px 0 20px',
  borderRadius: '4px',
  border: '1px solid #ccc',
  fontSize: '16px',
  outline: 'none',
  '&:focus': {
    borderColor: '#d32f2f'
  }
});

const PageFive = ({groupData, handleClose}) => {
  const {translate} = useTranslations();
  const [newLeaderId, setNewLeaderId] = useState('');
  const [editLeader, setEditLeader] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(groupData);
  const {organizationSelected} = useAdminStore(state => state);
  const [groupLeader, setGroupLeader] = useState(null);
  const [leaders, setLeaders] = useState([]);

  const getGroupLeader = async id => {
    try {
      const resp = await getAdminLeadersGroupApi(id, 'leader');
      if (resp?.data?.status === 200) {
        setGroupLeader(resp?.data?.data);
        setNewLeaderId(resp?.data?.data?.id || '');
      }
    } catch (error) {}
  };

  const getLeaders = async () => {
    try {
      const res = await getAdminLeadersGroupApi(organizationSelected);
      if (res?.data?.status === 200) {
        setLeaders(res?.data?.data);
      }
    } catch (error) {}
  };

  const handleReplaceLeader = async () => {
    let toReplaceLeader = leaders?.find(leader => leader.name.trim() === newLeaderId);

    try {
      const resp = await updateGroupApi(selectedGroup?.id, {
        replace_leader: toReplaceLeader?.id
      });
      if (resp?.data?.status === 200) {
        setSelectedGroup(resp.data.data);
        handleClose();
      }
    } catch (error) {
      // handleClose();
    }
  };

  const handleAssignLeader = () => {
    handleReplaceLeader();
    setEditLeader(false);
  };

  useEffect(() => {
    if (organizationSelected) {
      getLeaders();
    }
  }, [organizationSelected]);

  useEffect(() => {
    if (groupData?.id) {
      getGroupLeader(groupData.id);
    }
  }, [groupData]);

  return (
    <Box mb={1} ml={1}>
      <Typography variant="h5" mb={1} textAlign={'left'}>
        {translate('Please assign the leader for the group you created!')}
      </Typography>

      <StyledSelect
        placeholder={translate('Select Leader')}
        value={newLeaderId}
        onChange={e => {
          const selectedUserId = e.target.value;
          setNewLeaderId(selectedUserId);
          setEditLeader(!!selectedUserId && selectedUserId !== (groupLeader ? groupLeader.id : ''));
        }}
      >
        <option value="">
          <em>{translate('Select Leader')}</em>
        </option>
        {leaders &&
          leaders
            .filter(leader => leader.status === 'ACTIVE')
            .map(leader => {
              const isSelected = leader.user_id === (groupLeader ? groupLeader.id : '');

              return (
                <option
                  key={leader.user_id}
                  value={leader.user_id}
                  style={{
                    backgroundColor: isSelected ? '#f0f0f0' : '',
                    fontWeight: isSelected ? 'bold' : 'normal'
                  }}
                >
                  {leader.name}
                </option>
              );
            })}
      </StyledSelect>

      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" color="secondary" onClick={handleAssignLeader} disabled={!editLeader}>
          {translate('Assign')}
        </Button>
      </Box>
    </Box>
  );
};

export default PageFive;
