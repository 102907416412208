import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import useTranslations from '../../../hooks/useTranslation';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import QueueIcon from '@mui/icons-material/Queue';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import DeleteReadingPlanComponent from './DeleteReadingPlanComponent';
import EditDuplicateReadingPlanComponent from './EditDuplicateReadingPlanComponent';
import AddReadingPlanComponent from './AddReadingPlanComponent';
import Modal from '../../../components/common/Modal';

const ReadingPlansTabComponent = () => {
	const { translate } = useTranslations();
	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState('');

	const masterPlans = [
		{ name: 'SD Standard Plan (D2)', level: 'D2' },
		{ name: 'SD Standard Plan 47', level: 'D3' },
		{ name: 'Hipster Plan', level: 'D2' },
		{ name: 'Conservative Plan', level: 'D2' },
		{ name: 'Northview Reading', level: 'D1' },
	];

	const [planDetails, setPlanDetails] = useState({
		name: 'SD Standard Plan',
		level: 'D3',
		books: [
			{ title: 'Mystical Chapters', startWeek: 43, endWeek: 47 },
			{ title: 'Quick Smart Start', startWeek: 16, endWeek: 19 },
			{ title: 'Search for Significance', startWeek: 20, endWeek: 25 },
			{ title: 'In His Steps', startWeek: 31, endWeek: 33 },
		]
	});

	const [availableBooks] = useState([
		'Mystical Chapters',
		'Quick Smart Start',
		'Search for Significance',
		'How to Pray - Greig',
		// ... add more books
	]);

	const [weeks] = useState(Array.from({ length: 52 }, (_, i) => i + 1));

	const handleAddBook = (index) => {
		const newBooks = [...planDetails.books];
		newBooks.splice(index + 1, 0, { title: '', startWeek: 1, endWeek: 1 });
		setPlanDetails({ ...planDetails, books: newBooks });
	};

	const handleDeleteBook = (index) => {
		const newBooks = planDetails.books.filter((_, i) => i !== index);
		setPlanDetails({ ...planDetails, books: newBooks });
	};

	const handleBookChange = (index, field, value) => {
		const newBooks = [...planDetails.books];
		newBooks[index][field] = value;
		setPlanDetails({ ...planDetails, books: newBooks });
	};

	const handleAddPlan = () => {
		setModalOpen(true);
		setModalType("add");
	};

	const handleEditPlan = (plan) => {
		setModalOpen(true);
		setModalType("edit");
	};

	const handleDuplicatePlan = (plan) => {
		setModalOpen(true);
		setModalType("duplicate");
	};

	const handleDeletePlan = (plan) => {
		setModalOpen(true);
		setModalType("delete");
	};

	const handleSaveEdits = () => {
		console.log('Saving plan details:', planDetails);
	};

	return (
		<Box height={"80vh"}>
			<Box display={'flex'} justifyContent={'space-between'} gap={2} height={"100%"}>
				{/* Left Panel - Plan Details */}
				<Box width={'50%'} height={'100%'} bgcolor={'#d0373b'} borderRadius={2} overflow="auto">
					<Typography variant='h5' fontWeight={"bold"} textAlign={"left"} m={2} color="white">
						{planDetails.name} ({planDetails.level})
					</Typography>
					<Typography mx={2} textAlign={"left"} color="white">{translate("Remaining Weeks for Reading: ")}14</Typography>
					<Typography mx={2} fontSize={"12px"} textAlign={"left"} color="white">{translate("The remaining weeks of reading is your Total Weeks of Discipleship less all weeks allocated for reading below.")}</Typography>
					<hr style={{ border: '0.5px solid #FFFFFF', width: '95%', margin: '10px auto' }} />
					<Box my={2}>
						<Box display={"flex"} mb={2} justifyContent={"space-between"} alignItems={"center"} mx={8} mr={25}>
							<Typography color={"#FFFFFF"} variant='h6'>{translate("Books")}</Typography>
							<Typography color={"#FFFFFF"} variant='h6'>{translate("Weeks to Read")}</Typography>
						</Box>
						{planDetails.books.map((book, index) => (
							<Box key={index} mx={2} mb={3} color="white">
								<Box display="flex" alignItems="center" gap={2}>
									<Box width={"5%"}>
										<Typography>{index + 1}.</Typography>
									</Box>
									<Box width={"65%"} display={"flex"} gap={2} alignItems={"center"}>
										<Select
											value={book.title}
											onChange={(e) => handleBookChange(index, 'title', e.target.value)}
											sx={{
												color: 'white',
												width: '100%',
												height: '50px',
												'& .MuiSelect-icon': { color: 'white' }
											}}
										>
											{availableBooks.map((title) => (
												<MenuItem key={title} value={title}>{title}</MenuItem>
											))}
										</Select>
										<Select
											value={book.startWeek}
											onChange={(e) => handleBookChange(index, 'startWeek', e.target.value)}
											sx={{
												color: 'white',
												width: '100px',
												height: '50px',
												'& .MuiSelect-icon': { color: 'white' }
											}}
										>
											{weeks.map((week) => (
												<MenuItem  sx={{backgroundColor: "#d0373b"}} key={week} value={week}>{week}</MenuItem>
											))}
										</Select>
									</Box>
									<Box width={"30%"} display="flex" alignItems="center" gap={2}>
										<IconButton onClick={() => handleDeleteBook(index)} sx={{ color: 'white' }}>
											<DeleteIcon />
										</IconButton>
										<IconButton onClick={() => handleAddBook(index)} sx={{ color: 'white' }}>
											<AddIcon />
										</IconButton>
									</Box>
								</Box>
								<Box mt={1}>
									<Box display={"flex"} gap={2} alignItems={"center"}>
										<Box width={"5%"} height={"100%"}></Box>
										<Box width={"65%"} display={"flex"} gap={2} justifyContent={"space-between"} alignItems={"center"}>
											<Box display={"flex"} gap={2} alignItems={"center"}>
												<Select
													value={book.endWeek}
													onChange={(e) => handleBookChange(index, 'endWeek', e.target.value)}
													sx={{
														color: 'white',
														width: '100px',
														height: '50px',
														'& .MuiSelect-icon': { color: 'white' }
													}}
												>
													{weeks.map((week) => (
														<MenuItem sx={{backgroundColor: "#d0373b"}} key={week} value={week}>{week}</MenuItem>
													))}
												</Select>
												<Typography>Start Week</Typography>
											</Box>
											<Box display={"flex"} gap={2} alignItems={"center"}>
												<Typography>Ends on Week</Typography>
												<Select
													value={book.endWeek}
													onChange={(e) => handleBookChange(index, 'endWeek', e.target.value)}
													sx={{
														color: 'white',
														width: '100px',
														height: '50px',
														'& .MuiSelect-icon': { color: 'white' }
													}}
												>
													{weeks.map((week) => (
														<MenuItem sx={{backgroundColor: "#d0373b"}} key={week} value={week}>{week}</MenuItem>
													))}
												</Select>
											</Box>
										</Box>
										<Box width={"30%"} height={"100%"}></Box>
									</Box>
								</Box>
							</Box>
						))}
					</Box>
					<Box
						onClick={handleSaveEdits}
						width={'40%'}
						margin={'20px auto'}
						sx={{
							bgcolor: '#000000',
							color: 'white',
							px: 3,
							py: 1,
							borderRadius: 12,
							cursor: 'pointer',
						}}
					>
						{translate("Save Edits")}
					</Box>
				</Box>

				{/* Right Panel - Plans List */}
				<Box width={'50%'} height={'100%'}>
					<Box
						border={'1px solid #d0373b'}
						borderRadius={2}
						height={'60%'}
						mb={5}
						sx={{ overflowY: 'auto' }}
					>
						<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} p={2}>
							<Typography color={"#000000"} variant='h5' fontWeight={"bold"} textAlign={"left"}>
								{translate("SD Master Plans")}
							</Typography>
							<Box
								onClick={handleAddPlan}
								sx={{
									color: '#d0373b',
									display: 'flex',
									alignItems: 'center',
									cursor: 'pointer',
								}}
							>
								<AddIcon /> {translate("Add Plan")}
							</Box>
						</Box>
						<Box sx={{ px: 2 }}>
							{masterPlans.map((plan, index) => (
								<Box
									key={index}
									display="flex"
									alignItems="center"
									justifyContent="space-between"
									mb={2}
									sx={{
										cursor: 'pointer',
										'&:hover': { bgcolor: '#f5f5f5' }
									}}
								>
									<Typography color={"#000000"} variant='h6'>
										{plan.name} ({plan.level})
									</Typography>
									<Box display="flex" gap={2}>
										<EditIcon
											onClick={() => handleEditPlan(plan)}
											sx={{ color: '#000000', cursor: 'pointer' }}
										/>
										<QueueIcon
											onClick={() => handleDuplicatePlan(plan)}
											sx={{ color: '#000000', cursor: 'pointer' }}
										/>
										<DeleteIcon
											onClick={() => handleDeletePlan(plan)}
											sx={{ color: '#000000', cursor: 'pointer' }}
										/>
									</Box>
								</Box>
							))}
						</Box>
					</Box>
				</Box>
			</Box>
			<Modal
				open={modalOpen}
				setOpen={setModalOpen}
				title={translate(modalType === 'add' ? "Add SD Standard Plan" : modalType === 'edit' ? "Edit Reading Plan" : modalType === "duplicate" ? "Duplicate Reading Plan" : "Delete Reading Plan")}
				modalbgColor="#ffffff"
				modalTextColor="#000000"
				modalTitleColor="#d32f2f"
				buttons={[
					{ name: 'Cancel', onClick: () => setModalOpen(false) },
					{
						name: modalType === 'delete' ? translate("Delete") : translate("Save"),
						onClick: () => { },
					}
				]}
			>
				{modalType === "delete" && <DeleteReadingPlanComponent />}
				{modalType === "add" && <AddReadingPlanComponent />}
				{modalType === "edit" && <EditDuplicateReadingPlanComponent />}
				{modalType === "duplicate" && <EditDuplicateReadingPlanComponent />}
			</Modal>
		</Box>
	);
};

export default ReadingPlansTabComponent;
