import React from 'react';
import sdlogo from '../../../images/sdlogo.png';
import {Box, Button, Typography} from '@mui/material';
import useTranslations from '../../../hooks/useTranslation';

const GetStartedOne = ({setPage}) => {
  const { translate } = useTranslations();
  
  return (
    <Box mx={3} my={5}>
      <img src={sdlogo} width="80" height="80" />
      <Typography mt={2} variant="h3" fontWeight="medium">
        {translate("A Simple Repeatable Method")}
      </Typography>
      <Typography mb={2} >{translate("HOW IT WORKS")} &bull; {translate("1 OF 5")}</Typography>
      <Typography variant="h6" py={2}>
        <strong>{translate("Let's Make Disciples!")}</strong> {translate("is powered by the Sustainable Discipleship Method. The method is a proven 'how to actually do this' - not more theory.")}
      </Typography>
      <Typography variant="h6" py={2}>
        {translate("The SD Method is being used across the US, on six continents, and in eight denominations to achieve 95% success in disciple making.")}
      </Typography>
      <Typography variant="h6" py={2} style={{marginBottom: "20px"}}>
        {translate("That means 95% get in a group, finish the process, and go on to make disciples.")}
      </Typography>
      <Button type="submit" fullWidth onClick={() => setPage(3)} variant="contained" color="secondary" style={{fontWeight: 400, marginBottom: '40px'}}>
        {translate("WHAT IS THE METHOD?")}
      </Button>
    </Box>
  );
};

export default GetStartedOne;
