import {create} from 'zustand';
import {createJSONStorage, persist} from 'zustand/middleware';

const leaderStore = (set, get) => ({
  meetings: null,
  group: null,
  editingMeeting: null,

  setMeetings: data => {
    set(state => ({
      ...state,
      meetings: data
    }));
  },
  setGroupsData: data => {
    set(state => ({
      ...state,
      group: data
    }));
  },
  clearLeaderData: () => {
    set(state => ({
      ...state,
      meetings: null,
      group: null,
      editingMeeting: null
    }));
  },
  setEditingMeeting: data => {
    set(state => ({
      ...state,
      editingMeeting: data
    }));
  },
  setGroupDisciples: data => {
    set(state => ({
      ...state,
      group: {...state?.group, disciple: data}
    }));
  }
});

const useLeaderStore = create(
  persist(leaderStore, {
    name: 'leader-storage',
    storage: createJSONStorage(() => localStorage)
  })
);

export default useLeaderStore;
