import React from 'react';
import sdlogo from '../../../images/sdlogo.png';
import {Box, Button, Typography} from '@mui/material';
import useTranslations from '../../../hooks/useTranslation';

const GetStartedThree = ({setPage}) => {
  const { translate } = useTranslations();
  return (
    <Box mx={3} my={5}>
      <img src={sdlogo} width="80" height="80" />
      <Typography mt={2} variant="h3" fontWeight="medium">
        {translate("Why the Method Works")}
      </Typography>
      <Typography mb={2}>{translate("HOW IT WORKS")} &bull; {translate("3 OF 5")}</Typography>
      <Typography variant="h5" pt={2} textAlign="left" fontWeight="bold" mb={1}>
        {translate("It's Super Simple")}
      </Typography>
      <Typography variant="h6" textAlign="left">
        {translate("SD is so easy, so simple that the average Joe can be ready to make well-made disciples after a two-day workshop or by reading the 'How To' book.")}
      </Typography>
      <Typography variant="h5" pt={2} textAlign="left" fontWeight="bold" mb={1}>
        {translate("Proven Methods")}
      </Typography>
      <ul style={{color: 'white', textAlign: 'left', paddingLeft: '21px', marginBottom: "38px"}}>
        <li style={{marginBottom: "4px"}}>{translate("Uses the same method that God used to make disciples in the Old and New Testament.")}</li>

        <li style={{marginBottom: "4px"}}>{translate("Leverages the Predictable Pathway to give disciples what they need when they need it.")}</li>


        <li style={{marginBottom: "4px"}}>{translate("Optimize group design and recruiting.")}</li>
        <li style={{marginBottom: "4px"}}>{translate("An innovative and addictive Bible Reading method.")}</li>
        <li>{translate("And, so much more...")}</li>
      </ul>

      <Button type="submit" fullWidth onClick={() => setPage(5)} variant="contained" color="secondary" style={{fontWeight: 400, marginBottom: '40px'}}>
        {translate("WHAT'S THE APP DO?")}
      </Button>
    </Box>
  );
};

export default GetStartedThree;