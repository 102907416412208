import React, {useEffect, useState} from 'react';
import Sidebar from './Sidebar';
import DashboardHeader from './DashboardHeader';
import {Box} from '@mui/system';
import {getAdminOrgDisciplesApi} from '../../../api';
import useAdminStore from '../../../store/adminStore';
import useAuthStore from '../../../store/authStore';

const AdminStructure = ({children, handleAddClick, isSearch, searchHandler, heading, isAddingUser, isAddingGroup, isAddingDisciple}) => {
  const {organizationSelected} = useAdminStore(state => state);
  const {user} = useAuthStore(state => state);
  const [disciples, setDisciples] = useState([]);

  const getDiscipleDataHandler = async () => {
    try {
      const res = await getAdminOrgDisciplesApi(organizationSelected, 'disciple');
      if (res?.data?.status === 200) {
        setDisciples(res?.data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDiscipleDataHandler();
  }, []);

  return (
    <Box display={'flex'}>
      <Sidebar totalDisciples={disciples.length || 0} />
      <Box height={'auto'} flexGrow={1} sx={{ width: 'calc(100vw - 300px)' }}>
        <DashboardHeader user={user} heading={heading} isAddingUser={isAddingUser} handleAddClick={handleAddClick} isSearch={isSearch} searchHandler={searchHandler} isAddingGroup={isAddingGroup} isAddingDisciple={isAddingDisciple} />
        {children}
      </Box>
    </Box>
  );
};

export default AdminStructure;
