import React, {useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {Alert, Grid, Box} from '@mui/material';
import TextInput from '../components/common/TextInput';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FormWrapper from '../components/common/StyledFormWrapper';
import {useNavigate} from 'react-router-dom';
import {changePasswordApi} from '../api/index';
import PasswordChecklist from 'react-password-checklist';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HeaderContainer from '../components/common/HeaderBox';
import useTranslations from '../hooks/useTranslation';

const ChangePassword = () => {
  const {translate} = useTranslations();
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const changePasswordHandler = async () => {
    if (!isValidPassword) {
      setError(translate('Invalid password!'));
      return;
    }
    const data = {
      currentPassword: currentPassword,
      newPassword: password
    };
    try {
      setLoading(true);
      const response = await changePasswordApi(data);
      if (response?.data?.status === 200) {
        navigate(-1);
      } else {
        setError(translate('Failed to change password!'));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClick = e => {
    e.preventDefault();
    if (!currentPassword || !password || !confirmPassword) {
      setError(translate('All fields are required!'));
      return;
    }
    changePasswordHandler();
  };

  return (
    <>
      <CssBaseline /> 
      <HeaderContainer pt={1} mt={2} mb={4}>
        <ArrowBackIcon style={{cursor: 'pointer', color: 'white', marginLeft:'15px'}} onClick={() => navigate(-1)}  />
      </HeaderContainer>
      <Box mt={3} px={3} overflow="auto">
        <FormWrapper
          buttonInputs={[
            {
              text: translate('Update'),
              onClick: handleClick,
              type: 'submit'
            }
          ]}
          headingText={translate('Change Password')}
          loading={loading}
        >
            <Grid item xs={12} marginBottom={1}>
              <div className="relative_container">
                <TextInput
                  autoComplete="off"
                  label={translate("Current Password")}
                  value={currentPassword}
                  isAllowSpecialChar={true}
                  placeholder={translate("Current Password")}
                  onChange={e => {
                    setCurrentPassword(e.target.value);
                    setError(null);
                  }}
                  type={showCurrentPassword ? 'text' : 'password'}
                  labelVariant="body2"
                />
                {showCurrentPassword ? <VisibilityIcon onClick={() => setShowCurrentPassword(false)} className="eye_icon" /> : <VisibilityOffIcon onClick={() => setShowCurrentPassword(true)} className="eye_icon" />}
              </div>
            </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} marginBottom={1}>
              <div className="relative_container">
                <TextInput
                  autoComplete="off"
                  label={translate('New Password')}
                  value={password}
                  isAllowSpecialChar={true}
                  placeholder={translate('New Password')}
                  onChange={e => {
                    setPassword(e.target.value);
                    setError(null);
                  }}
                  type={showPassword ? 'text' : 'password'}
                  labelVariant="body2"
                />
                {showPassword ? <VisibilityIcon onClick={() => setShowPassword(false)} className="eye_icon" /> : <VisibilityOffIcon onClick={() => setShowPassword(true)} className="eye_icon" />}
              </div>
            </Grid>
            <Grid item xs={12} marginBottom={1}>
              <div className="relative_container">
                <TextInput
                  autoComplete="off"
                  label={translate('Confirm New Password')}
                  value={confirmPassword}
                  isAllowSpecialChar={true}
                  placeholder={translate('Confirm New Password')}
                  onChange={e => {
                    setConfirmPassword(e.target.value);
                    setError(null);
                  }}
                  type={showConfirmPassword ? 'text' : 'password'}
                  labelVariant="body2"
                />
                {showConfirmPassword ? <VisibilityIcon onClick={() => setShowConfirmPassword(false)} className="eye_icon" /> : <VisibilityOffIcon onClick={() => setShowConfirmPassword(true)} className="eye_icon" />}
              </div>
              <PasswordChecklist
                rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase', 'match']}
                minLength={8}
                validColor="#FFFFFF"
                value={password}
                valueAgain={confirmPassword}
                style={{textAlign: 'start'}}
                messages={{
                  minLength: translate('Should be mininum 8 characters.'),
                  specialChar: translate('Should contain special character.'),
                  number: translate('Should contain number.'),
                  capital: translate('Should contain uppercase letter.'),
                  lowercase: translate('Should contain lowercase letter.'),
                  match: translate('Password and confirm password should be same.')
                }}
                onChange={isValid => setIsValidPassword(isValid)}
              />
            </Grid>
            <Grid item xs={12} sx={{height: '55px'}}>
              {error && <Alert severity="error">{error}</Alert>}
            </Grid>
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default ChangePassword;
