import React, { useEffect, useState } from 'react';
import {ThemeProvider} from '@mui/material/styles';
import theme, {greenTheme, orangeTheme } from './styles/theme';
import {BrowserRouter as Router} from 'react-router-dom';
import ProjectRoutes from './routes/index';
import {styled} from '@mui/system';
import {Toaster} from 'react-hot-toast';
import './App.css';
import useTranslations from './hooks/useTranslation';
import LanguageSwitcher from './components/LanguageSwitcher';
import { GlobalStyles } from '@mui/material';


function App() {
  const {loadAllTranslations} = useTranslations();
// Refactor themeType initialization and theme selection
const [themeType, setThemeType] = useState(() => {
  const savedTheme = localStorage.getItem('theme');
  return savedTheme ? Number(savedTheme) : 1; // default to Red theme (1)
});

   // Map themeType to the appropriate theme
   const getTheme = (themeType) => {
     switch (themeType) {
       case 1:
         return theme;
       case 2:
         return greenTheme;
       case 3:
         return orangeTheme;
       default:
         return theme;
     }
   };
  const state = JSON.parse(localStorage.getItem('auth-storage'));
  const AppContainer = styled('div')(({theme}) => ({
    textAlign: 'center',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: state?.state?.user?.user_role === 'ADMIN' ? 'flex-start' : 'center',
    ...theme.palette.backgroundGradient
  }));

  useEffect(() => {
    const lang = localStorage.getItem('lang');
   loadAllTranslations(lang);
  }, []);

  return (
    <ThemeProvider theme={getTheme(themeType)}>
      <GlobalStyles
    styles={{
      body: {
        backgroundColor: themeType === 1 ? '#ca0000' : themeType === 2 ? '#66bb6a' : '#ffa726',
        backgroundImage:
        themeType === 1
            ? 'linear-gradient(330deg, #ca0000 64%, #9e0001 58%)'
            : themeType === 2
            ? 'linear-gradient(330deg, #4caf50 64%, #388e3c 58%)'
            : 'linear-gradient(330deg, #f57c00 64%, #ff9800 58%)',
      },
    }}
  />
      <Router>
        {/* <LanguageSwitcher /> */}
        <AppContainer>
          <ProjectRoutes />
          {/* Toaster for toast message and triggered by the toast function in components */}
          <Toaster
            position="bottom-center"
            gutter={-110}
            containerStyle={{
              bottom: '18%',
              pointerEvents: 'none'
            }}
            toastOptions={{
              success: {
                iconTheme: {
                  primary: 'black'
                }
              },
              style: {
                background: '#000',
                color: '#FFF',
                pointerEvents: 'none'
              }
            }}
          />
        </AppContainer>
      </Router>
    </ThemeProvider>
  );
}

export default App;
