import React from 'react';
import {Alert, Box, Grid, MenuItem, Select, Typography} from '@mui/material';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {useState} from 'react';
import FormWrapper from '../../components/common/StyledFormWrapper';
import {getReportApi} from '../../api/index';
import {getOrganisationReport, getOverallGrowthReport, getYearReport} from '../../utils';
import {CONSTANTS} from '../../constants/index';
import moment from 'moment';
import AdminStructure from './common/AdminStructure';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import toast from 'react-hot-toast';
import useTranslations from '../../hooks/useTranslation';

const Reports = () => {
  const {translate} = useTranslations();
  const [reportForm, setReportForm] = useState({
    name: '',
    start_date: new Date(Date.now()),
    end_date: new Date(Date.now())
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (name, value) => {
    setError(null);
    setReportForm(prev => {
      return {
        ...prev,
        [name]: value
      };
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (reportForm.name.length === 0) {
      toast.error(translate("Please select the report"), {id: "error_message"});
      return;
    }
    getReportData(reportForm);
  };

  const getReportData = async data => {
    try {
      const submittingData = {
        ...data,
        start_date: new Date(data.start_date).getFullYear(),
        end_date: new Date(data.end_date).getFullYear()
      };
      setLoading(true);
      const response = await getReportApi(submittingData);
      if (response?.data?.status === 200) {
        if (reportForm.name === 'growth') {
          getOverallGrowthReport(response?.data?.data);
        } else if (reportForm.name === 'year') {
          getYearReport(response?.data?.data);
        } else {
          getOrganisationReport(response?.data);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Box>
        <div
          style={{
            backgroundImage: 'none !important',
            backgroundColor: '#f9f9f9',
            height: '100vh',
            width: '100vw',
            overflowY: 'hidden'
          }}
        >
          <AdminStructure heading={translate("Reports")}>
            <>
              <Box height="100vh" display="flex" flexDirection="column">
                <Box px={2} pt={2}>
                  <FormWrapper
                    buttonInputs={[
                      {
                        text: translate("Download Report"),
                        onClick: e => handleSubmit(e),
                        type: 'submit'
                      }
                    ]}
                    loading={loading}
                  >
                    <Box mb={2}>
                      <Typography textAlign="left" marginBottom={1} variant="body2" color="black">
                        {translate("Pick Your Report")}
                      </Typography>
                      <Select sx={{borderRadius: '4px', backgroundColor: 'white', color: 'black'}} variant="outlined" fullWidth name="name" placeholder={translate("Select Organization")} value={reportForm.name} onChange={e => handleInputChange('name', e.target.value)} displayEmpty>
                        <MenuItem sx={{display: 'none', height: '0px'}} value="">
                          <em>{translate("Select Report")}</em>
                        </MenuItem>
                        <MenuItem sx={{color: 'black', background: 'white'}} value="growth">
                          {translate("Growth Report")}
                        </MenuItem>
                        <MenuItem sx={{color: 'black', background: 'white'}} value="org">
                          {translate("Organization Report")}
                        </MenuItem>
                        <MenuItem sx={{color: 'black', background: 'white'}} value="year">
                          {translate("Year Report")}
                        </MenuItem>
                      </Select>
                    </Box>
                    <Box mb={2}>
                      <Typography textAlign="left" marginBottom={1} variant="body2" color="black">
                        {translate("Pick Your Start Year")}
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            views={['year']}
                            sx={{
                              borderRadius: '4px',
                              svg: {color: 'black !important'},
                              input: {color: 'black !important'},
                              label: {color: 'black !important'},
                              color: "black !important"
                            }}
                            name="start_date"
                            value={moment(reportForm?.start_date)}
                            onChange={date => handleInputChange('start_date', date)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                    <Box mb={2}>
                      <Typography textAlign="left" marginBottom={1} variant="body2" color="black">
                        {translate("Pick Your End Year")}
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DemoContainer components={['DatePicker2']}>
                          <DatePicker
                            views={['year']}
                            sx={{
                              borderRadius: '4px',
                              svg: {color: 'black !important'},
                              input: {color: 'black !important'},
                              label: {color: 'black !important'},
                              color: "black !important"
                            }}
                            name="end_date"
                            minDate={moment(reportForm?.start_date)}
                            value={moment(reportForm?.end_date)}
                            onChange={date => handleInputChange('end_date', date)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                    <Grid item xs={12} sx={{height: '55px'}}>
                      {error && <Alert severity="error">{error}</Alert>}
                    </Grid>
                  </FormWrapper>
                </Box>
              </Box>
            </>
          </AdminStructure>
        </div>
      </Box>
    </>
  );
};

export default Reports;
