import React, {useState, useEffect} from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import CssBaseline from '@mui/material/CssBaseline';
import {Typography, Grid, Radio, RadioGroup, FormControlLabel, Box, Alert} from '@mui/material';
import HeaderContainer from '../../common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {getExperienceApi, addExperienceApi} from '../../../api';
import {CONSTANTS} from '../../../constants';
import CheckBoxInput from '../../common/CheckBoxInput';
import {Divider} from '@mui/material';
import useTranslations from '../../../hooks/useTranslation';

const Journey = ({setPage, user}) => {
  const {translate} = useTranslations();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [error, setError] = useState(null);

  const answersData = {
    51: [
      {
        id: 1,
        options: [
          { value: "Very Prepared", label: translate("Very Prepared") },
          { value: "Somewhat Prepared", label: translate("Somewhat Prepared") },
          { value: "Not Really Prepared", label: translate("Not Really Prepared") }
        ]
      },
      {
        id: 2,
        options: [
          { value: "Very Confident", label: translate("Very Confident") },
          { value: "Somewhat Confident", label: translate("Somewhat Confident") },
          { value: "Not Really Confident", label: translate("Not Really Confident") }
        ]
      },
      {
        id: 3,
        options: [
          { value: "Very Skilled", label: translate("Very Skilled") },
          { value: "Somewhat Skilled", label: translate("Somewhat Skilled") },
          { value: "Not Really Skilled", label: translate("Not Really Skilled") }
        ]
      }
    ],
    52: [
      {
        id: 4,
        options: [
          { value: "Do the Right Thing", label: translate("Do the Right Thing") },
          { value: "Do the Wrong Thing", label: translate("Do the Wrong Thing") },
          { value: "It's a Toss Up", label: translate("It's a Toss Up") }
        ]
      },
      {
        id: 5,
        options: [
          { value: "Know What to Do", label: translate("Know What to Do") },
          { value: "Don't Know What to Do", label: translate("Don't Know What to Do") },
          { value: "A Little of Both", label: translate("A Little of Both") }
        ]
      },
      {
        id: 6,
        options: [
          { value: "Know the Why Behind What to Do", label: translate("Know the Why Behind What to Do") },
          { value: "Don't Really Get Why God say 'Do.'", label: translate("Don't Really Get Why God say 'Do.'") },
          { value: "A Little of Both", label: translate("A Little of Both") }
        ]
      }
    ],
    53: [
      {
        id: 7,
        options: [
          { value: "Buy a Car", label: translate("Buy a Car") },
          { value: "Pick a Date", label: translate("Pick a Date") },
          { value: "Pick a Job", label: translate("Pick a Job") },
          { value: "Handle Finances", label: translate("Handle Finances") },
          { value: "Raise a Kid", label: translate("Raise a Kid") },
          { value: "Manage Employees", label: translate("Manage Employees") }
        ]
      }
    ],
    54: [
      {
        id: 9,
        options: [
          { value: "Tithe to God", label: translate("Tithe to God") },
          { value: "Attend Church", label: translate("Attend Church") },
          { value: "Serve Others", label: translate("Serve Others") }
        ]
      }
    ]
  };  

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await getExperienceApi(CONSTANTS.DISCIPLE_JOURNEY_IDS.JOURNEY_1);
      if (response?.data?.status === 200) {
        const ques = response.data.data.map(item => ({
          ...item,
          question: translate(item.question), // Translate the question
          answers: item.answers ? item.answers.split(',') : []
        }));

        // Define an object to hold prefilled answers
        const prefilledAnswers = {};

        ques.forEach(item => {
          // Check if item has answers
          if (item.answers.length > 0 && (item.id === 51 || item.id === 52)) {
            const answerObj = {};
            item.answers.forEach((ans, index) => {
              answerObj[index + 1] = ans;
            });
            prefilledAnswers[item.id] = answerObj;
          } else if (item.id === 53 || item.id === 54) {
            prefilledAnswers[item.id] = item.answers;
          }
        });
        setQuestions(ques);
        setAnswers(prefilledAnswers);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAnswerChange = (questionId, answerId, value) => {
    setError(null);
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: {
        ...prevAnswers[questionId],
        [answerId]: value
      }
    }));
  };

  const handleCheckboxChange = (event, questionId) => {
    setError(null);
    const value = event.target.value;

    setAnswers(prevAnswers => {
      const currentAnswers = prevAnswers[questionId] || [];
      // Check if the value is already present in the current answers
      const isChecked = currentAnswers.includes(value);
      let updatedAnswers = [];
      if (isChecked) {
        // If the value is already present, remove it
        updatedAnswers = currentAnswers.filter(item => item !== value);
      } else {
        // If the value is not present, add it
        updatedAnswers = [...currentAnswers, value];
      }
      return {
        ...prevAnswers,
        [questionId]: updatedAnswers
      };
    });
  };

  const validateObject = obj => {
    const keys = Object.keys(obj);
    if (keys.length !== 4) {
      return false;
    }
    if (!obj.hasOwnProperty('51') || Object.keys(obj['51']).length !== 3 || !obj['51'].hasOwnProperty('1') || !obj['51'].hasOwnProperty('2') || !obj['51'].hasOwnProperty('3')) {
      return false;
    }
    if (!obj.hasOwnProperty('52') || Object.keys(obj['52']).length !== 3 || !obj['52'].hasOwnProperty('1') || !obj['52'].hasOwnProperty('2') || !obj['52'].hasOwnProperty('3')) {
      return false;
    }
    if (user?.user_role !== 'DISCIPLE' && (!obj.hasOwnProperty('53') || !Array.isArray(obj['53']) || obj['53'].length === 0)) {
      return false;
    }
    if (user?.user_role !== 'DISCIPLE' && (!obj.hasOwnProperty('54') || !Array.isArray(obj['54']) || obj['54'].length === 0)) {
      return false;
    }

    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (validateObject(answers)) {
      const data = {
        questions: [
          {
            question_id: 51,
            answers: [answers[51][1], answers[51][2], answers[51][3]].join(',')
          },
          {
            question_id: 52,
            answers: [answers[52][1], answers[52][2], answers[52][3]].join(',')
          },
          {
            question_id: 53,
            answers: answers[53].join(',')
          },
          {
            question_id: 54,
            answers: answers[54].join(',')
          }
        ]
      };
      try {
        setLoading(true);
        const response = await addExperienceApi(data);
        if (response?.data?.status === 200) {
          setPage(3);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      setError(translate('All fields are required!'));
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  return (
    <>
      <CssBaseline />
      <HeaderContainer pt={1} mt={2} mb={4}>
        <ArrowBackIcon style={{cursor: 'pointer', color: 'white'}} onClick={() => setPage(1)} color="white" />
      </HeaderContainer>
      <Typography variant="h3">{translate('Tell Us About You')}</Typography>
      <Typography variant="body1" style={{marginTop: '20px'}}>
        {translate('YOUR JOURNEY')} &bull; {translate('2 OF')} {user.user_role === 'LEADER' ? '4' : '3'}
      </Typography>
      <Box mt={1} px={1} overflow="auto">
        <FormWrapper
          buttonInputs={[
            {
              text: translate('Continue'),
              onClick: handleSubmit,
              type: 'submit'
            }
          ]}
          loading={loading}
        >
          <Box my={1} overflow="hidden">
            {questions.length > 0 &&
              questions.map((quest, index) => (
                <Box key={index} mb={2} overflow="hidden">
                  {/* {quest.id} */}
                  {answersData[quest.id]?.length > 0 && (
                    <Box sx={{textAlign: 'left'}}>
                      <Typography variant="body2" gutterBottom>
                        {quest?.question}
                      </Typography>
                      <Divider sx={{backgroundColor: 'white', marginBottom: '7px'}} />
                    </Box>
                  )}
                  <Box overflow="hidden">
                    {answersData[quest.id]?.length > 0 &&
                      (quest.id === 53 || quest.id === 54
                        ? answersData[quest.id].map((ans, subIndex) => (
                            <Box key={subIndex} textAlign="left" display="flex" flexWrap="wrap">
                              {ans.options.map((option, optIndex) => (
                                <CheckBoxInput width="50%" label={option.label} checked={answers[quest.id]?.includes(option.value) || false} onChange={val => handleCheckboxChange({target: {value: option.value}}, quest.id)} labelFontSize="0.8rem" />
                              ))}
                            </Box>
                          ))
                        : answersData[quest.id].map((ans, subIndex) => (
                            <RadioGroup
                              key={subIndex}
                              aria-label={`question-${quest.id}-${ans.id}`}
                              name={`question-${quest.id}-${ans.id}`}
                              value={answers[`${quest.id}`]?.[`${subIndex + 1}`] || ''}
                              onChange={event => handleAnswerChange(quest.id, subIndex + 1, event.target.value)}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 10,
                                textAlign: 'left',
                                marginTop: '1px',
                                flexWrap: 'nowrap'
                              }}
                            >
                              {ans.options.map((option, optIndex) => (
                                <FormControlLabel
                                  key={optIndex}
                                  value={option.value}
                                  control={<Radio />}
                                  label={option.label}
                                  sx={{
                                    textAlign: 'left',
                                    fontSize: '0.9rem',
                                    width: '30%',
                                    marginBottom: '12px',
                                    '& span:last-child': {
                                      fontSize: {
                                        xs: '0.8rem',
                                        sm: '0.8rem',
                                        md: '0.8rem'
                                      }
                                    }
                                  }}
                                />
                              ))}
                            </RadioGroup>
                          )))}
                  </Box>
                </Box>
              ))}
          </Box>
          <Grid item xs={12} sx={{height: '50px'}}>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default Journey;
