import React from 'react';
import sdlogo from '../../../images/sdlogo.png';
import {Box, Button, Typography} from '@mui/material';
import useTranslations from '../../../hooks/useTranslation';

const GetStartedFour = ({setPage}) => {
  const { translate } = useTranslations();
  return (
    <Box mx={3} my={5}>
      <img src={sdlogo} width="80" height="80" />
      <Typography mt={2} variant="h3" fontWeight="medium">
        {translate("Get Going Fast")}
      </Typography>
      <Typography variant="h3" fontWeight="medium">
        {translate("Let Us Handle the")}
      </Typography>
      <Typography variant="h3" fontWeight="medium">
        {translate("Heavy Lifting")}
      </Typography>
      <Typography mb={2} >{translate("HOW IT WORKS")} &bull; {translate("4 OF 5")}</Typography>

      <ol style={{color: 'white', margin: '0 18px', marginBottom: "40px"}}>
        <li>
          <Typography variant="h6" textAlign="left" fontWeight="bold" pt={2}>
            {translate("Set up your group in five minutes.")}
          </Typography>
          <Typography textAlign="left" mb={1}>
            {translate("The app even sets up your reading plan.")}
          </Typography>
        </li>
        <li>
          <Typography variant="h6" textAlign="left" fontWeight="bold">
            {translate("Invite folks from within the app.")}
          </Typography>
          <Typography textAlign="left" mb={1}>
            {translate("Disciples are added to your group as they respond.")}
          </Typography>
        </li>
        <li>
          <Typography variant="h6" textAlign="left" fontWeight="bold">
            {translate("Know who you are discipling.")}
          </Typography>
          <Typography textAlign="left" mb={1}>
            {translate("Disciples' profiles are shared with you.")}
            <br/>
            {translate("Are they introverts or extroverts?")}
          </Typography>
        </li>
        <li>
          <Typography variant="h6" textAlign="left" fontWeight="bold">
          {translate("Get going with your group")}
          </Typography>
          <Typography textAlign="left" mb={1}>
          {translate("Home screens tell you and disciples what reading is coming up, when to order a resource. Disciples can even track their reading.")}
          </Typography>
        </li>
        <li>
          <Typography variant="h6" textAlign="left" fontWeight="bold">
          {translate("Track Disciples Progress")}
          </Typography>
          <Typography textAlign="left" mb={1}>
          {translate("In-app assessments help you know what to do and when to do it.")}
          </Typography>
        </li>
      </ol>

      <Button type="submit" fullWidth onClick={() => setPage(6)} variant="contained" color="secondary" style={{fontWeight: 400, marginBottom: '40px'}}>
      {translate("HOW DO YOU START?")}
      </Button>
    </Box>
  );
};

export default GetStartedFour;