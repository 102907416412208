import React, { useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import AdminStructure from './common/AdminStructure';
import useTranslations from '../../hooks/useTranslation';
import TabSwitcher from './common/TabSwitcher';
import ReadingPlansTabComponent from './components/ReadingPlansTabComponent';
import AvailableBooksTabComponent from './components/AvailabelBooksTabComponent';


function ReadingPlans() {
	const { translate } = useTranslations();
	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
	const [selectedTab, setSelectedTab] = useState(0);
	return (
		<div
			style={{
				backgroundImage: 'none !important',
				backgroundColor: '#f9f9f9',
				height: '100vh',
				width: '100vw',
				overflowY: isSmallScreen ? 'auto' : 'hidden'
			}}
		>
			<AdminStructure heading={translate("Organization Standardized Outside Reading Plans")}>
				<Box px={2}>
					<TabSwitcher
						setSelectedTab={setSelectedTab}
						selectedTab={selectedTab}
						tab1Text={translate("Reading Plans")}
						tab2Text={translate("Available Books")}
					>
						{selectedTab === 0 && <ReadingPlansTabComponent />}
						{selectedTab === 1 && <AvailableBooksTabComponent />}
					</TabSwitcher>
				</Box>
			</AdminStructure>
		</div>
	);
}

export default ReadingPlans;
