import React, {useState} from 'react';
import {Box, Button, IconButton, Typography, Checkbox, FormControl, FormControlLabel, Radio, Select, MenuItem} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import Modal from '../../../components/common/Modal';
import {styled} from '@mui/material/styles';
import {Edit} from '@mui/icons-material';
import {updateGroupApi} from '../../../api';
import Loader from '../../../components/common/Loader';
import NoDataFoundMessage from '../../../components/common/NoDataFoundMessage';
import useTranslations from '../../../hooks/useTranslation';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import SelectInput from '../../../components/common/SelectInput';

const StyledSelect = styled('select')({
  width: '100%',
  padding: '10px',
  margin: '10px 0 20px',
  borderRadius: '4px',
  border: '1px solid #ccc',
  fontSize: '16px',
  outline: 'none',
  '&:focus': {
    borderColor: '#d32f2f'
  }
});

const ItemDetails = ({title, details, leaders, message, height, selectedGroup, loader, setSelectedGroup, level, editIcon = false, deleteIcon = false, reloadData, setIsModalOpen, setIsPromoteOpen}) => {
  const {translate} = useTranslations();
  const [newLeaderId, setNewLeaderId] = useState();
  const [editLeader, setEditLeader] = useState(false);
  const [selectedLeader, setSelectedLeader] = useState('');
  let toReplaceLeader = leaders?.find(leader => leader.name.trim() === newLeaderId);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [deleteOption, setDeleteOption] = useState('');
  const [selectGroup, setSelectGroup] = useState('');
  const [notify, setNotify] = useState(false);
  const [isMoveDiscipleOpen, setIsMoveDiscipleOpen] = useState(false);
  const [changeLeader, setchangeLeader] = useState(false);

  const groups = ['Doug’s Demo One', 'Group Two', 'Group Three']; // Example group list

  const handleLeaderCheckboxChange = event => {
    setchangeLeader(event.target.checked);
  };

  const handleSave = (selectGroup, notify) => {
    console.log(`Selected Group: ${selectGroup}`);
    console.log(`Notify via email: ${notify}`);
    setIsMoveDiscipleOpen(false);
  };

  const handleMoveDiscipleGroupChange = event => {
    setSelectGroup(event.target.value);
  };

  const handleMoveDiscipleCheckboxChange = event => {
    setNotify(event.target.checked);
  };

  const handleOpenModal = item => {
    setToDelete(item);
    setIsModalOpen(true);
  };

  const handleMenuOpen = (e, itemId) => {
    // setAnchorEl(e.currentTarget);
    setSelectedItemId(itemId);
    e.stopPropagation();
  };

  const handleMenuClose = e => {
    if (e) {
      e.stopPropagation();
    }
    // setAnchorEl(null);
    setSelectedItemId(null);
    setIsModalArchieveOpen(false);
    setIsModalDeleteOpen(false);
    setIsMoveDiscipleOpen(false);
  };

  const handleCloseModal = () => {
    setToDelete(null);
    setIsModalOpen(false);
  };

  const handleGroupStatusUpdate = async (groupId, status) => {
    try {
      let payload;
      // Determine the payload based on the status
      switch (status) {
        case 'ARCHIEVED':
          payload = {isMarkGroupAsArchived: true};
          break;
        case 'DELETED':
          payload = {isMarkGroupAsDeleted: true};
          break;
        default:
          return;
      }
      const res = await updateGroupApi(groupId, payload);
      if (res.data.status === 200) {
        handleMenuClose();
        if (reloadData) {
          reloadData();
        }
      }
    } catch (error) {
    } finally {
      handleMenuClose();
    }
  };

  // Handler for Deleting the group (opens confirmation modal)
  const handleDeleteGroup = (e, itemId) => {
    handleGroupStatusUpdate(itemId, 'DELETED');
    e.stopPropagation();
  };

  const handleReplaceLeader = async () => {
    try {
      const resp = await updateGroupApi(selectedGroup?.id, {
        replace_leader: toReplaceLeader?.id
      });
      if (resp?.data?.status == 200) {
        setSelectedGroup();
      }
    } catch (error) {}
  };
  const handleEditLeaderOpenModal = id => {
    setSelectedLeader(id);
    setEditLeader(true);
  };
  const handleEditItemCloseModal = () => {
    handleReplaceLeader();
    setEditLeader(false);
  };

  const getUserById = id => {
    return leaders?.find(leader => leader.user_id == id);
  };

  return (
    <Box
      p={3}
      overflow="scroll"
      bgcolor="#FFF"
      borderRadius={4}
      display="flex"
      flexDirection="column"
      gap={1}
      textAlign="left"
      sx={{
        height: {
          md: height,
          xs: 'auto',
          sm: 'auto'
        }
      }}
    >
      {loader ? (
        <Loader color="#9e0001" />
      ) : (
        <>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="h6" gutterBottom color="#d32f2f" fontWeight="bold">
                {title}
              </Typography>
              {level && (
                <Typography variant="h6" gutterBottom color="#d32f2f" fontWeight="bold" style={{marginLeft: '8px'}}>
                  {`(${level})`}
                </Typography>
              )}
            </Box>
            <Box display="flex" alignItems="center">
              {editIcon && (
                <IconButton onClick={() => {}}>
                  <Edit />
                </IconButton>
              )}
              {deleteIcon && (
                // <IconButton onClick={() => handleOpenModal(item.user_id || item.id)}>
                <IconButton
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  <DeleteIcon /> {/* Assuming you meant to use a delete icon here */}
                </IconButton>
              )}
            </Box>
          </Box>
          {details.length === 0 ? (
            <NoDataFoundMessage mt={5} message={translate('No disciples under this leader.')} />
          ) : (
            details?.map((detail, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant="body1"
                  color="black"
                  fontWeight={600}
                  mr={1}
                  sx={{
                    '& .nth-child(2)': {
                      color: 'red'
                    }
                  }}
                >
                  {detail?.name}
                </Typography>
                {detail.detail && (
                  <Typography variant="body2" color="black" sx={{marginBottom: '0px !important'}}>
                    {detail.detail}
                  </Typography>
                )}
                {detail?.leaders && (
                  <Typography variant="body2" color="black" sx={{marginBottom: '0px !important'}}>
                    {detail?.leaders?.name}
                  </Typography>
                )}
                {detail?.icon && (
                  <IconButton
                    onClick={() => {
                      handleEditLeaderOpenModal(detail.leaders.id);
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}
                {detail?.name === 'In Group:' && (
                  <IconButton
                    onClick={() => {
                      setIsMoveDiscipleOpen(true);
                      // handleEditLeaderOpenModal(detail.leaders.id);
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}
                {detail?.name === 'Approved to Lead D2' && (
                  <IconButton
                    onClick={() => {
                      setIsPromoteOpen(true);
                      // handleEditLeaderOpenModal(detail.leaders.id);
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
                {message && (
                  <Typography variant="body2" color="black" ml={1}>
                    {message}
                  </Typography>
                )}
              </div>
            ))
          )}
          <Modal modalbgColor="#ffffff" modalTextColor="#000000" modalTitleColor="#d32f2f" open={editLeader} onClose={handleEditItemCloseModal} title={translate('Change Group Leader')}>
            <Box mb={1} ml={1}>
              <StyledSelect
                value={newLeaderId}
                onChange={e => {
                  getUserById(e.target.value);
                  setNewLeaderId(e.target.value);
                }}
              >
                {leaders &&
                  leaders.map((leader, i) => {
                    const index = leaders.findIndex(leader => leader.id == selectedLeader);
                    return (
                      <>
                        {index == -1 && i == 0 && (
                          <option key="null" value={null} disabled={true} selected={index == -1 && leader.user_id !== selectedLeader}>
                            {translate('Deleted User')}
                          </option>
                        )}
                        {leader.status == 'ACTIVE' && (
                          <option key={leader.user_id} value={leader.user_id} disabled={leader.id === selectedLeader} selected={leader.id === selectedLeader}>
                            {leader.name ? leader.name : translate('Deleted User')}
                          </option>
                        )}
                      </>
                    );
                  })}
              </StyledSelect>
              <Typography variant="body1" mt={-2} mb={1} color="black">
                {translate('Type to Search for Existing Leader')}
              </Typography>
              <FormControlLabel
                sx={{
                  '& .MuiFormControlLabel-label': {color: '#000000 !important'},
                  '& .MuiCheckbox-root': {color: '#000000 !important'},
                  mb: 2
                }}
                control={<Checkbox checked={changeLeader} onChange={handleLeaderCheckboxChange} />}
                label="Send Notification to New Leader"
              />
              <Box display="flex" justifyContent="flex-end">
                <Button variant="outlined" color="secondary" onClick={() => setEditLeader(false)} sx={{mr: 1}}>
                  {translate('Cancel')}
                </Button>
                <Button variant="contained" color="primary" onClick={handleEditItemCloseModal} disabled={!newLeaderId}>
                  {translate('Replace')}
                </Button>
              </Box>
            </Box>
          </Modal>

          {/* modal for move disciple to new group */}
          <Modal
            open={isMoveDiscipleOpen}
            onClose={handleMenuClose}
            title="Move Disciple to New Group"
            modalbgColor="#ffffff"
            modalTextColor="#000000"
            modalTitleColor="#d32f2f"
            buttons={[
              {name: 'Cancel', onClick: () => setIsMoveDiscipleOpen(false)},
              {
                name: 'Save',
                onClick: () => handleSave(selectGroup, notify),
                disabled: !selectGroup // Disable Save button if no group is selected
              }
            ]}
          >
            <FormControl fullWidth sx={{mb: 2}}>
              <Select labelId="group-select-label" value={selectGroup} onChange={handleMoveDiscipleGroupChange} displayEmpty sx={{color: '#000000'}}>
                {groups.map((group, index) => (
                  <MenuItem
                    key={index}
                    value={group}
                    sx={{
                      backgroundColor: '#FFFFFF !important',
                      color: '#000000 !important',
                      '&:hover': {
                        backgroundColor: '#f5f5f5'
                      }
                    }}
                  >
                    {group}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="body2" sx={{mb: 2, color: '#000000'}}>
              Select Existing Group
            </Typography>
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {color: '#000000 !important'},
                '& .MuiCheckbox-root': {color: '#000000 !important'}
              }}
              control={<Checkbox checked={notify} onChange={handleMoveDiscipleCheckboxChange} />}
              label="Notify the disciple via email."
            />
          </Modal>
        </>
      )}
    </Box>
  );
};

export default ItemDetails;
