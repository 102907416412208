import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import NameAndAddressCard from './components/NameAndAddressCard';
import OrganizationStatsCard from './components/OrganizationStatsCard';
import OrganizationContactCard from './components/OrganizationContactCard';
import AdministratorsCard from './components/AdministratorsCard';
import LogoCard from './components/LogoCard';
import AdminStructure from './common/AdminStructure';
import useTranslations from '../../hooks/useTranslation';
import EditProfile from './components/EditProfile';

const Settings = () => {
  const { translate } = useTranslations();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <>
      <div
        style={{
          backgroundImage: 'none !important',
          backgroundColor: '#f9f9f9',
          height: '100vh',
          width: '100vw',
          overflow: 'hidden' // Prevents the page itself from scrolling
        }}
      >
        <AdminStructure heading={translate('Settings')}>
          <Box
            sx={{
              height: 'calc(100vh - 100px)', // Adjust the height to fit within available space
              overflowY: 'auto', // Enables vertical scrolling
              padding: 2
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                gap: 2
              }}
            >
              <NameAndAddressCard />
              <OrganizationStatsCard />
              <OrganizationContactCard />
              <AdministratorsCard />
              <LogoCard />
              <EditProfile />
            </Box>
          </Box>
        </AdminStructure>
      </div>
    </>
  );
};

export default Settings;
