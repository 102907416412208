import { Box, Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import useTranslations from '../../../hooks/useTranslation';
import TextInput from '../../../components/common/TextInput';
import SelectInput from '../../../components/common/SelectInput';

const AddReadingPlanComponent = ({ bookId, onDelete }) => {
	const { translate } = useTranslations();
	const [planType, setPlanType] = useState('');
	const [readingPlan, setReadingPlan] = useState([
		{label: translate("ABC"), value: "ABC"},
		{label: translate("XYZ"), value: "XYZ"},
		{label: translate("123"), value: "123"},	
	]);

	return (
		<>
			<SelectInput 
				placeholder={translate("Select Reading Plan")}
				value={""}
				setValue={value => {}}
				optionArray={readingPlan}
				bottomLabel={translate("Select Reading Plan")}
				bottomLabelColor="black"
			/>
			<Typography fontWeight="bold" color={'#000000'} mb={2}>{translate("OR")}</Typography>
			<Typography fontWeight="bold" color={'#d32f2f'} mb={2}>{translate("Add Custom Plan")}</Typography>

			<TextInput
				labelColor="black"
				autoComplete="off"
				labelVariant="body2"
				colorBlack={'text-black'}
				value={""}
				onChange={e => { }}
				fullWidth
				mb={0}
				placeholder="Reading Plan Name"
				bottomLabel={translate("Reading Plan Name")}
				sx={{
					backgroundColor: 'white',
					color: 'black',
					border: '1px solid #ccc',
					borderRadius: '8px',
					'& .MuiInputBase-input': {
						color: 'black !important',
					},
					'& .MuiInputBase-input:focus': {
						color: 'black !important'
					}
				}}
			/>
			<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={2} mt={2}>
				<TextInput
					labelColor="black"
					autoComplete="off"
					labelVariant="body2"
					colorBlack={'text-black'}
					value={""}
					onChange={e => { }}
					mb={0}
					placeholder="No. of Weeks"
					bottomLabel={translate("No. of Weeks")}
					sx={{
						backgroundColor: 'white',
						color: 'black',
						border: '1px solid #ccc',
						borderRadius: '8px',
						'& .MuiInputBase-input': {
							color: 'black !important',
						},
						'& .MuiInputBase-input:focus': {
							color: 'black !important'
						}
					}}
				/>
				<RadioGroup
					row
					value={planType}
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: "nowrap",
						marginTop: -4,
						color: '#d32f2f !important',
						'& .MuiFormControlLabel-label': {
							color: '#d32f2f !important'
						}
					}}
					onChange={e => setPlanType(e.target.value)}
				>
					<FormControlLabel
						value="D1"
						control={
							<Radio
								sx={{
									color: '#d32f2f !important',
									'&.Mui-checked': {
										color: '#d32f2f !important'
									}
								}}
							/>
						}
						label="D1"
					/>
					<FormControlLabel
						value="D2"
						control={
							<Radio
								sx={{
									color: '#d32f2f !important',
									'&.Mui-checked': {
										color: '#d32f2f !important'
									}
								}}
							/>
						}
						label="D2"
					/>
					<FormControlLabel
						value="D3"
						control={
							<Radio
								sx={{
									color: '#d32f2f !important',
									'&.Mui-checked': {
										color: '#d32f2f !important'
									}
								}}
							/>
						}
						label="D3"
					/>
				</RadioGroup>
			</Box>
		</>
	);
};

export default AddReadingPlanComponent;
