import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, ListItemIcon, Typography, useMediaQuery } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import AdminStructure from './common/AdminStructure';
import useAdminStore from '../../store/adminStore';
import { getDashboardData } from '../../api';
import NoDataFoundMessage from '../../components/common/NoDataFoundMessage';
import Loader from '../../components/common/Loader';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import useTranslations from '../../hooks/useTranslation';
import { doubleRoundPersonIcon, multiPersonIcon, singleRoundPersonIcon } from '../../utils/svg';
import IconContainer from '../../components/common/IconContainer';
Chart.register(...registerables);

function Dashboard() {
  const { translate } = useTranslations();
  const { organizationSelected } = useAdminStore(state => state);
  const [dashboardData, setDashboardData] = useState();
  const [loadingPage, setLoadingPage] = useState(false);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const getDashboardDataHandler = async () => {
    try {
      setLoadingPage(true);
      const response = await getDashboardData();
      if (response?.data?.status === 200) {
        setDashboardData(response?.data?.data);
      }
      setLoadingPage(false);
    } catch (err) {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getDashboardDataHandler();
  }, [organizationSelected]);

  const years = dashboardData?.progressionData?.Years || [];
  const groupsData = [];
  const disciplesData = [];
  const successData = [];

  years.forEach(year => {
    groupsData.push(dashboardData?.progressionData['Groups'][year] || 0);
    disciplesData.push(dashboardData?.progressionData['Disciples'][year] || 0);
    successData.push(dashboardData?.progressionData['Success'][year] || 0);
  });

  const chartData = {
    labels: years,
    datasets: [
      {
        label: translate("Groups"),
        backgroundColor: '#171716',
        borderColor: '#171716',
        borderWidth: 1,
        hoverBackgroundColor: '#171716',
        hoverBorderColor: '#171716',
        data: groupsData
      },
      {
        label: translate("Disciples"),
        backgroundColor: '#9e0001',
        borderColor: '#9e0001',
        borderWidth: 1,
        hoverBackgroundColor: '#9e0001',
        hoverBorderColor: '#9e0001',
        data: disciplesData
      },
      {
        label: translate("Success"),
        backgroundColor: '#cecece',
        borderColor: '#cecece',
        borderWidth: 1,
        hoverBackgroundColor: '#cecece',
        hoverBorderColor: '#cecece',
        data: successData
      }
    ]
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            stepSize: 10
          }
        }
      ]
    }
  };

  return (
    <div
      style={{
        backgroundImage: 'none !important',
        backgroundColor: '#f9f9f9',
        height: '100vh',
        width: '100vw',
        overflowY: isSmallScreen ? 'auto' : 'hidden'
      }}
    >
      <AdminStructure heading={translate("Dashboard")}>
        {loadingPage ? (
          <Loader color="#9e0001" />
        ) : !dashboardData ? (
          <NoDataFoundMessage mt={5} message={translate("No data available for this organization")} />
        ) : (
            <Box
              display={'flex'}
              justifyContent={"space-between"}
              flexWrap={"wrap"}
              overflow={'auto'}
              pb={2}
              gap={2}
              sx={{
                height: {
                  md: '88vh',
                  xs: 'auto',
                  sm: 'auto'
                },
                flexDirection: {
                  md: 'row',
                  sm: 'column',
                  xs: 'column'
                },
                justifyContent: {
                  md: 'space-between'
                }
              }}
            >
              {/* Quick Look */}
              {dashboardData && (
                <Box bgcolor="#FFF" borderRadius="12px" padding={2} minHeight="200px" maxHeight={'350px'} width={'45.5%'}>
                  <Typography variant="h5" gutterBottom color="#000" textAlign="left" px={2}>
                    {translate("Quick Look")}
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <IconContainer color="#d32f2f" icon={multiPersonIcon} width={50} height={50} marginRight={8} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <Typography color="black" fontWeight={'bold'} variant={isSmallScreen ? 'h5' : 'h4'}>
                          {dashboardData.quickLookData['Groups']['Active']} {translate("Active Groups")}
                        </Typography>
                        <Typography color="#d32f2f" variant="body1">
                          {dashboardData.quickLookData['Groups']['Total']} {translate("Lifetime Groups")} &bull; {dashboardData.quickLookData['Groups']['Finished']} {translate("Finished Groups")}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <IconContainer color="#d32f2f" icon={singleRoundPersonIcon} width={50} height={50} marginRight={8} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <Typography color="black" fontWeight={'bold'} variant={isSmallScreen ? 'h5' : 'h4'}>
                          {dashboardData.quickLookData['Disciples']['Active']} {translate("Disciples in Process")}
                        </Typography>
                        <Typography color="#d32f2f" variant="body1">
                          {dashboardData.quickLookData['Disciples']['Total']} {translate("Lifetime Disciples")} &bull; {dashboardData.quickLookData['Disciples']['Finished']} {translate("Disciples Finished")}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <IconContainer color="#d32f2f" icon={doubleRoundPersonIcon} width={50} height={50} marginRight={8} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <Typography color="black" fontWeight={'bold'} variant={isSmallScreen ? 'h5' : 'h4'}>
                          {dashboardData.quickLookData['Leaders']['Active']} {translate("Active Leaders")}
                        </Typography>
                        <Typography color="#d32f2f" variant="body1">
                          {dashboardData.quickLookData['Leaders']['Total']} {translate("Lifetime Leaders")} &bull; {dashboardData.quickLookData['Leaders']['Inactive']} {translate("Inactive Leaders")}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
              )}
              {/* Process Stats */}
              {dashboardData && (
                <Box bgcolor="#FFF" borderRadius="12px" padding={2} minHeight="200px" maxHeight={'350px'} width={'45.5%'}>
                  <Typography gutterBottom variant="h5" color="#000" textAlign="left" px={2} mb={2}>
                    {translate("Through the Process Stats")}
                  </Typography>
                  <ul style={{padding: '0 20px'}}>
                    <li style={{display: 'flex', justifyContent: 'space-between', marginBottom: '8px'}}>
                      <Typography variant="body1" color="#000">{translate('Total Starts')}</Typography>
                      <div style={{ display: 'flex', gap: '3rem' }}>
                        <Typography variant="body1" color="#000000">{dashboardData.phaseData['Total'].D1_finished + dashboardData.phaseData['Total'].D1_started}</Typography>
                        <Typography visibility="hidden" variant="body1" color="#000000">0%</Typography>
                      </div>
                    </li>
                    <li style={{display: 'flex', justifyContent: 'space-between', marginBottom: '8px'}}>
                      <Typography variant="body1" color="#000">{translate('Total Disciples Made')}</Typography>
                      <div style={{ display: 'flex', gap: '3rem' }}>
                        <Typography variant="body1" color="#000000">{dashboardData.phaseData['Total'].D1_finished}</Typography>
                        <Typography variant="body1" color="#d32f2f">92%</Typography>
                      </div>
                    </li>
                    <li style={{display: 'flex', justifyContent: 'space-between', marginBottom: '8px'}}>
                      <Typography variant="body1" color="#000">{translate('Currently Stalled')}</Typography>
                      <div style={{ display: 'flex', gap: '3rem' }}>
                        <Typography variant="body1" color="#000000">{dashboardData.phaseData['Total'].D2_finished + dashboardData.phaseData['Total'].D2_started}</Typography>
                        <Typography variant="body1" color="#d32f2f">92%</Typography>
                      </div>
                    </li>
                    <li style={{display: 'flex', justifyContent: 'space-between', marginBottom: '8px'}}>
                      <Typography variant="body1" color="#000">{translate('Currently Dropped')}</Typography>
                      <div style={{ display: 'flex', gap: '3rem' }}>
                        <Typography variant="body1" color="#000000">{dashboardData.phaseData['Total'].D2_finished}</Typography>
                        <Typography variant="body1" color="#d32f2f">92%</Typography>
                      </div>
                    </li>
                    <li style={{display: 'flex', justifyContent: 'space-between', marginBottom: '8px'}}>
                      <Typography variant="body1" color="#000">{translate('Disciples Making Disciples')}</Typography>
                      <div style={{ display: 'flex', gap: '3rem' }}>
                        <Typography variant="body1" color="#000000">{dashboardData.phaseData['Total'].D3_finished + dashboardData.phaseData['Total'].D3_started}</Typography>
                        <Typography variant="body1" color="#d32f2f">92%</Typography>
                      </div>
                    </li>
                  </ul>
                </Box>
              )}
              {/* Progression */}
              {dashboardData && (
                <Box bgcolor="#FFF" borderRadius="12px" padding={2} minHeight="200px" maxHeight={'350px'} width={'45.5%'}>
                  <Typography gutterBottom variant="h5" color="#000" textAlign="left" px={2}>
                    {translate("Progression by Year")}
                  </Typography>
                  <Box height="300px" width={isSmallScreen ? 'auto' : '100%'}>
                    <Bar data={chartData} options={chartOptions} />
                  </Box>
                </Box>
              )}
              {/* Phase Stats */}
              {dashboardData && (
                <Box bgcolor="#FFF" borderRadius="12px" padding={2} minHeight="200px" maxHeight={'350px'} width={'45.5%'}>
                  <Typography gutterBottom variant="h5" color="#000" textAlign="left" px={2}>
                    {translate("Phase Stats")}
                  </Typography>
                  <List>
                    <ListItem dense>
                      <ListItemText
                        sx={{
                          '& .MuiListItemText-primary': {
                            color: '#000'
                          },
                          marginBottom: -2, // Adjust as needed
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                        primary="In D1"
                        secondary={dashboardData.phaseData['Total'].D1_finished + dashboardData.phaseData['Total'].D1_started}
                      />
                    </ListItem>
                    <ListItem dense>
                      <ListItemText
                        sx={{
                          '& .MuiListItemText-primary': {
                            color: '#000'
                          },
                          marginBottom: 6,
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                        primary="Finished D1"
                        secondary={dashboardData.phaseData['Total'].D1_finished}
                      />
                    </ListItem>
                    <ListItem dense>
                      <ListItemText
                        sx={{
                          '& .MuiListItemText-primary': {
                            color: '#000'
                          },
                          marginBottom: -2,
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                        primary="In D2"
                        secondary={dashboardData.phaseData['Total'].D2_finished + dashboardData.phaseData['Total'].D2_started}
                      />
                    </ListItem>
                    <ListItem dense>
                      <ListItemText
                        sx={{
                          '& .MuiListItemText-primary': {
                            color: '#000'
                          },
                          // marginBottom: 10,
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                        primary="Finished D2"
                        secondary={dashboardData.phaseData['Total'].D2_finished}
                      />
                    </ListItem>
                    <ListItem dense>
                      <ListItemText
                        sx={{
                          '& .MuiListItemText-primary': {
                            color: '#000'
                          },
                          marginBottom: -2,
                          marginTop: 8,
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                        primary="In D3"
                        secondary={dashboardData.phaseData['Total'].D3_finished + dashboardData.phaseData['Total'].D3_started}
                      />
                    </ListItem>
                    <ListItem dense>
                      <ListItemText
                        sx={{
                          '& .MuiListItemText-primary': {
                            color: '#000'
                          },
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                        primary="Finished D3"
                        secondary={dashboardData.phaseData['Total'].D3_finished}
                      />
                    </ListItem>
                  </List>
                </Box>
              )}
            </Box>
        )}
      </AdminStructure>
    </div>
  );
}

export default Dashboard;
