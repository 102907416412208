import {Box} from '@mui/material';

const HeaderContainer = ({children, ...rest}) => {
  return (
    <Box display="flex" alignItems="flex-start" {...rest}>
      {children}
    </Box>
  );
};

export default HeaderContainer;
