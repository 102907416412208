import React, {useEffect, useState} from 'react';
import CustomButton from '../../common/Button';
import CssBaseline from '@mui/material/CssBaseline';
import {Box} from '@mui/material';
import {useParams} from 'react-router-dom';
import {getAssessmentsQuestions} from '../../../api';
import {CONSTANTS} from '../../../constants';
import useTranslations from '../../../hooks/useTranslation';

const PageOne = ({nextpage, setPage, user}) => {
  const {translate} = useTranslations();
  const params = useParams();
  const [data, setData] = useState();

  const handleLevelChange = () => {
    if (params?.level === 'D1') {
      setPage(11);
    } else if (params?.level === 'D2') {
      setPage(12);
    } else if (params?.level === 'D3') {
      setPage(13);
    } else {
      setPage(11);
    }
  };

  const fetchQuestions = async () => {
    try {
      const response = await getAssessmentsQuestions(CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_3, user?.id, user?.group);
      setData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (user) {
      fetchQuestions();
    }
  }, [user]);

  const result = data?.find(item => item.id === 49 || item.id === 50);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        overflow: 'auto'
      }}
    >
      <CssBaseline />
      <Box mx={2} mt={12}>
        <CustomButton text={translate("Assessment")} onClick={() => nextpage()} />
        <CustomButton disabled={result?.answer ? false : true} text={translate("They have finished")} onClick={handleLevelChange} />
        <CustomButton text={translate("They're thinking about dropping")} onClick={() => setPage(9)} />
      </Box>
    </div>
  );
};

export default PageOne;
