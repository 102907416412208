import React, {useState} from 'react';
import {Typography, TextField, Select, MenuItem, FormControl, Radio, RadioGroup, FormControlLabel, Grid, Avatar, IconButton, Box} from '@mui/material';
import Modal from '../../../components/common/Modal';
import TextInput from '../../../components/common/TextInput';
import SelectInput from '../../../components/common/SelectInput';
import DatePicker from '../../../components/common/DatePicker';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import {CONSTANTS} from '../../../constants';
import useTranslations from '../../../hooks/useTranslation';

const AddGroupModal = ({open, setOpen, onSave}) => {
  const {translate} = useTranslations();
  const [groupName, setGroupName] = useState('');
  const [existingLeader, setExistingLeader] = useState([
    {label: translate('Tom Coffee'), value: 'Tom Coffee'},
    {label: translate('Tom Coffee2'), value: 'Tom Coffee2'},
    {label: translate('Leader Two'), value: 'Leader Two'}
  ]);
  const [readingPlan, setReadingPlan] = useState([
    {value: 'SD Standard', label: translate('SD Standard')},
    {value: 'SD Classic', label: translate('SD Classic')}
  ]);
  const [planType, setPlanType] = useState('');
  const [genderType, setGenderType] = useState('');
  const [meetingPlace, setMeetingPlace] = useState('');
  const [timeOfDay, setTimeOfDay] = useState([
    {label: translate('6:00 PM'), value: '6:00 PM'},
    {label: translate('7:00 PM'), value: '7:00 PM'},
    {label: translate('8:00 PM'), value: '8:00 PM'}
  ]);
  const [dayOfWeek, setDayOfWeek] = useState([
    {label: translate('Monday'), value: 'monday'},
    {label: translate('Tuesday'), value: 'tuesday'},
    {label: translate('Wednesday'), value: 'wednesday'},
    {label: translate('Thursday'), value: 'thursday'},
    {label: translate('Friday'), value: 'friday'},
    {label: translate('Saturday'), value: 'saturday'},
    {label: translate('Sunday'), value: 'sunday'}
  ]);
  const [startDate, setStartDate] = useState('');
  const [weeks, setWeeks] = useState('');
  const [dateOpen, setDateOpen] = useState(false);

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        zIndex: 1300 // Ensures it appears above other components
      }
    }
  };

  const handleSave = () => {
    onSave({
      groupName,
      existingLeader,
      readingPlan,
      planType,
      meetingPlace,
      timeOfDay,
      dayOfWeek,
      startDate,
      weeks
    });
    setOpen(false);
  };

  const imageChangeHandler = e => {
    // handleCaptchaChange();
    const file = e.target.files[0];
    if (file.size >= CONSTANTS.MAX_IMAGE_FILE_SIZE) {
      toast.error(translate('File is too large (not more than 10 mb)'));
      return;
    }
    if (isValidFile('image', file)) {
      setGroupData(prevData => ({...prevData, avatar: e.target.files[0]}));
    } else {
      toast.error(translate('Only image file allowed!'));
      return;
    }
  };

  return (
    <Modal
      open={open}
      setOpen={() => setOpen(false)}
      title="Add Group"
      modalbgColor="#ffffff"
      modalTextColor="#000000"
      modalTitleColor="#d32f2f"
      buttons={[
        {name: 'Cancel', onClick: () => setOpen(false)},
        {
          name: 'Save',
          onClick: handleSave,
          disabled: !groupName || !existingLeader || !readingPlan
        }
      ]}
    >
      <FormControl fullWidth sx={{mb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <label style={{cursor: 'pointer'}} htmlFor="contained-button-file">
          <Box
            sx={{
              display: 'inline-block',
              position: 'relative'
            }}
          >
            <Avatar
              src=""
              sx={{
                width: 60,
                height: 60
              }}
            />
            <EditIcon
              sx={{
                color: 'black',
                position: 'absolute',
                bottom: 0,
                right: 0,
                transform: 'translate(25%, 25%)', // Adjust this to position the icon better if needed
                zIndex: 1,
                backgroundColor: 'whitesmoke',
                borderRadius: '50%',
                padding: '2px'
              }}
            />
          </Box>
          <input onChange={imageChangeHandler} style={{display: 'none'}} accept="image/*" id="contained-button-file" type="file" />
        </label>
      </FormControl>

      <FormControl fullWidth>
        <TextInput
          labelColor="black"
          autoComplete="off"
          labelVariant="body2"
          colorBlack={'text-black'}
          value={groupName}
          onChange={e => setGroupName(e.target.value)}
          fullWidth
          placeholder="Enter group name"
          mb={0}
          bottomLabel={translate('Enter group name')}
          sx={{
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid #ccc',
            borderRadius: '8px',
            '& .MuiInputBase-input': {
              color: 'black !important'
            },
            '& .MuiInputBase-input:focus': {
              color: 'black !important'
            }
          }}
        />
      </FormControl>

      <FormControl fullWidth>
        <SelectInput
          value={existingLeader}
          setValue={e => {
            setExistingLeader(e.target.value);
          }}
          onChange={e => setExistingLeader(e.target.value)}
          fullWidth
          variant="outlined"
          optionArray={existingLeader}
          bottomLabel={translate('Select Existing Leader')}
          bottomLabelColor="black"
          sx={{borderRadius: '8px'}}
        />
      </FormControl>

      <FormControl component="fieldset">
        <RadioGroup
          row
          value={planType}
          sx={{
            color: '#d32f2f !important',
            '& .MuiFormControlLabel-label': {
              color: '#d32f2f !important'
            }
          }}
          onChange={e => setPlanType(e.target.value)}
        >
          <FormControlLabel
            value="D1"
            control={
              <Radio
                sx={{
                  color: '#d32f2f !important',
                  '&.Mui-checked': {
                    color: '#d32f2f !important'
                  }
                }}
              />
            }
            label="D1"
          />
          <FormControlLabel
            value="D2"
            control={
              <Radio
                sx={{
                  color: '#d32f2f !important',
                  '&.Mui-checked': {
                    color: '#d32f2f !important'
                  }
                }}
              />
            }
            label="D2"
          />
          <FormControlLabel
            value="D3"
            control={
              <Radio
                sx={{
                  color: '#d32f2f !important',
                  '&.Mui-checked': {
                    color: '#d32f2f !important'
                  }
                }}
              />
            }
            label="D3"
          />
        </RadioGroup>
      </FormControl>

      <FormControl component="fieldset">
        <RadioGroup
          row
          value={genderType}
          sx={{
            color: '#d32f2f !important',
            '& .MuiFormControlLabel-label': {
              color: '#d32f2f !important'
            }
          }}
          onChange={e => setGenderType(e.target.value)}
        >
          <FormControlLabel
            value="Co-Ed"
            control={
              <Radio
                sx={{
                  color: '#d32f2f !important',
                  '&.Mui-checked': {
                    color: '#d32f2f !important'
                  }
                }}
              />
            }
            label="Co-ed"
          />
          <FormControlLabel
            value="All Guys"
            control={
              <Radio
                sx={{
                  color: '#d32f2f !important',
                  '&.Mui-checked': {
                    color: '#d32f2f !important'
                  }
                }}
              />
            }
            label="All Guys"
          />
          <FormControlLabel
            value="All Girls"
            control={
              <Radio
                sx={{
                  color: '#d32f2f !important',
                  '&.Mui-checked': {
                    color: '#d32f2f !important'
                  }
                }}
              />
            }
            label="All Girls"
          />
        </RadioGroup>
      </FormControl>

      <FormControl fullWidth>
        <SelectInput
          value={readingPlan}
          setValue={e => {
            setReadingPlan(e.target.value);
          }}
          onChange={e => {
            console.log(e.target.value);
            setReadingPlan(e.target.value);
          }}
          fullWidth
          variant="outlined"
          MenuProps={menuProps}
          optionArray={readingPlan}
          bottomLabel={translate('Select Reading Plan')}
          bottomLabelColor="black"
          sx={{borderRadius: '8px'}}
        ></SelectInput>
      </FormControl>

      <FormControl fullWidth>
        <TextInput
          labelColor="black"
          autoComplete="off"
          labelVariant="body2"
          colorBlack={'text-black'}
          value={meetingPlace}
          onChange={e => setMeetingPlace(e.target.value)}
          fullWidth
          placeholder="Enter meeting location"
          bottomLabel={translate('Where will you meet?')}
          sx={{
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid #ccc',
            borderRadius: '8px',
            '& .MuiInputBase-input': {
              color: 'black !important'
            },
            '& .MuiInputBase-input:focus': {
              color: 'black !important'
            }
          }}
        />
      </FormControl>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <SelectInput sx={{borderRadius: '8px'}} value={''} setValue={value => {}} optionArray={timeOfDay} onChange={e => setTimeOfDay(e.target.value)} displayEmpty fullWidth variant="outlined" MenuProps={menuProps} bottomLabel={translate('Time of Day')} bottomLabelColor="black"></SelectInput>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <SelectInput sx={{borderRadius: '8px'}} value={''} setValue={value => {}} optionArray={dayOfWeek} onChange={e => setDayOfWeek(e.target.value)} displayEmpty fullWidth variant="outlined" MenuProps={menuProps} bottomLabel={translate('Day of Week')} bottomLabelColor="black"></SelectInput>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <DatePicker open={dateOpen} value={startDate} onChange={newValue => setStartDate(newValue)} setOpen={() => setDateOpen(true)} onClose={() => setDateOpen(false)} minDate={moment().subtract(1, 'year')} showDaysOutsideCurrentMonth sx={{borderRadius: '8px', border: '1px solid #ccc', '& .MuiInputBase-input': {color: 'black !important'}}} />
            <Typography variant="body2" sx={{mb: 1, mt: 1, color: '#000000'}}>
              Date You Will Start
            </Typography>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <SelectInput
              // value={weeks}
              sx={{borderRadius: '8px'}}
              value={''}
              setValue={value => {}}
              optionArray={dayOfWeek}
              onChange={e => setDayOfWeek(e.target.value)}
              displayEmpty
              fullWidth
              variant="outlined"
              MenuProps={menuProps}
              bottomLabel={translate('Number of Weeks')}
              bottomLabelColor="black"
            ></SelectInput>
          </FormControl>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddGroupModal;