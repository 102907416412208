import React from 'react';
import {Typography, useTheme} from '@mui/material';

const CheckBoxInput = ({label, checked, onChange, width, disabled = false, labelFontSize = '1rem', fullWidth, defaultValue}) => {
  const theme = useTheme();
  const checkBoxColor = theme.palette.checkbox.primary;
  const handleChange = event => {
    onChange(event.target.checked);
  };

  return (
    <div className={fullWidth} style={{display: 'flex', alignItems: 'center', justifyContent: 'start', width: width ? width : 'auto', marginBottom: '4px'}}>
      <input
        type="checkbox"
        checked={checked}
        {...(defaultValue && {
          defaultChecked: defaultValue,
          defaultValue: defaultValue
        })}
        disabled={disabled}
        onChange={handleChange}
        className="checkbox-item"
        style={{
          backgroundColor: checked ? {checkBoxColor} : 'white',
          cursor: 'pointer'
        }}
      />
      <label style={{fontSize: '16px'}}>
        <Typography variant="h6" fontSize={labelFontSize}>
          {label}
        </Typography>
      </label>
    </div>
  );
};

export default CheckBoxInput;
