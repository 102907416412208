import React from 'react';
import sdlogo from '../../../images/sdlogo.png';
import {Box, Button, Typography} from '@mui/material';
import useTranslations from '../../../hooks/useTranslation';

const GetStartedTwo = ({setPage}) => {
  const { translate } = useTranslations();
  return (
    <Box mx={3} my={5}>
      <img src={sdlogo} width="80" height="80" />
      
      <Typography mt={2} variant="h3" fontWeight="bold">
        {translate("The SD Method")}<br/> {translate("A Quick Look")}
      </Typography>
        {/* <Typography mt={2} variant="h3" fontWeight="medium">
        A Quick Look
      </Typography> */}
      <Typography mb={2}>{translate("HOW IT WORKS")} &bull; {translate("2 OF 5")}</Typography>
      <Typography variant="h5" pt={2} textAlign="left" fontWeight="bold" mb={1}>
        {translate("One Focus")}
      </Typography>
      <Typography variant="h6" textAlign="left">
        {translate("The focus is on transformation instead of education. The goal is to get disciples prepared, confident, and skilled to live the abundant life God promised.")}
      </Typography>
      <Typography variant="h6" py={2} textAlign="left">
        {translate("The secret is that healthy followers do healthy things. The result is that 95% of disciples made go on to make disciples without being asked.")}
      </Typography>
      <Typography variant="h5" pt={2} textAlign="left" fontWeight="bold" mb={1}>
        {translate("Five Foundations")}
      </Typography>
      <ul style={{color: 'white', textAlign: 'left', paddingLeft: '21px', marginBottom: "20px"}}>
        <li style={{marginBottom: "4px"}}>{translate("The Bible is the Fuel.")}</li>
        <li style={{marginBottom: "4px"}}>{translate("Disciples are made Intentionally.")}</li>
        <li style={{marginBottom: "4px"}}>{translate("A Simple Definition of Disciple Making.")}</li>
        <li style={{marginBottom: "4px"}}>{translate("The Focus is on the Individual.")}</li>
        <li>{translate("The Process is done Along the Way.")}</li>
      </ul>

      <Button type="submit" fullWidth onClick={() => setPage(4)} variant="contained" color="secondary" style={{marginTop: '20px', fontWeight: 400, marginBottom: '40px'}}>
        {translate("WHY DOES IT WORK?")}
      </Button>
    </Box>
  );
};

export default GetStartedTwo;
