import {Box, Typography, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Alert, IconButton} from '@mui/material';
import TextInput from '../../common/TextInput';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Modal from '../../common/Modal';
import {getOutsideReadingPlanApi, updateOutsideReadingPlanApi} from '../../../api';
import SelectInput from '../../common/SelectInput';
import DeleteIcon from '@mui/icons-material/Delete';
import FormWrapper from '../../common/StyledFormWrapper';
import useLeaderStore from '../../../store/leaderStore';
import Divide from '../../common/Divide';
import Loader from '../../common/Loader';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {getBookId, getWeeksToRead} from '../../../utils';
import Button from '../../common/Button';
import {CONSTANTS} from '../../../constants/index';
import theme from '../../../styles/theme';
import useTranslations from '../../../hooks/useTranslation';

const OutsideReadingPlan = () => {
  const {translate} = useTranslations();
  const location = useLocation();
  const {meetings, group} = useLeaderStore(state => state);
  const meetingId = location?.search.split('=')?.[1];
  const [outsideData, setOutsideData] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [customBookList, setCustomBookList] = useState([]);
  const [outsideReadingCheck, setOutsideReadingCheck] = useState(true);
  const [allData, setAllData] = useState([]);
  const [error, setError] = useState(null);
  const [startOutsideReadingWeek, setStartOutsideReadingWeek] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const weekDaysList = Array(5)
    .fill()
    .map((_, index) => ({label: index + 1, value: index + 1}));
  const [selectedBook, setSelectedBook] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddingBook, setIsAddingBook] = useState(false);
  const [isPlanUpdated, setIsPlanUpdated] = useState(false);
  const navigate = useNavigate();
  const groupLevel = group?.level;

 const readings = {
    "MYSTICAL_CHAPTER": {
      "label": translate("Mystical Chapters"),
      "value": "Mystical Chapters"
    }
  };

  const handleOpenModal = book => {
    setSelectedBook(book);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedBook(null);
    setIsModalOpen(false);
  };

  const handleConfirmDelete = () => {
    if (selectedBook) {
      handleDelete(selectedBook);
      handleCloseModal();
    }
  };

  const handleDelete = async book => {
    let newOutsideData = outsideData.filter(item => item.book_sequence !== book.book_sequence);
    if (newOutsideData.length === 0) return setError(translate("Reading plan cannot be empty."));
    newOutsideData = newOutsideData.map((item, index) => {
      return {...item, book_sequence: index + 1};
    });
    setOutsideData(newOutsideData);
  };

  const getOutsideReadingPlanHandler = async () => {
    try {
      setLoading(true);
      const res = await getOutsideReadingPlanApi(meetingId);
      if (res?.data?.status == 200) {
        setStartOutsideReadingWeek(res?.data?.data?.outside_reading_start_week || 1);
        setOutsideData(res?.data?.data?.reading);
        let useCustomBook = {
          label: translate("Use My Own Custom Book"),
          value: 'Use My Own Custom Book',
          weeks: ''
        };
        let customBookData = res?.data?.data?.reading?.map(book => ({
          label: book.book_name,
          value: book.book_name,
          weeks_to_read: book.weeks_to_read,
          book_id: book.book_id
        }));
        customBookData.push(useCustomBook);
        setStandardData(res?.data?.data?.standard_books);
        setCustomBookList(customBookData);
        setAllData(res?.data?.data?.reading);
        setIsPlanUpdated(res?.data?.data?.is_plan_updated)
        setOutsideReadingCheck(res?.data?.data?.type === 'CUSTOM' ? false : true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const updateOutsidePlanHandler = async data => {
    try {
      setLoading(true);
      const res = await updateOutsideReadingPlanApi(data);
      if (res?.data?.status === 200) {
        navigate('/leader');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleBookSelect = (value, index, weeks,book_id) => {
    const newOutsideData = outsideData.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          customBook: value === 'Use My Own Custom Book',
          book_name: value,
          weeks_to_read: weeks || '',
          customBookName: '',
          book_id: book_id
        };
      }
      return item;
    });
    setOutsideData(newOutsideData);
  };

  const handleBookChange = (value, index, type) => {
    const newOutsideData = outsideData.map((item, idx) => {
      if (idx === index) {
        if (type === 'week') {
          return {
            ...item,
            weeks_to_read: value
          };
        } else {
          return {
            ...item,
            customBookName: value
          };
        }
      }
      return item;
    });
    setOutsideData(newOutsideData);
  };
  const updateOutsideCustomPlan = () => {
    let data;
    if (outsideReadingCheck) {
      data = {
        group_id: parseInt(meetingId),
        outside_book_reading_type: 'STANDARD',
        outside_reading_start_week: startOutsideReadingWeek,
        data: outsideData.map((item, index) => {
          return {
            book_id: item.book_id,
            book_name: item.book_name,
            weeks_to_read: item?.weeks_to_read,
            year: group?.level,
            book_sequence: index + 1
          };
        })
      };
    } else {
      let outData = [];
      if (hasDuplicateBookNames(outsideData)) {
        setError(translate("Duplicate book names found. Please ensure all book names are unique."));
        return;
      }
      outData = outsideData.map((item, index) => {
        if (item.customBook) {
          return {
            book_id: null,
            book_name: item.customBookName,
            weeks_to_read: item?.weeks_to_read,
            year: group?.level,
            book_sequence: index + 1
          };
        } else {
          return {
            book_id: item.book_id,
            book_name: item.book_name,
            weeks_to_read: item?.weeks_to_read,
            year: group?.level,
            book_sequence: index + 1
          };
        }
      });
      data = {
        group_id: parseInt(meetingId),
        outside_book_reading_type: 'CUSTOM',
        outside_reading_start_week: startOutsideReadingWeek,
        data: outData
      };
    }
    updateOutsidePlanHandler(data);
  };

  const hasDuplicateBookNames = data => {
    const bookNames = data.map(item => (item.customBook ? item.customBookName : item.book_name));
    const uniqueBookNames = new Set(bookNames);
    return uniqueBookNames.size !== bookNames.length;
  };

  const getRemaingWeeks = () => {
    let totalWeekAvailable = 0;
    outsideData.map(data => {
      totalWeekAvailable += Number(data?.weeks_to_read);
    });
    totalWeekAvailable = Number(meetings?.meeting_weeks) - (totalWeekAvailable + (Number(startOutsideReadingWeek) - 1));
    return totalWeekAvailable < 0 ? 0 : totalWeekAvailable;
  };

  useEffect(() => {
    getOutsideReadingPlanHandler();

    return () => {
      setIsAddingBook(false);
    };
  }, []);

  const addBookHandler = () => {
    const maxBooks = CONSTANTS[`${group?.level}_MAX_BOOKS`];
    if (outsideData.length >= maxBooks) {
      setError(translate("You can add a maximum of {maxBooks} books.").replace("{maxBooks}", maxBooks ));
      return;
    }
    setIsAddingBook(true);
    setOutsideData([...outsideData, {book_name: '', weeks_to_read: 1, customBook: true, customBookName: ''}]);
  };

  const handleSaveNewBook = index => {
    setIsAddingBook(false);
    setError(null);
  };

  const handleCancelNewBook = index => {
    setIsAddingBook(false);
    const newOutsideData = outsideData.filter((_, idx) => idx !== index);
    setOutsideData(newOutsideData);
  };

  useEffect(() => {
    if (outsideReadingCheck) {
      setOutsideData(standardData);
    } else {
      setOutsideData(allData);
    }
  }, [outsideReadingCheck]);

  return (
    <>
      <FormWrapper
        buttonInputs={[
          // {
          //   text: isAddingBook ? "Save" : "Add Book",
          //   onClick: isAddingBook ? handleSaveNewBook : addBookHandler,
          //   loading: moment.utc(meetings.starting_time).isBefore(),
          //   visible: outsideReadingCheck ? false : true
          // },
          {
            text: isAddingBook ? translate("Cancel") : isPlanUpdated ? translate("Update") : translate("Save"),
            onClick: isAddingBook ? () => handleCancelNewBook(outsideData.length - 1) : updateOutsideCustomPlan,
            loading: moment.utc(meetings.starting_time).isBefore()
          }
        ]}
      >
        <Divide />
        <FormControl>
          <RadioGroup
            row
            style={{justifyContent: 'space-between'}}
            defaultValue={!!outsideReadingCheck}
            onChange={() => {
              setOutsideReadingCheck(!outsideReadingCheck);
            }}
          >
            <FormControlLabel
              control={<Radio />}
              sx={{
                '& .MuiFormControlLabel-label': {fontSize: '0.7rem'}
              }}
              value={!!outsideReadingCheck}
              checked={!!outsideReadingCheck}
              onChange={() => {
                setModalOpen(false);
              }}
              label={translate("Standard Reading Plan")}
            />
            <FormControlLabel
              control={<Radio />}
              sx={{
                '& .MuiFormControlLabel-label': {fontSize: '0.7rem'}
              }}
              value={!outsideReadingCheck}
              checked={!outsideReadingCheck}
              onChange={() => setModalOpen(true)}
              label={translate("My Own Custom Plan")}
            />
          </RadioGroup>
        </FormControl>
        <Box minHeight="400px">
          {loading ? (
            <Loader />
          ) : (
            <>
              <Box textAlign="start">
                <Typography sx={{fontSize: '11px'}}>{translate("Standard Plan will populate all fields that you can then customize.")}</Typography>
                <Typography sx={{fontSize: '11px'}}>{translate("Your own custom plan starts blank.")}</Typography>
                <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
                  <Typography sx={{whiteSpace: 'nowrap'}}>{translate("Start Outside Reading on Week:")}</Typography>
                  <Box
                    sx={{
                      '& >[class^=css-]': {
                        marginBottom: '0px'
                      }
                    }}
                  >
                    <SelectInput
                      value={startOutsideReadingWeek}
                      setValue={value => {
                        setStartOutsideReadingWeek(value);
                      }}
                      optionArray={weekDaysList}
                    />
                  </Box>
                </Box>
                <Typography sx={{fontSize: '10px'}}>{translate("Select using dropdown. D1 Outside Reading usually begins week 4.")}</Typography>

                <Typography sx={{marginY: '8px'}}>{translate("Remaining Weeks for Reading: ")}{getRemaingWeeks()}</Typography>
                <Typography sx={{fontSize: '11px'}}>{translate("The Remaining weeks of reading is your Total Weeks of Discipleship less all weeks allocated for reading below.")}</Typography>
              </Box>
              <Divide />
              <Grid overflow="hidden" container>
                <Box mb={2} display="flex" justifyContent="space-between" sx={{width: '100%'}}>
                  <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                    <Typography sx={{whiteSpace: 'nowrap', fontSize: '12px'}} textAlign="start" paddingLeft={'5px'}>
                      {translate("Book")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <Typography sx={{whiteSpace: 'nowrap', fontSize: '12px'}}>{translate("Weeks to Read")}</Typography>
                  </Grid>
                </Box>

                {groupLevel === 'D3' && (
                  <>
                    <Box width="7%">
                      <Typography sx={{fontSize: '18px', fontWeight: 'normal'}}></Typography>
                    </Box>
                    <Grid width={outsideReadingCheck ? '70%' : '67%'} container>
                      <TextInput value={readings.MYSTICAL_CHAPTER.value} readOnly={true} />
                    </Grid>
                  </>
                )}

                {outsideData?.map((option, index) => (
                  <Box width="100%" key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
                      <Box width="80%" display="flex" alignItems="center">
                        <Box width="10%">
                          <Typography sx={{fontSize: '18px', fontWeight: 'normal'}}>{index + 1}.</Typography>
                        </Box>
                        {outsideReadingCheck ? (
                          <Grid container>
                            <TextInput value={option?.book_name} readOnly={true} />
                          </Grid>
                        ) : (
                          <Grid container>
                            <>
                                                          {option?.book_name !== '' && (
                                <SelectInput
                                  formWidth={'95%'}
                                  value={option?.book_name}
                                  setValue={value => {
                                    setError(null);
                                    handleBookSelect(value, index, getWeeksToRead(customBookList, value)
                                    ,getBookId(customBookList, value));
                                  }}
                                  
                                  optionArray={customBookList}
                                />
                              )}
                              {option.customBook && !outsideReadingCheck && (
                                <Box display="flex" alignItems="center" justifyContent="space-between" gap={2} width={'95%'}>
                                  <TextInput
                                    paddingBlock={theme.spacing(1)}
                                    mb={0}
                                    placeholder={translate("My Custom Book")}
                                    value={option?.customBookName}
                                    onChange={e => {
                                      setError(null);
                                      handleBookChange(e.target.value, index, 'book');
                                    }}
                                  />
                                </Box>
                              )}
                            </>
                          </Grid>
                        )}
                      </Box>
                      <Box width="20%" display="flex" alignItems="center" justifyContent="center">
                        {outsideReadingCheck ? (
                          <TextInput
                            value={option.weeks_to_read}
                            type="number"
                            onChange={e => {
                              setError(null);
                              handleBookChange(e.target.value, index, 'week');
                            }}
                            readOnly={option?.customBook === true ? false : true}
                            sx={{
                              '& .MuiInputBase-input': {textAlign: 'center'}
                            }}
                          />
                        ) : (
                          <Box display="flex">
                            <SelectInput
                              formWidth={'auto'}
                              value={option?.weeks_to_read}
                              setValue={e => {
                                setError(null);
                                handleBookChange(e, index, 'week');
                              }}
                              optionArray={[...Array(17).keys()].map(i => ({
                                label: i,
                                value: i
                              }))}
                            />
                            {/* will be using later */}
                            {/* {!isAddingBook && (
                              <IconButton onClick={() => handleOpenModal(option)}>
                                <DeleteIcon
                                  sx={{
                                    color: "#ccc",
                                  }}
                                />
                              </IconButton>
                            )} */}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Grid>
            </>
          )}
        </Box>
        <Grid item xs={12} sx={{height: '70px'}}>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
      </FormWrapper>

      <Modal color="white" open={isModalOpen} onClose={handleCloseModal} title={translate("Confirm Delete")}>
        <Box>
          <Typography variant="body1" mb={2} color="white">
            {translate("Are you sure you want to delete the book")} "{selectedBook?.book_name}"?
          </Typography>
          <Box display="flex" gap={2} justifyContent="flex-end">
            <Button onClick={handleCloseModal} text={translate("Cancel")} />

            <Button onClick={handleConfirmDelete} text={translate("Delete")} />
          </Box>
        </Box>
      </Modal>

      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        title={translate("My Own Custom Plan")}
        buttons={[
          {
            name: translate("No"),
            onClick: () => {
              setModalOpen(false);
              setOutsideReadingCheck(true);
            }
          },
          {
            name: translate("Yes"),
            onClick: () => {
              setModalOpen(false);
            }
          }
        ]}
      >
        <Typography sx={{color: 'white'}}>{translate("Do you want to continue with Custom reading plan?")}</Typography>
      </Modal>
    </>
  );
};

export default OutsideReadingPlan;
