import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import useTranslations from '../../../hooks/useTranslation';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '../../../components/common/Modal';
import DeleteAvailableBookComponent from './DeleteAvailableBookComponent';
import AddUpdateBookComponent from './AddUpdateBookComponent';

const AvailableBooksTabComponent = () => {
	const { translate } = useTranslations();
	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState('');
	const [selectedBook, setSelectedBook] = useState(["Improving Your Serve", "Mystical Chapters", "Quick Smart Start"]);
	return (
		<Box height={"80vh"}>
			<Box display={'flex'} justifyContent={'space-between'} gap={2} height={"100%"}>
				<Box width={'50%'} height={'100%'} bgcolor={'#d0373b'} borderRadius={2} overflow="auto">
					<Typography variant='h5' fontWeight={"bold"} textAlign={"left"} m={2}>
						{translate("My Library")}
					</Typography>
					<Box my={2}>
						{selectedBook.map((book, index) => (
							<Typography variant='h6' textAlign={"left"} mx={2} key={index}>
								{book}
							</Typography>
						))}
					</Box>
				</Box>
				<Box width={'50%'} height={'100%'}>
					<Box
						display={'flex'}
						flexDirection={"column"}
						gap={2}
						sx={{ overflowY: 'hidden' }}
						height={'100%'}
					>
						<Box
							height={'60%'}
							border={'1px solid #d0373b'}
							borderRadius={2}
							sx={{ overflowY: 'auto' }}
						>
							<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} p={2}>
								<Typography color={"#000000"} variant='h5' fontWeight={"bold"} textAlign={"left"}>
									{translate("SD Tested Books")}
								</Typography>
								<Box
									sx={{
										height: "30px",
										width: "300px",
										bgcolor: '#d0373b',
										color: 'white',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										borderRadius: 12,
										cursor: 'pointer',
									}}
								>
									{translate("Add All SD Books to Library")}
								</Box>
							</Box>
							<Box sx={{ px: 2 }}>
								{selectedBook.map((book, index) => (
									<Box
										key={index}
										display="flex"
										alignItems="center"
										gap={2}
										mb={1}
									>
										<input
											type="checkbox"
											style={{
												width: '20px',
												height: '20px'
											}}
										/>
										<Typography color={"#000000"} variant='h6'>{book}</Typography>
									</Box>
								))}
							</Box>
						</Box>
						<Box
							height={'40%'}
							border={'1px solid #d0373b'}
							borderRadius={2}
							sx={{ overflowY: 'auto' }}
						>
							<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} p={2}>
								<Typography color={"#000000"} variant='h5' fontWeight={"bold"} textAlign={"left"}>
									{translate("My Custom Books")}
								</Typography>
								<Box
									onClick={() => { setModalType('add'); setModalOpen(true); }}
									sx={{
										height: "30px",
										color: 'black',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										borderRadius: 12,
										cursor: 'pointer',
									}}
								>
									<AddIcon sx={{ color: '#d0373b' }} /> {translate("Add Book")}
								</Box>
							</Box>
							<Box sx={{ px: 2 }}>
								{selectedBook.map((book, index) => (
									<Box
										key={index}
										display="flex"
										alignItems="center"
										justifyContent="space-between"
										mb={1}
									>
										<Box display="flex" alignItems="center" gap={2}>
											<input
												type="checkbox"
												style={{
													width: '20px',
													height: '20px'
												}}
											/>
											<Typography color={"#000000"} variant='h6'>{book}</Typography>
										</Box>
										<Box display="flex" gap={1}>
											<IconButton onClick={() => { setModalType('edit'); setModalOpen(true); }}>
												<EditIcon
													sx={{
														color: '#000000',
														cursor: 'pointer'
													}}
												/>
											</IconButton>
											<IconButton onClick={() => { setModalType('delete'); setModalOpen(true); }}>
												<DeleteIcon
													sx={{
														color: '#000000',
														cursor: 'pointer'
													}}
												/>
											</IconButton>
										</Box>
									</Box>
								))}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
			<Modal
				open={modalOpen}
				setOpen={setModalOpen}
				title={translate(modalType === 'add' ? "Add Book" : modalType === 'edit' ? "Edit Book" : "Delete Book")}
				modalbgColor="#ffffff"
				modalTextColor="#000000"
				modalTitleColor="#d32f2f"
				buttons={[
					{ name: 'Cancel', onClick: () => setModalOpen(false) },
					{
						name: modalType === 'delete' ? translate("Delete") : translate("Save"),
						onClick: () => { },
					}
				]}
			>
				{
					modalType === "delete" ? <DeleteAvailableBookComponent /> : <AddUpdateBookComponent />
				}
			</Modal>
		</Box>
	);
};

export default AvailableBooksTabComponent;
