import {IconButton, Typography} from '@mui/material';
import React, {useState} from 'react';
import Modal from '../../common/Modal';
import HelpOutline from '@mui/icons-material/HelpOutline';
import ModalHeader from '../../common/ModalHeader';
import useTranslations from '../../../hooks/useTranslation';

const QuestionHeadings = ({heading1, heading2, heading3, isIcon}) => {
  const {translate} = useTranslations();
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Typography color="lightgray">{heading1}</Typography>
      <Typography variant="h4" my={1} color="white" fontWeight="bold">
        {heading2}
      </Typography>
      <Typography mb={isIcon ? 0 : 5} color="lightgray">
        {heading3}
      </Typography>
      {isIcon && (
        <IconButton
          style={{
            marginTop: '10px',
            width: '28px',
            height: '28px',
            cursor: 'pointer',
            marginBottom: '10px'
          }}
          onClick={() => setModalOpen(true)}
        >
          <HelpOutline style={{color: 'white'}} />
        </IconButton>
      )}

      <Modal open={modalOpen} setOpen={setModalOpen} buttons={[{name: translate("Done"), onClick: () => setModalOpen(false)}]} style={{height: '100%'}} backgroundColor="white">
        <ModalHeader title={translate("Who They Are?")} />
        <Typography mt={2} color="#000">
         {translate("Knowing whom you are talking helps when making You can push an extrovert in the group whereas you mght give an introvert mote time to think. Leaders need pushes to follow. The quiet to speak. The activist needs answers. Speak to the who they are os you guide them down the path.")}
        </Typography>
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          {translate("INTROVERT - EXTROVERT - AMBIVERT")}
        </Typography>
        <Typography mt={1} color="#000">
          {translate("Do they recharge in a group (extrovert) or alone (introvert)? DO they process externally (extrovert) ? Are they truly a mix of both (ambivert) ?")}
        </Typography>
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          {translate("GO/SEND - LEAD/FOLLOW - TELL/LISTEN")}
        </Typography>
        <Typography mt={1} color="#000">
          {translate("If three people and $3.000 were needed to free nine slaves. would they hit the ground or fund the trip? If an event was going downhill and it seemed like no one knew what to do, would they end up fixing it or supporting the fix? If a question was asked in a group. would they jump in with the answer or wait to see if someone else spoke first?")}
        </Typography>
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          {translate("AUDITORY - VISUAL - BOTH")}
        </Typography>
        <Typography mt={1} color="#000">
          {translate("Can they follow and audio book (auditory learner) ? DO they use metaphors and word pictures or have to •see it• (visual learner) ?")}
        </Typography>
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          {translate("EMOTIONAL - ENGINEER - ACTIVIST")}
        </Typography>
        <Typography mt={1} color="#000">
          {translate("Do they feel what they read and learn deeply. emotionally? Do they have to figure out the order of things or seem to think linearly? Are they always asking, Why would GOd kill all the people? Why all the rules?")}
        </Typography>
      </Modal>
    </>
  );
};

export default QuestionHeadings;
