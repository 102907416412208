import React, {useState} from 'react';
import {TextField, Typography, IconButton, Box, Button} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import useTranslations from '../../../hooks/useTranslation';
import TextInput from '../../../components/common/TextInput';

const OrganizationStatsCard = () => {
  const {translate} = useTranslations();

  const [isEditing, setIsEditing] = useState(false);
  const [membership, setMembership] = useState('3,000');
  const [denomination, setDenomination] = useState('Christian Church');
  const [structure, setStructure] = useState('Elders');
  const [style, setStyle] = useState('Contemporary');
  const [foundedYear, setFoundedYear] = useState('1982');

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleSubmitClick = () => {
    // Add logic to save changes here
    setIsEditing(false);
  };

  return (
    <Box
      sx={{
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        maxWidth: '400px',
        backgroundColor: '#ffffff'
      }}
    >
      <Typography variant="h5" mb={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#000'}}>
        Organization Stats
        {!isEditing && (
          <IconButton onClick={handleEditClick} size="small" aria-label="edit">
            <EditIcon sx={{color: '#000'}} />
          </IconButton>
        )}
      </Typography>

      {isEditing ? (
        <>
          <TextInput
            bottomLabel={translate('Active Membership')}
            labelColor="black"
            labelVariant="body2"
            value={membership}
            onChange={e => setMembership(e.target.value)}
            fullWidth
            colorBlack={'text-black'}
            sx={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #ccc',
              borderRadius: '8px',
              '& .MuiInputBase-input': {
                color: 'black !important'
              },
              '& .MuiInputBase-input:focus': {
                color: 'black !important'
              }
            }}
          />
          <TextInput
            bottomLabel={translate('Denomination')}
            labelColor="black"
            labelVariant="body2"
            value={denomination}
            onChange={e => setDenomination(e.target.value)}
            fullWidth
            colorBlack={'text-black'}
            sx={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #ccc',
              borderRadius: '8px',
              '& .MuiInputBase-input': {
                color: 'black !important'
              },
              '& .MuiInputBase-input:focus': {
                color: 'black !important'
              }
            }}
          />
          <TextInput
            bottomLabel={translate('Leadership Structure')}
            labelColor="black"
            labelVariant="body2"
            value={structure}
            onChange={e => setStructure(e.target.value)}
            fullWidth
            colorBlack={'text-black'}
            sx={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #ccc',
              borderRadius: '8px',
              '& .MuiInputBase-input': {
                color: 'black !important'
              },
              '& .MuiInputBase-input:focus': {
                color: 'black !important'
              }
            }}
          />
          <TextInput
            bottomLabel={translate('Style')}
            labelColor="black"
            labelVariant="body2"
            value={style}
            onChange={e => setStyle(e.target.value)}
            fullWidth
            colorBlack={'text-black'}
            sx={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #ccc',
              borderRadius: '8px',
              '& .MuiInputBase-input': {
                color: 'black !important'
              },
              '& .MuiInputBase-input:focus': {
                color: 'black !important'
              }
            }}
          />
          <TextInput
            bottomLabel={translate('Year Founded')}
            labelColor="black"
            labelVariant="body2"
            value={foundedYear}
            onChange={e => setFoundedYear(e.target.value)}
            fullWidth
            colorBlack={'text-black'}
            sx={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #ccc',
              borderRadius: '8px',
              '& .MuiInputBase-input': {
                color: 'black !important'
              },
              '& .MuiInputBase-input:focus': {
                color: 'black !important'
              }
            }}
          />
          <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '8px', marginTop: '8px'}}>
            <Button variant="contained" color="primary" onClick={handleSubmitClick}>
              Submit
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCancelClick}>
              Cancel
            </Button>
          </Box>
        </>
      ) : (
        <>
          {[
            {label: 'Active Membership', value: membership},
            {label: 'Denomination', value: denomination},
            {label: 'Leadership Structure', value: structure},
            {label: 'Style', value: style},
            {label: 'Year Founded', value: foundedYear}
          ].map((item, index) => (
            <Typography variant="body1" sx={{color: '#000', display: 'flex', textAlign: 'start', justifyContent: 'space-between'}} key={index}>
              <span>{item.label}</span>
              <span>{item.value}</span>
            </Typography>
          ))}
        </>
      )}
    </Box>
  );
};

export default OrganizationStatsCard;
