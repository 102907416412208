import React, {useState, useEffect} from 'react';
import {Box, Typography, useMediaQuery, Checkbox, FormControlLabel, FormControl, Select, MenuItem, IconButton} from '@mui/material';
import ItemList from './common/ItemList';
import ItemDetails from './common/Details';
import {deleteUser, getAdminOrgDisciplesApi, getDiscipleDetailsApi} from '../../api';
import AdminStructure from './common/AdminStructure';
import useAdminStore from '../../store/adminStore';
import NoDataFoundMessage from '../../components/common/NoDataFoundMessage';
import Loader from '../../components/common/Loader';
import moment from 'moment';
import {CONSTANTS} from '../../constants';
import useTranslations from '../../hooks/useTranslation';
import Modal from '../../components/common/Modal';
import {Edit} from '@mui/icons-material';

function Disciples() {
  const {translate} = useTranslations();
  const {organizationSelected} = useAdminStore(state => state);
  const [selectedDisciple, setSelectedDisciple] = useState(null);
  const [adminDisciple, setAdminDisciple] = useState([]);
  const [discipleData, setDiscipleData] = useState([]);
  const [searchDisciples, setSearchDisciples] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const [selectDisciple, setSelectDisciple] = useState('');
  const [invite, setInvite] = useState(false);
  const [isAddDiscipleOpen, setIsAddDiscipleOpen] = useState(false);

  const [isPromoteOpen, setIsPromoteOpen] = useState(false);
  const [d1Checked, setD1Checked] = useState(false);
  const [d2Checked, setD2Checked] = useState(false);
  const [d3Checked, setD3Checked] = useState(false);

  const [disciples, setDisciples] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handlePromoteChange = setter => event => {
    setter(event.target.checked);
  };

  const handlePromoteSave = () => {
    // Implement save logic
    console.log('Saved with selections:', {d1Checked, d2Checked, d3Checked});
    setIsPromoteOpen(false);
  };

  const newDisciples = ['Doug’s Demo One', 'Dis Two', 'Dis Three']; // Example group list

  const handleDiscipleSave = (selectDisciple, invite) => {
    console.log(`Selected Disciple: ${selectDisciple}`);
    console.log(`Invite: ${invite}`);
    setIsAddDiscipleOpen(false);
  };

  const handleAddDiscipleGroupChange = event => {
    setSelectDisciple(event.target.value);
  };

  const handleAddDiscipleCheckboxChange = event => {
    setInvite(event.target.checked);
  };

  const getDiscipleDataHandler = async () => {
    try {
      const res = await getAdminOrgDisciplesApi(organizationSelected, 'disciple');
      if (res?.data?.status === 200) {
        setDisciples(res?.data?.data.filter(obj => obj.status === 'ACTIVE'));
      }
    } catch (error) {}
  };

  const handleDelete = async () => {
    try {
      const response = await deleteUser(itemToDelete);
      if (response?.data?.status === 200) {
        setDisciples(disciples.filter(i => i.id !== itemToDelete));
        getDiscipleDataHandler();
      }
      handleMenuClose();
    } catch (error) {
      handleMenuClose();
    }
  };

  // Trigger delete modal with disciple data
  const openDeleteModal = discipleId => {
    setItemToDelete(discipleId); // Set the disciple to delete
    setIsModalOpen(true);
  };

  const handleCheckboxChange = event => {
    setIsChecked(event.target.checked);
  };

  const handleMenuClose = e => {
    if (e) {
      e.stopPropagation();
    }
    setIsModalOpen(false);
    setIsChecked(false);
    setItemToDelete(null); // Reset item to delete when closing modal
    setIsPromoteOpen(false);
    setIsAddDiscipleOpen(false);
  };

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handleDiscipleClick = async disciple => {
    setLoader(true);
    setSelectedDisciple(disciple);
    const resp = await getDiscipleDetailsApi(disciple?.id, 'disciple');
    if (resp.status == 200) {
      setDiscipleData(resp?.data?.data?.[0]);
      setLoader(false);
    }
    setLoader(false);
  };

  const getDisciples = async () => {
    setLoader(true);
    try {
      setPageLoading(true);
      const response = await getAdminOrgDisciplesApi(organizationSelected, 'disciple');
      if (response?.data?.status == 200) {
        setAdminDisciple(response?.data?.data);
        setSearchDisciples(response?.data?.data);
      }
      setPageLoading(false);
      setLoader(false);
    } catch (error) {
      setPageLoading(false);
      setLoader(false);
    }
  };

  useEffect(() => {
    // Automatically trigger handleDiscipleClick for the first disciple when adminDisciple is loaded
    if (adminDisciple?.length > 0) {
      const firstDisciple = adminDisciple[0]; // Get the first disciple in the list
      handleDiscipleClick(firstDisciple);
    }
  }, [adminDisciple]);

  const searchHandler = value => {
    if (value.length === 0) {
      setAdminDisciple(searchDisciples);
    } else {
      const filteredDisciples = searchDisciples.filter(disciple => disciple?.name?.toLowerCase().includes(value.toLowerCase()));
      setAdminDisciple(filteredDisciples);
    }
  };

  useEffect(() => {
    getDisciples();
  }, [organizationSelected]);

  // Calculate the counts of each status disciple
  const activeDisciplesCount = adminDisciple ? adminDisciple.filter(disciple => disciple.status === 'ACTIVE')?.length : 0;
  const invitedDisciplesCount = adminDisciple ? adminDisciple.filter(disciple => ['COMPLETED', 'ARCHIVED', 'INVITED'].includes(disciple.status))?.length : 0;

  return (
    <div
      style={{
        backgroundImage: 'none !important',
        backgroundColor: '#f9f9f9',
        height: '100vh',
        width: '100vw',
        overflowY: isSmallScreen ? 'auto' : 'hidden',
        overflowX: 'hidden'
      }}
    >
      <AdminStructure isSearch={true} searchHandler={searchHandler} handleAddClick={() => setIsAddDiscipleOpen(true)} heading={translate('Disciples')} isAddingDisciple={true}>
        {pageLoading ? (
          <Loader color="#9e0001" />
        ) : adminDisciple?.length === 0 ? (
          <NoDataFoundMessage mt={5} message={translate('There are no disciples in this organization')} />
        ) : (
          <Box
            display="flex"
            // justifyContent="space-between"
            px={2}
            gap={2}
            sx={{
              height: {
                md: '85vh',
                xs: 'auto',
                sm: 'auto'
              },
              flexDirection: {
                md: 'row',
                sm: 'column',
                xs: 'column'
              },
              justifyContent: {
                md: 'space-between'
              }
            }}
          >
            <Box width="100%" display={'flex'} flexDirection={'column'} gap={2}>
              <ItemList items={adminDisciple.filter(disc => ['ACTIVE'].includes(disc.status))} handleClick={handleDiscipleClick} selectedItem={selectedDisciple} height={'50%'} title={`${translate('Active Disciples')} (${activeDisciplesCount})`} />
              <ItemList isInvited items={adminDisciple.filter(disc => ['COMPLETED', `ARCHIVED`, `INVITED`].includes(disc.status))} handleClick={handleDiscipleClick} selectedItem={selectedDisciple} height={'50%'} title={`${translate('Invited Disciples')} (${invitedDisciplesCount})`} />
            </Box>

            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              // justifyContent="space-between"
              gap={2}
              sx={{
                justifyContent: {
                  md: 'space-between'
                }
              }}
            >
              {selectedDisciple && (
                <ItemDetails
                  title={selectedDisciple.name}
                  selectedLeader={selectedDisciple}
                  deleteIcon
                  editIcon
                  setIsModalOpen={() => openDeleteModal(selectedDisciple.id)}
                  setIsPromoteOpen={setIsPromoteOpen}
                  height="42%"
                  details={[
                    {
                      name: translate('Date Finished D1:'),
                      detail: discipleData?.d1_completion_date !== 'N/A' ? moment(discipleData?.d1_completion_date).format(CONSTANTS.DATE_FORMAT) : 'N/A'
                    },
                    {
                      name: translate('Date Finished D2:'),
                      detail: discipleData?.d2_completion_date !== 'N/A' ? moment(discipleData?.d2_completion_date).format(CONSTANTS.DATE_FORMAT) : 'N/A'
                    },
                    {
                      name: translate('Date Finished D3:'),
                      detail: discipleData?.d3_completion_date !== 'N/A' ? moment(discipleData?.d3_completion_date).format(CONSTANTS.DATE_FORMAT) : 'N/A'
                    },
                    {
                      name: discipleData?.groupsLed,
                      detail: translate('Groups Led')
                    },
                    {
                      name: discipleData?.disciplesMade,
                      detail: translate('Disciples Made')
                    },
                    {
                      name: 'In Group:',
                      detail: '',
                      icon: ''
                    },
                    {
                      name: 'Approved to Lead D2',
                      detail: '',
                      icon: selectedDisciple?.status == 'IN-PROGRESS'
                    }
                  ]}
                  loader={loader}
                />
              )}
              {loader ? (
                <Loader color="#9e0001" />
              ) : (
                <>
                  {selectedDisciple && (
                    <>
                      <Box p={3} height="42%" overflowY="scroll" bgcolor="#FFF" borderRadius={5} display="flex" flexDirection="column" textAlign="left">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="h6" gutterBottom color="#d32f2f" fontWeight="bold">
                            {selectedDisciple?.name}'s {translate('Profile')}
                          </Typography>
                          {/* <IconButton onClick={() => {}}>
                            <Edit />
                          </IconButton> */}
                        </Box>
                        <div
                          //  key={index}
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            gap: '0.5rem'
                          }}
                        >
                          {discipleData?.title !== '' ? (
                            discipleData?.title?.split(',').map((item, index, array) =>
                              index === 2 ? (
                                <Typography key={index} variant="body1" color="black" fontWeight={600}>
                                  {[2, 3, 4].map(i => array[i]).join(' • ')}
                                </Typography>
                              ) : index === array.length - 1 ? (
                                <Typography key={index} variant="body1" color="black" fontWeight={600}>
                                  {`${item} ${translate('on the Pathway')}`}
                                </Typography>
                              ) : ![3, 4].includes(index) ? (
                                <Typography key={index} variant="body1" color="black" fontWeight={600}>
                                  {item}
                                </Typography>
                              ) : null
                            )
                          ) : (
                            <NoDataFoundMessage mt={5} message={translate('No assessment data available.')} />
                          )}
                        </div>
                      </Box>
                    </>
                  )}
                  {/* modal for Add disciple to group */}
                  <Modal
                    open={isAddDiscipleOpen}
                    onClose={handleMenuClose}
                    title="Add Disciple to New Group"
                    modalbgColor="#ffffff"
                    modalTextColor="#000000"
                    modalTitleColor="#d32f2f"
                    buttons={[
                      {name: 'Cancel', onClick: () => setIsAddDiscipleOpen(false)},
                      {
                        name: 'Save',
                        onClick: () => handleDiscipleSave(selectDisciple, notify),
                        disabled: !selectDisciple
                      }
                    ]}
                  >
                    <FormControl fullWidth sx={{mb: 2}}>
                      <Select
                        sx={{
                          backgroundColor: '#FFFFFF !important',
                          color: '#000000 !important',
                          '& .MuiSelect-select': {
                            backgroundColor: '#FFFFFF !important',
                            color: '#000000 !important'
                          },
                          '& .MuiPaper-root': {
                            backgroundColor: '#FFFFFF !important'
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#000000 !important'
                          }
                        }}
                        labelId="disciple-select-label"
                        value={selectDisciple}
                        onChange={handleAddDiscipleGroupChange}
                        displayEmpty
                      >
                        {newDisciples.map((disciple, index) => (
                          <MenuItem
                            key={index}
                            value={disciple}
                            sx={{
                              backgroundColor: '#FFFFFF !important',
                              color: '#000000 !important',
                              '&:hover': {
                                backgroundColor: '#f5f5f5 !important' // Light gray on hover
                              }
                            }}
                          >
                            {disciple}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography variant="body2" sx={{mb: 2, color: '#000000 !important'}}>
                      Select Existing Disciple
                    </Typography>
                    <FormControlLabel
                      sx={{'& .MuiFormControlLabel-label': {color: '#000000 !important'}}}
                      control={
                        <Checkbox
                          checked={invite}
                          onChange={handleAddDiscipleCheckboxChange}
                          sx={{
                            color: '#000000 !important',
                            '&.Mui-checked': {
                              color: '#000000 !important'
                            }
                          }}
                        />
                      }
                      label="Send Invite to Disciple"
                    />
                  </Modal>

                  {/* MOdal for drop disciple */}

                  <Modal
                    open={isModalOpen}
                    onClose={handleMenuClose}
                    title="Drop Disciple"
                    modalbgColor="#ffffff"
                    modalTextColor="#000000"
                    modalTitleColor="#d32f2f"
                    buttons={[
                      {name: 'Cancel', onClick: handleMenuClose},
                      {
                        name: 'Delete',
                        onClick: handleDelete,
                        disabled: !isChecked
                      }
                    ]}
                  >
                    <Typography sx={{mb: 2, color: '#000000'}}>Are you certain that you want to drop this disciple from the group?</Typography>
                    <FormControlLabel
                      sx={{'& .MuiFormControlLabel-label': {color: '#000000'}}}
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          sx={{
                            color: '#000000',
                            '&.Mui-checked': {
                              color: '#000000'
                            }
                          }}
                        />
                      }
                      label="I am sure that I want to drop this disciple."
                    />
                  </Modal>

                  {/* Modal for promote disciple */}
                  <Modal
                    open={isPromoteOpen}
                    onClose={handleMenuClose}
                    title="Promote Disciple"
                    modalbgColor="#ffffff"
                    modalTextColor="#000000"
                    modalTitleColor="#d32f2f"
                    buttons={[
                      {name: 'Cancel', onClick: () => setIsPromoteOpen(false)},
                      {name: 'Save', onClick: handlePromoteSave, disabled: false}
                    ]}
                  >
                    <Typography sx={{mb: 2, color: '#000000'}}>Select each year that the disciple is ready to lead.</Typography>
                    <FormControlLabel
                      sx={{'& .MuiFormControlLabel-label': {color: '#000000'}}}
                      control={
                        <Checkbox
                          checked={d1Checked}
                          onChange={handlePromoteChange(setD1Checked)}
                          sx={{
                            color: '#000000',
                            '&.Mui-checked': {
                              color: '#000000'
                            }
                          }}
                        />
                      }
                      label="D1"
                    />
                    <FormControlLabel
                      sx={{'& .MuiFormControlLabel-label': {color: '#000000'}}}
                      control={
                        <Checkbox
                          checked={d2Checked}
                          onChange={handlePromoteChange(setD2Checked)}
                          sx={{
                            color: '#000000',
                            '&.Mui-checked': {
                              color: '#000000'
                            }
                          }}
                        />
                      }
                      label="D2"
                    />
                    <FormControlLabel
                      sx={{'& .MuiFormControlLabel-label': {color: '#000000'}}}
                      control={
                        <Checkbox
                          checked={d3Checked}
                          onChange={handlePromoteChange(setD3Checked)}
                          sx={{
                            color: '#000000',
                            '&.Mui-checked': {
                              color: '#000000'
                            }
                          }}
                        />
                      }
                      label="D3"
                    />
                  </Modal>
                </>
              )}
            </Box>
          </Box>
        )}
      </AdminStructure>
    </div>
  );
}

export default Disciples;